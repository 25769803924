import {
    Backdrop, Box, Button, Card, Checkbox, Chip, CircularProgress, ClickAwayListener, Dialog, DialogActions, DialogContent, FormControl,
    FormControlLabel, FormLabel, IconButton, InputAdornment, Snackbar, TextField, Typography, useMediaQuery, useTheme
} from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Autocomplete, { AutocompleteCloseReason } from '@material-ui/lab/Autocomplete';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import PuffLoader from 'react-spinners/PuffLoader';
// Icons
import CancelIcon from '@material-ui/icons/Cancel';
import SearchIcon from "@material-ui/icons/Search";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@mui/icons-material/Info';
// Componant
import { ARMLogo, emailRegex, firstNameregex, lastNameregex } from '../../constants/Constant';
import { Transition } from '../GlobalStyles/DialogBoxTransition';
import { DialogTitleHeader } from '../GlobalStyles/DialogStyle';
import useStyles from './AgentUserCss';
import ManageActivity from './ManageActivity';
// Models
import _ from 'lodash';
import { CONT, FIRSTSTP, PREC } from '../../constants/Constant';
import { IAgentClient, IAgentClientCode, IFTEHistoryDetail, IPhaseSelection } from '../../models/AgentUser/IAgentClientDetails';
import { IAgentUserDetails } from '../../models/AgentUser/IAgentUserDetails';
import { IClientPhaseDetails } from '../../models/AgentUser/IClientPhaseDetails';
import { useGlobalState } from '../../store/GlobalStore';
import { usePost } from '../../utils/apiHelper';
import { AppBar, Toolbar, Grid, FormGroup } from '@mui/material';

const CreateAgentUser: React.FC<{ onSave: () => void }> = (props) => {
    /* API EndPoint */
    const GetAgentClientList = "AgentUser/GetAgentClientList";
    const CreateAgentUser = "AgentUser/CreateAgentUser";
    const CheckUserExists = "AgentUser/CheckUserExists";
    const AgentFteInsert = "AgentUser/InsertFTEHistory";

    const theme = useTheme();
    const classes = useStyles();
    const { state } = useGlobalState();

    const [phase, setPhase] = useState<IPhaseSelection[]>([]);
    const [agentId, setAgentId] = useState<number>(0);
    const [emailprocess, setEmailprocess] = useState<number>(0);

    const [open, setOpen] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [loading, setLoading] = useState(false);
    const [checkAll, setCheckAll] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [isloading, setIsLoading] = useState(false);
    const [isCaptured, setIsCaptured] = useState(false);
    const [createUser, setCreateUser] = useState(false);
    const [isDisableFN, setIsDisableFN] = useState(false);
    const [isDisableLN, setIsDisableLN] = useState(false);
    const [ProgressBar, setShowProgressBar] = useState(false);
    const [ClientCodeOpen, setClientCodeOpen] = useState(false);
    const [deselectClient, setDeselectClient] = useState(false);
    const [isDisableEmail, setIsDisableEmail] = useState(false);
    const [accessActivity, setAccessActivity] = useState(true);

    const [lastName, setLastName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [Emailerror, setEmailError] = useState("");
    const [Lastnameerror, setLastNameError] = useState("");
    const [firstnameerror, setFirstNameError] = useState("");
    const [searchClientCode, setSearchClientCode] = useState("");
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [confirmedClients, setConfirmedClients] = useState<IAgentClient[]>([]);
    const [selectedClients, setSelectedClients] = useState<any>([]);
    const [fteHistoryDetail, setFteHistoryDetails] = useState<IFTEHistoryDetail[]>([]);

    /* Notification access state */
    const [notificationConfirmedClients, setNotificationConfirmedClients] = useState<IAgentClient[]>([]);
    const [notificationSearchClientCode, setNotificationSearchClientCode] = useState("");
    const [notificationDeselectAll, setNotificationDeselectAll] = useState(false);
    const [notificationCheckAll, setNotificationCheckAll] = useState(false);
    const [notificationClientCodeOpen, setNotificationClientCodeOpen] = useState(false);
    const [notificationSelectedClients, setNotificationSelectedClients] = useState<any>([]);
    const [notificationClientLoading, setNotificationClientLoading] = useState(false);
    const [notificationClientClear, setNotificationClientClear] = useState("false");

    let userDetails: IAgentUserDetails = {
        emailAddress: "",
        firstName: "",
        lastName: "",
        createdDate: moment(new Date())?.format("YYYY-MM-DD"),
        modifiedDate: moment(new Date())?.format("YYYY-MM-DD"),
        paymentUpdates: false,
        closeAccounts: false,
        rollAccounts: false,
        clientInformation: false,
        readOnlyAccess: false,
        isActive: true,
        portalUserExist: false,
        remainingAccountCount: false,
        dailyActivityCount: false,
        dailyTargetedCount: false,
        accountPlacementCount: false,
        accountUpdateHistory: false,
        accountActivityHistory: false,
        allFirstPartySelected: false,
        allPrecSelected: false,
        allContSelected: false,
        agentUsersReport: false,
        ftePermission: false,
        disputeValidation: false,
        paymentVerification: false,
        backupDocument: false,
        caseCreatedReport: false,
        activityCountReport: false
    }

    let clientPhaseDetails: IClientPhaseDetails = {
        isFirstParty: true,
        isFlatFee: false,
        isContingency: false
    }

    const [localState, setLocalState] = useState(userDetails);
    const [selectedPhase, setSelectedPhase] = useState(clientPhaseDetails);

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setCreateUser(false);
        setIsValid(false);
    };

    const handleClose = () => {
        setAccessActivity(false);
        setOpen(false);
        setShowProgressBar(false);
        setSelectedClients([]);
        setLocalState(userDetails);
        setIsSubmit(false);
        setIsCaptured(false);
        resetValues();
    };

    const handleCloseExistsUser = () => {
        setAlertOpen(false);
        setSelectedPhase(null);
    }

    const handleChange = (e) => {
        setAccessActivity(true);
        let name = e.target.name;
        setLocalState({
            ...localState,
            [name]: e.target.value
        });
    }

    const handleChecked = (e) => {
        setAccessActivity(true);
        let name = e.target.name;
        setLocalState({
            ...localState,
            [name]: e.target.checked
        });
    }

    const handleEmailChange = (e) => {
        setAccessActivity(true);
        let name = e.target.name;
        setLocalState({
            ...localState,
            [name]: e.target.value
        });
    }

    const HandleFirstNameVal = (e) => {
        const FirstNameVal = e.target.value;

        if (!FirstNameVal.match(firstNameregex)) {
            setFirstNameError("");
            setIsDisableFN(false);
            setFirstName(FirstNameVal); // only set when successful
        } else if (FirstNameVal === "") {
            setFirstNameError("First Name is Required");
            setIsDisableFN(true);
        } else {
            setFirstNameError("Please enter characters only");
            setIsDisableFN(true);
        }
    };

    const HandleLastNameVal = (e) => {
        const LastNameVal = e.target.value;

        if (!LastNameVal.match(lastNameregex)) {
            setLastNameError("");
            setIsDisableLN(false);
            setLastName(LastNameVal); // only set when successful
        } else if (LastNameVal === "") {
            setLastNameError("Last Name is Required");
            setIsDisableLN(true);
        }
        else {
            setLastNameError("Please enter characters only");
            setIsDisableLN(true);
        }
    };

    const validateEmail = (e) => {
        setAccessActivity(true);
        const email = e.target.value;
        setIsCaptured(true);
        if (emailRegex.test(email)) {
            (async () => {
                setIsLoading(false);
                let checkUser = { "emailAddress": email };
                await usePost<any>(CheckUserExists, checkUser).then(async (r) => {
                    if (r?.data[0]?.isWebViewUser || r?.data[0]?.isAgentUser) {
                        setEmailprocess(2);
                        setEmailError("User already exists, Please try alternate Email!");
                        setIsDisableEmail(true);
                    } else {
                        setEmailprocess(3);
                        setEmailError("");
                        setIsDisableEmail(false);
                    }
                }).finally(() => {
                    setIsLoading(false);
                });
            })()
        } else if (!e.target.value?.trim()?.length) {
            setEmailprocess(0);
            setEmailError("");
            setIsDisableEmail(true);
        }
        else {
            setEmailprocess(1);
            setEmailError("Please enter valid mail address");
            setIsDisableEmail(true);
        }
    };

    const resetValues = () => {
        setFirstNameError(null);
        setLastNameError(null);
        setEmailError(null);
    }

    const handleClickAway = (e, isSearchClear: boolean) => {
        setClientCodeOpen(false);
        if (isSearchClear) {
            handleClear();
        }
    };

    const handleAgentNotificationClientClickAway = (e, isSearchClear: boolean) => {
        setNotificationClientCodeOpen(false);
        if (isSearchClear) {
            handleClientCodeClear();
        }
    };

    const onDelete = (clientcode: string) => () => {
        setSelectedClients((value) => value.filter((v) => (v.clientCode !== clientcode)));
    };

    const onNotificationDelete = (clientcode: string) => () => {
        setNotificationSelectedClients((value) => value.filter((v) => (v.clientCode !== clientcode)));
    };

    const handleClientSelect = (event, selected) => {
        setSelectedClients(selected);
        if (selected?.length <= 0) {
            setCheckAll(false);
        }
    }

    const handleNotificationClientSelect = (event, selected) => {
        setNotificationSelectedClients(selected);
        if (selected?.length <= 0) {
            setNotificationCheckAll(false);
        }
    }

    const handleSearchClient = (e) => {
        setSearchClientCode(e.target.value);
    }

    const handleNotificationSearchClient = (e) => {
        setNotificationSearchClientCode(e.target.value);
        setNotificationClientClear("");
    }

    const checkAllChange = (event) => {
        setCheckAll(event.target.checked);
        if (event.target.checked) {
            var NewClientList = confirmedClients.filter((c) => {
                return !selectedClients.find((r) => {
                    return c.clientCode == r?.clientCode
                })
            });
            setSelectedClients([...new Set(selectedClients?.concat(NewClientList?.map((r) => r)))]);
            if (selectedPhase?.isFirstParty && selectedPhase?.isFlatFee && selectedPhase?.isContingency) {
                setLocalState({ ...localState, allFirstPartySelected: true, allPrecSelected: true, allContSelected: true });
            } else if (selectedPhase?.isFirstParty && selectedPhase?.isFlatFee) {
                setLocalState({ ...localState, allFirstPartySelected: true, allPrecSelected: true });
            } else if (selectedPhase?.isFirstParty && selectedPhase?.isContingency) {
                setLocalState({ ...localState, allFirstPartySelected: true, allContSelected: true });
            } else if (selectedPhase?.isFlatFee && selectedPhase?.isContingency) {
                setLocalState({ ...localState, allPrecSelected: true, allContSelected: true });
            } else if (selectedPhase?.isFirstParty) {
                setLocalState({ ...localState, allFirstPartySelected: true });
            } else if (selectedPhase?.isFlatFee) {
                setLocalState({ ...localState, allPrecSelected: true });
            } else if (selectedPhase?.isContingency) {
                setLocalState({ ...localState, allContSelected: true });
            }
            setClientCodeOpen(false);
        }
        setCheckAll(false);
        handleClear();
        setSearchClientCode("");
    };

    const DeselectAllChange = (event) => {
        setDeselectClient(event.target.checked);
        if (event.target.checked) {
            setSelectedClients([]);
            setClientCodeOpen(false);
        }
        setLocalState({ ...localState, allFirstPartySelected: false, allPrecSelected: false, allContSelected: false });
        setDeselectClient(false);
        handleClear();
        setSearchClientCode("");
    };

    const notificationCheckAllChange = (event) => {
        setNotificationCheckAll(event.target.checked);
        if (event.target.checked) {
            var NewClientList = notificationConfirmedClients.filter((c) => {
                return !notificationSelectedClients?.find((r) => {
                    return c?.clientCode == r?.clientCode
                })
            });
            setNotificationSelectedClients([...new Set(notificationSelectedClients?.concat(NewClientList?.map((r) => r)))]);
            setNotificationClientCodeOpen(false);
        }
        setNotificationCheckAll(false);
        handleClientCodeClear();
        setNotificationSearchClientCode("");
    };

    const notificationDeselectAllChange = (event) => {
        setNotificationDeselectAll(event.target.checked);
        if (event.target.checked) {
            setNotificationSelectedClients([]);
            setNotificationClientCodeOpen(false);
        }
        setLocalState({ ...localState, allFirstPartySelected: false, allPrecSelected: false, allContSelected: false });
        setNotificationDeselectAll(false);
        handleClientCodeClear();
        setNotificationSearchClientCode("");
    };

    const handleCreateUser = async () => {
        setShowProgressBar(true);
        try {
            // Step 1: Check if the user exists
            const checkUserPayload = {
                emailAddress: localState?.emailAddress,
            };

            const checkUserResponse = await usePost<any>(CheckUserExists, checkUserPayload);

            if (checkUserResponse?.data?.[0]?.isWebViewUser || checkUserResponse?.data?.[0]?.isAgentUser) {
                setAlertOpen(true);
                return; // Exit early if a duplicate or invalid user is detected
            }

            // Step 2: Prepare new client list
            let newClient: IAgentClientCode[] = [];
            selectedClients?.forEach((c) => {
                phase?.forEach((p) => {
                    newClient.push({ clientCode: c?.clientCode, phaseCode: p.phase });
                });
            });

            // Step 3: Prepare notification client list
            let newNotificationClientCodeList: IAgentClientCode[] = [];
            notificationSelectedClients?.forEach((r) => {
                newNotificationClientCodeList.push({ clientCode: r?.clientCode, phaseCode: CONT });
            });

            // Step 4: Initialize user data for batch saving
            const newUser: IAgentUserDetails[] = [localState];
            const batchSize = 500;
            let batchClientCodeList: IAgentClientCode[] = [];

            // Step 5: Batch processing
            for (let i = 0; i < newClient.length; i++) {
                batchClientCodeList.push(newClient[i]);

                // If batch is full or we're at the last item, save the batch
                if ((i + 1) % batchSize === 0 || i === newClient.length - 1) {
                    await handleBatchSave(newUser, batchClientCodeList, newNotificationClientCodeList);
                    batchClientCodeList = []; // Reset the batch list after saving
                }
            }
        } catch (error) {
            console.error("Error in handleCreateUser:", error);
        } finally {
            // Final cleanup and state updates
            setIsSubmit(true);
            props.onSave();
            setCreateUser(true);
            setAccessActivity(false);
        }
    };

    useEffect(() => {
        (async () => {
            insertAgentFTEHistory();
        })();
    }, [fteHistoryDetail])

    const insertAgentFTEHistory = () => {
        let request = {
            "agentFteHistory": fteHistoryDetail,
            "fteAction": localState?.ftePermission ? 1 : 2,
            "fteActionTakenBy": state?.userAccessContext?.id
        }
        usePost<any>(AgentFteInsert, request).then((r) => {

        }).finally(() => {
            setShowProgressBar(false);
        });
    }

    const handleBatchSave = ((userInfo: IAgentUserDetails[], batchClientCodeList: IAgentClientCode[], batchNotificationclientCodeList: IAgentClientCode[]) => {
        let newHistoryList = [];
        let request = {
            "createdBy": state?.userAccessContext?.id,
            "agentUserDetails": userInfo,
            "agentClientDetails": batchClientCodeList,
            "agentNotificationClientDetails": batchNotificationclientCodeList
        }
        usePost<any>(CreateAgentUser, request).then((r) => {
            setAgentId(r?.data[0]?.agentUserId);
            let newResult: IFTEHistoryDetail = {
                agentUserId: r?.data[0]?.agentUserId,
                clientCode: null,
                phaseCode: null,
                previousActivityTemplateId: null,
                newActivityTemplateId: null
            }
            newHistoryList.push(newResult);
            setFteHistoryDetails(newHistoryList);
        });
    });

    useEffect(() => {
        if (!selectedPhase?.isFirstParty && !selectedPhase?.isFlatFee && !selectedPhase?.isContingency) {
            setSelectedPhase({
                ...selectedPhase,
                isFirstParty: true
            });
            let PhaseList = _.cloneDeep(phase);
            let phaseCode: IPhaseSelection = {
                phase: FIRSTSTP
            }
            PhaseList.push(phaseCode);
            setPhase(PhaseList);
            handleClientGet(PhaseList);
        }
    }, [selectedPhase])

    const handleClientGet = (Phase: IPhaseSelection[]) => {
        (async () => {
            setLoading(true);
            let request = {
                "searchText": searchClientCode?.trim().toLocaleUpperCase(),
                "phaseDetails": Phase?.map((r) => r?.phase).join(",")
            }
            await usePost<IAgentClient[]>(GetAgentClientList, request).then((r) => {
                setConfirmedClients(r?.data);
                setLoading(false);
            }).finally(() => {
                setCheckAll(false);
                setNotificationCheckAll(false);
            });
        })()
    };

    const handlePhaseChange = (e) => {
        setSelectedPhase({
            ...selectedPhase,
            [e.target.name]: e.target.checked
        });

        let PhaseList = _.cloneDeep(phase);
        if (phase.findIndex(x => x.phase == e.target.value) <= -1) {
            let phaseCode = { phase: e?.target.value };
            if (!PhaseList?.includes(phaseCode)) {
                PhaseList.push(phaseCode);
            }
            setPhase(PhaseList);
        }
        else {
            if (phase.findIndex(x => x.phase == e.target.value) > -1) {
                PhaseList = phase.filter(x => x.phase !== e.target.value)
                setPhase(phase.filter(x => x.phase !== e.target.value));
            };
        }
        handleClientOpen(PhaseList);
    }

    useEffect(() => {
        (async () => {
            setLoading(true);
            let request = {
                "searchText": searchClientCode?.trim().toLocaleUpperCase(),
                "phaseDetails": FIRSTSTP
            }
            await usePost<IAgentClient[]>(GetAgentClientList, request).then((r) => {
                setConfirmedClients(r?.data);
                setLoading(false);
                let PhaseList = _.cloneDeep(phase);
                let phaseCode = { phase: FIRSTSTP };
                PhaseList.push(phaseCode);
                setPhase(PhaseList);
            });
        })()
    }, [])

    const handleNotificationClientLoad = () => {
        (async () => {
            setNotificationClientLoading(true);
            let request = {
                "searchText": notificationSearchClientCode?.trim().toLocaleUpperCase(),
                "phaseDetails": CONT
            }
            await usePost<IAgentClient[]>(GetAgentClientList, request).then((r) => {
                let newList: IAgentClient[] = r?.data;
                setNotificationConfirmedClients(newList);
            });
        })().finally(() => {
            setNotificationClientLoading(false);
        })
    }

    const handleClientOpen = (Phase: IPhaseSelection[]) => {
        (async () => {
            setConfirmedClients([]);
            setLoading(true);
            setOpen(true);
            let request = {
                "searchText": searchClientCode?.trim().toLocaleUpperCase(),
                "phaseDetails": Phase?.map((r) => r?.phase).join(",")
            }
            await usePost<IAgentClient[]>(GetAgentClientList, request).then((r) => {
                setConfirmedClients(r?.data);
                setLoading(false);
            }).finally(() => {
                setCheckAll(false);
            });
        })()
    };

    const handleNotificationClientOpen = () => {
        (async () => {
            setNotificationConfirmedClients([]);
            setNotificationClientLoading(true);
            setOpen(true);
            let request = {
                "searchText": notificationSearchClientCode?.trim().toLocaleUpperCase(),
                "phaseDetails": CONT
            }
            await usePost<IAgentClient[]>(GetAgentClientList, request).then((r) => {
                setNotificationConfirmedClients(r?.data);
                setNotificationClientLoading(false);
            }).finally(() => {
                setNotificationCheckAll(false);
                setNotificationClientLoading(false);
            });
        })()
    };

    const handleClear = () => {
        (async () => {
            setOpen(true);
            let request = {
                "searchText": null,
                "phaseDetails": phase?.map((r) => r?.phase).join(",")
            }
            await usePost<IAgentClient[]>(GetAgentClientList, request).then((r) => {
                setConfirmedClients(r?.data);
            }).finally(() => {
                setSearchClientCode("");
            });
        })()
    };

    const handleClientCodeClear = () => {
        (async () => {
            setOpen(true);
            setNotificationClientLoading(true);
            let request = {
                "searchText": null,
                "phaseDetails": CONT
            }
            await usePost<IAgentClient[]>(GetAgentClientList, request).then((r) => {
                setNotificationConfirmedClients(r?.data);
            }).finally(() => {
                setNotificationSearchClientCode("");
                setNotificationClientLoading(false);
            });
        })()
    };

    return (
        <React.Fragment>
            <Button className={classes.ButtonStyle} size="small" startIcon={<PersonAddIcon />} variant="contained" color="primary" onClick={(_) => { setOpen(true); handleNotificationClientLoad(); }}>
                CREATE NEW USER
            </Button>
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition} className={classes.dialog}>
                <AppBar position="fixed" className={classes.appBar} sx={{ position: 'relative' }}>
                    <Toolbar className={classes.toolbarStyle}>
                        <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" >
                            <img alt="ARM_Solutions" className={classes.menuButton1} src={ARMLogo} />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            <b>CREATE AGENT USER</b>
                        </Typography>
                        <IconButton edge="end" color="inherit" onClick={() => handleClose()} aria-label="close" style={{ padding: '5px' }}>
                            <CloseIcon style={{ color: '#000', fontSize: 22 }} />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent style={{ padding: "10px" }}>
                    <Backdrop className={classes.backdrop} open={ProgressBar}>
                        <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                    </Backdrop>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <Box className={classes.userboxviewStyle}>
                                <Typography variant="h6" gutterBottom className={`${classes.root} ${classes.userStyle}`}>USER PROFILE</Typography>
                                <TextField id="U_CU_FirstName"
                                    size="small" required label="First Name"
                                    variant="outlined" name="firstName"
                                    onChange={(e) => { handleChange(e); HandleFirstNameVal(e); }}
                                    helperText={firstnameerror} error={!!firstnameerror}
                                    inputProps={{ maxlength: 100 }}
                                    className={`${classes.firstTextfield} ${classes.firstnameInputSpacing}`}
                                    autoComplete='off'
                                    InputProps={{
                                        classes: {
                                            root: classes.blueBorderBottom,
                                        },
                                    }}
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.inputFontSize,
                                        },
                                    }}
                                />
                                <TextField id="U_CU_FirstName" size="small"
                                    required label="Last Name"
                                    variant="outlined" name="lastName"
                                    onChange={(e) => { handleChange(e); HandleLastNameVal(e); }}
                                    inputProps={{ maxlength: 100 }}
                                    className={`${classes.lastTextfield} ${classes.firstnameInputSpacing}`}
                                    helperText={Lastnameerror} error={!!Lastnameerror}
                                    autoComplete='off'
                                    InputProps={{
                                        classes: {
                                            root: classes.blueBorderBottom,
                                        },
                                    }}
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.inputFontSize,
                                        },
                                    }}
                                />
                                <div style={{ display: 'flex' }}>
                                    <TextField id="U_CU_Email" size="small"
                                        required label="Email" variant="outlined"
                                        name="emailAddress"
                                        onChange={(e) => { handleEmailChange(e); validateEmail(e) }}
                                        helperText={Emailerror} error={!!Emailerror}
                                        inputProps={{ maxlength: 50 }}
                                        className={`${isCaptured && emailprocess === 0 ? classes.textFieldBorder : emailprocess === 1 || emailprocess === 2 ? classes.textFieldBorderRed : classes.textFieldBorderGreen}`}
                                        autoComplete='off'
                                        InputProps={{
                                            classes: {
                                                root: `${emailprocess === 0 ? classes.blueBorderBottom : emailprocess === 1 || emailprocess === 2 ? classes.redBorderBottom : classes.greenBorderBottom}`,
                                                notchedOutline: `${emailprocess === 0 ? classes.blueBorderColor : emailprocess === 1 || emailprocess === 2 ? classes.redBorderColor : classes.greenBorderColor}`,
                                            },
                                        }}
                                        InputLabelProps={{ classes: { root: classes.inputFontSize }, }}
                                    />
                                    {isloading ?
                                        <div className={classes.loadingDiv}>
                                            <div className={`${'loader2'} ${classes.spinnerStyle}`} />
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                                <Box className={classes.LabelBoxStyle}>
                                    <FormLabel className={classes.formLabel}> Selected Client Code : {selectedClients.length}</FormLabel>
                                </Box>

                                <div>
                                    {selectedClients?.length > 0 ?
                                        <Card className={classes.boxBorder}>
                                            <Scrollbars autoHide
                                                autoHideTimeout={1000}
                                                autoHideDuration={10}
                                                autoHeight
                                                className={`${classes.textBoxStyle2} ${classes.scrollMargin}`}
                                                autoHeightMin="auto"
                                                autoHeightMax={86}
                                                style={{ width: '100%' }}>
                                                <div className={`${classes.valueContainer}`} >
                                                    {selectedClients.map((v, index) => (
                                                        <Chip key={index} size="small" color="secondary" label={v.clientCode} onDelete={onDelete(v.clientCode)} />
                                                    ))}
                                                </div>
                                            </Scrollbars>
                                        </Card>
                                        :
                                        null
                                    }
                                </div>
                                <ClickAwayListener onClickAway={(e) => { handleClickAway(e, searchClientCode.length > 0) }}>
                                    <div style={{ display: 'flex' }}>
                                        <Autocomplete
                                            multiple size="small"
                                            loading={loading}
                                            filterOptions={(options) => options}
                                            className={classes.clientCodeBox}
                                            autoComplete={false}
                                            id="checkboxes-tags-demo"
                                            options={confirmedClients}
                                            classes={{ paper: classes.paper2 }}
                                            disableClearable
                                            value={selectedClients}
                                            open={ClientCodeOpen}
                                            renderTags={() => null}
                                            onChange={(e, selected) => { handleClientSelect(e, selected); }}
                                            onOpen={() => { setClientCodeOpen(true); }}
                                            onClose={(e: any, reason: AutocompleteCloseReason) => {
                                                if (reason === "toggleInput") {
                                                    setClientCodeOpen(false);
                                                }
                                            }}
                                            getOptionDisabled={(option) => option.clientCode}
                                            getOptionLabel={(option) => option.clientCode}
                                            getOptionSelected={(option, value) => (option.clientCode == value.clientCode)}
                                            renderOption={(option, { selected }) => (
                                                <span className={`${selected ? classes.GreenColor : classes.clientCodeStyle}`} >
                                                    {`${option.clientCode} -- ${option.clientName}`}
                                                </span>
                                            )}
                                            ListboxProps={{ style: { maxHeight: '160px' } }}
                                            renderInput={(params) => (
                                                <TextField {...params} fullWidth size="small"
                                                    value={searchClientCode} variant="outlined"
                                                    type='text' placeholder="Search Clients"
                                                    onChange={(e) => { setSearchClientCode(e.target.value); }}
                                                    onKeyDown={event => {
                                                        if (event.key === 'Enter') { handleSearchClient(event); handleClientOpen(phase); setClientCodeOpen(true); }
                                                    }}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        inputProps: {
                                                            ...params.inputProps,
                                                            maxLength: 256
                                                        },
                                                        classes: { root: classes.inputFontSize, },
                                                        endAdornment: (
                                                            <React.Fragment>
                                                                {loading ? <CircularProgress color="primary" size={15} /> : null}
                                                                {params.InputProps.endAdornment}
                                                                <InputAdornment position="end">
                                                                    <IconButton onClick={(_) => { handleClientOpen(phase); setClientCodeOpen(true); }}>
                                                                        <SearchIcon className={classes.IconSizeStyle} />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                                <Box display="flex" width="100%" className={classes.CheckBoxBorder}>
                                                                    <Box className={classes.ClientcheckBoxStyle} width="64%">
                                                                        <Checkbox size="small" disabled={loading || !confirmedClients?.length}
                                                                            checked={checkAll}
                                                                            onChange={checkAllChange}
                                                                            id="check-all"
                                                                        />
                                                                        <span style={{ fontSize: 14, marginLeft: '2px' }}><b>SELECT ALL</b></span>
                                                                    </Box>
                                                                    <Box className={classes.ClientcheckBoxStyle} width="36%">
                                                                        <Checkbox size="small" disabled={loading || !confirmedClients?.length}
                                                                            checked={deselectClient}
                                                                            onChange={(e) => { DeselectAllChange(e); }}
                                                                            id="check-all"
                                                                        />
                                                                        <span style={{ fontSize: 14 }}><b>DESELECT ALL</b></span>
                                                                    </Box >
                                                                </Box >
                                                                <Card className={classes.checkBoxClientPhase} style={{ padding: '7px' }}>
                                                                    <FormControlLabel
                                                                        id="U_CU_FileAccess"
                                                                        control={<Checkbox size="small" name="isFirstParty" value={FIRSTSTP} className={classes.checkboxSeletct} checked={selectedPhase?.isFirstParty} onChange={(e) => { handlePhaseChange(e); }} />}
                                                                        label={<span className={classes.CheckBoxPhaseStyle} ><b>1ST PARTY</b></span>}
                                                                    />
                                                                    <FormControlLabel
                                                                        id="U_CU_FileAccess"
                                                                        control={<Checkbox size="small" name="isFlatFee" value={PREC} className={classes.checkboxSeletct1} checked={selectedPhase?.isFlatFee} onChange={(e) => { handlePhaseChange(e); }} />}
                                                                        label={<span className={classes.CheckBoxPhaseStyle} ><b>FLAT FEE</b></span>}
                                                                    />
                                                                    <FormControlLabel
                                                                        id="U_CU_FileAccess"
                                                                        control={<Checkbox size="small" name="isContingency" value={CONT} className={classes.checkboxSeletct1} checked={selectedPhase?.isContingency} onChange={(e) => { handlePhaseChange(e); }} />}
                                                                        label={<span className={classes.CheckBoxPhaseStyle}><b>CONTINGENCY</b></span>}
                                                                    />
                                                                </Card  >
                                                            </React.Fragment >
                                                        ),
                                                    }
                                                    }
                                                    className={classes.textBoxStyle}
                                                />
                                            )}
                                        />
                                    </div>
                                </ClickAwayListener >
                            </Box >

                            <Box style={{ padding: '8px 17px 8px 8px' }}>
                                <ManageActivity agentUserID={agentId} isAccess={accessActivity} phaseList={selectedPhase} isReadOnly={localState?.readOnlyAccess} />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={12}>
                                    <Box className={classes.userboxviewStyle}>
                                        <Typography variant="h6" gutterBottom className={`${classes.root} ${classes.userStyle1}`}>{"PERMISSIONS"}</Typography>
                                        <FormControl component="fieldset">
                                            <FormGroup row>
                                                <FormControlLabel
                                                    id="U_CU_paymentUpdates"
                                                    className={classes.permission}
                                                    control={<Checkbox size="small" name="paymentUpdates" />} onChange={(e) => { handleChecked(e); }}
                                                    label={<span className={classes.inputFontSize}>{"Payment Updates"}</span>}
                                                />
                                                <FormControlLabel
                                                    id="U_CU_closeAccounts"
                                                    className={classes.permission}
                                                    control={<Checkbox size="small" name="closeAccounts" />} onChange={(e) => { handleChecked(e); }}
                                                    label={<span className={classes.inputFontSize}>{"Close Accounts"}</span>}
                                                />
                                                <FormControlLabel
                                                    id="U_CU_rollAccounts"
                                                    className={classes.permission}
                                                    control={<Checkbox size="small" name="rollAccounts" />} onChange={(e) => { handleChecked(e); }}
                                                    label={<span className={classes.inputFontSize}>{"Roll Accounts"}</span>}
                                                />
                                                <FormControlLabel
                                                    id="U_CU_clientInformation"
                                                    className={classes.permission}
                                                    control={<Checkbox size="small" name="clientInformation" />} onChange={(e) => { handleChecked(e); }}
                                                    label={<span className={classes.inputFontSize}>{"Client information"}</span>}
                                                />
                                                <FormControlLabel
                                                    id="U_CU_readOnlyAccess"
                                                    className={classes.permission}
                                                    control={<Checkbox size="small" name="readOnlyAccess" />} onChange={(e) => { handleChecked(e); }}
                                                    label={<span className={classes.inputFontSize}>{"Read Only Access"}</span>}
                                                />
                                                <FormControlLabel
                                                    id="U_CU_agentUsersReport"
                                                    className={classes.permission}
                                                    control={<Checkbox size="small" name="agentUsersReport" />} onChange={(e) => { handleChecked(e); }}
                                                    label={<span className={classes.inputFontSize}>{"Agent User Report"}</span>}
                                                />
                                                <FormControlLabel
                                                    id="U_CU_fTEPermission"
                                                    className={classes.permission}
                                                    disabled={!selectedPhase?.isFirstParty ? true : false}
                                                    checked={localState?.ftePermission && selectedPhase?.isFirstParty}
                                                    control={<Checkbox size="small" name="ftePermission" />} onChange={(e) => { handleChecked(e); }}
                                                    label={<span className={classes.inputFontSize}>{"FTE Permission"}</span>}
                                                />
                                                <FormControlLabel
                                                    id="U_CU_CaseCreatedReport"
                                                    className={classes.permission}
                                                    // disabled={!selectedPhase?.isFirstParty ? true : false}
                                                    control={<Checkbox size="small" name="caseCreatedReport" />} onChange={(e) => { handleChecked(e); }}
                                                    label={<span className={classes.inputFontSize}>{"Case Created Report"}</span>}
                                                />
                                                <FormControlLabel
                                                    id="U_CU_ActivityCountReport"
                                                    className={classes.permission}
                                                    control={<Checkbox size="small" name="activityCountReport" />} onChange={(e) => { handleChecked(e); }}
                                                    label={<span className={classes.inputFontSize}>{"Activity Count Report"}</span>}
                                                />
                                            </FormGroup>
                                        </FormControl>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Box className={classes.userboxviewStyle}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sm={12}>
                                                <Typography variant="h6" gutterBottom className={`${classes.root} ${classes.userStyle1}`}>{" CONTINGENCY NOTIFICATION ACCESS"}</Typography>
                                                <FormControl component="fieldset">
                                                    <FormGroup row>
                                                        <FormControlLabel
                                                            id="U_CU_paymentUpdates"
                                                            className={classes.permission}
                                                            control={<Checkbox size="small" name="disputeValidation" />} onChange={(e) => { handleChecked(e); }}
                                                            label={<span className={classes.inputFontSize}>{"Dispute Validation Notification"}</span>}
                                                        />
                                                        <FormControlLabel
                                                            id="U_CU_closeAccounts"
                                                            className={classes.permission}
                                                            control={<Checkbox size="small" name="paymentVerification" />} onChange={(e) => { handleChecked(e); }}
                                                            label={<span className={classes.inputFontSize}>{"Payment Verification Notification"}</span>}
                                                        />
                                                        <FormControlLabel
                                                            id="U_CU_rollAccounts"
                                                            className={classes.permission}
                                                            control={<Checkbox size="small" name="backupDocument" />} onChange={(e) => { handleChecked(e); }}
                                                            label={<span className={classes.inputFontSize}>{"Backup Document Request Notification"}</span>}
                                                        />
                                                    </FormGroup>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={12} >
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12} sm={6} >
                                                        <Typography variant="h6" gutterBottom className={`${classes.root} ${classes.userStyle1}`}>{"ASSIGN CLIENT CODE"}</Typography>

                                                        <ClickAwayListener onClickAway={(e) => { handleAgentNotificationClientClickAway(e, notificationSearchClientCode.length > 0) }}>
                                                            <div style={{ display: 'flex' }}>
                                                                <Autocomplete
                                                                    multiple size="small"
                                                                    loading={notificationClientLoading}
                                                                    filterOptions={(options) => options}
                                                                    className={classes.clientCodeBox}
                                                                    autoComplete={false}
                                                                    id="checkboxes-tags-demo"
                                                                    options={notificationConfirmedClients}
                                                                    classes={{ paper: classes.paper2 }}
                                                                    disableClearable
                                                                    value={notificationSelectedClients}
                                                                    open={notificationClientCodeOpen}
                                                                    renderTags={() => null}
                                                                    onChange={(e, selected) => { handleNotificationClientSelect(e, selected); }}
                                                                    onOpen={() => {
                                                                        setNotificationClientCodeOpen(true);
                                                                    }}
                                                                    onClose={(e: any, reason: AutocompleteCloseReason) => {
                                                                        if (reason === "toggleInput") {
                                                                            setNotificationClientCodeOpen(false);
                                                                        }
                                                                    }}
                                                                    getOptionDisabled={(option) => option?.clientCode}
                                                                    getOptionLabel={(option) => option?.clientCode}
                                                                    getOptionSelected={(option, value) => (option?.clientCode == value?.clientCode)}
                                                                    renderOption={(option, { selected }) => (
                                                                        <span className={`${selected ? classes.GreenColor : classes.clientCodeStyle}`} >
                                                                            {`${option?.clientCode} -- ${option?.clientName}`}
                                                                        </span>
                                                                    )}
                                                                    ListboxProps={
                                                                        {
                                                                            style: {
                                                                                maxHeight: '160px'
                                                                            }
                                                                        }
                                                                    }
                                                                    renderInput={(params) => (
                                                                        <TextField {...params} fullWidth size="small"
                                                                            value={notificationSearchClientCode} variant="outlined"
                                                                            type='text' placeholder="Search Clients"
                                                                            onChange={(e) => { setNotificationSearchClientCode(e.target.value); }}
                                                                            onKeyDown={event => {
                                                                                if (event.key === 'Enter') { handleNotificationSearchClient(event); handleNotificationClientOpen(); setNotificationClientCodeOpen(true); }
                                                                            }}
                                                                            InputProps={{
                                                                                ...params.InputProps,
                                                                                inputProps: {
                                                                                    ...params.inputProps,
                                                                                    maxLength: 256
                                                                                },
                                                                                classes: {
                                                                                    root: classes.inputFontSize,
                                                                                },
                                                                                endAdornment: (
                                                                                    <React.Fragment>
                                                                                        {notificationClientLoading ? <CircularProgress color="primary" size={15} /> : null}
                                                                                        {params.InputProps.endAdornment}
                                                                                        <InputAdornment position="end">
                                                                                            <IconButton onClick={(_) => { handleNotificationClientOpen(); setNotificationClientCodeOpen(true); }}>
                                                                                                <SearchIcon className={classes.IconSizeStyle} />
                                                                                            </IconButton>
                                                                                        </InputAdornment>
                                                                                        <Box display="flex" width="100%" className={classes.CheckBoxBorder}>
                                                                                            <Box className={classes.ClientcheckBoxStyle} width="60%">
                                                                                                <Checkbox size="small" disabled={loading || !confirmedClients?.length}
                                                                                                    checked={notificationCheckAll}
                                                                                                    onChange={notificationCheckAllChange}
                                                                                                    id="check-all"
                                                                                                />
                                                                                                <span style={{ fontSize: 14, marginLeft: '2px' }}><b>SELECT ALL</b></span>
                                                                                            </Box>
                                                                                            <Box className={classes.ClientcheckBoxStyle} width="40%">
                                                                                                <Checkbox size="small" disabled={notificationClientLoading || !confirmedClients?.length}
                                                                                                    checked={notificationDeselectAll}
                                                                                                    onChange={(e) => { notificationDeselectAllChange(e); }}
                                                                                                    id="check-all"
                                                                                                />
                                                                                                <span style={{ fontSize: 14 }}><b>DESELECT ALL</b></span>
                                                                                            </Box >
                                                                                        </Box >
                                                                                    </React.Fragment >
                                                                                ),
                                                                            }
                                                                            }
                                                                            className={classes.textBoxStyle}
                                                                        />
                                                                    )}
                                                                />
                                                            </div>
                                                        </ClickAwayListener >
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} >
                                                        <Box className={classes.LabelBoxStyle}>
                                                            <FormLabel className={classes.formLabel}> Selected Client Code : {notificationSelectedClients?.length}</FormLabel>
                                                        </Box>
                                                        <Card className={classes.boxBorder}>
                                                            <Scrollbars autoHide
                                                                autoHideTimeout={1000}
                                                                autoHideDuration={10}
                                                                autoHeight
                                                                className={`${classes.textBoxStyle2} ${classes.scrollMargin}`}
                                                                autoHeightMin={120}
                                                                autoHeightMax={120}
                                                                style={{ width: '100%' }}>
                                                                <div className={`${classes.valueContainer}`} >
                                                                    {notificationSelectedClients?.length > 0 ?
                                                                        (
                                                                            notificationSelectedClients?.map((v, index) => (
                                                                                <Chip key={index} size="small" color="secondary" label={v.clientCode} onDelete={onNotificationDelete(v.clientCode)} />
                                                                            ))
                                                                        )
                                                                        :
                                                                        <Typography variant='body2' style={{ color: 'rgb(1, 41, 114)' }} align='center' className={classes.clientSelection}>
                                                                            <InfoIcon fontSize='large' />
                                                                            <br />
                                                                            <b> Selected client code list</b>
                                                                        </Typography>
                                                                    }
                                                                </div>
                                                            </Scrollbars>
                                                        </Card>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions style={{ borderTop: '1px solid lightgray' }}>
                    <Button id="IM_ok_btn" size="small" variant="contained" startIcon={<CheckCircleIcon />} onClick={() => { handleCreateUser(); }}
                        disabled={(!localState.firstName || !localState.lastName || !localState.emailAddress) || !selectedClients.length
                            || !(((!localState?.disputeValidation && !localState?.paymentVerification && !localState?.backupDocument) && !notificationSelectedClients?.length ? true
                                : (localState?.disputeValidation || localState?.paymentVerification || localState?.backupDocument) && notificationSelectedClients?.length ? true
                                    : false))
                        }
                        className={classes.yesButton} color="primary" autoFocus>
                        Save
                    </Button>
                    <Button id="IM_Cancel_Btn" size="small" variant="contained" startIcon={<CancelIcon />} autoFocus className={classes.closeBtn} onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog TransitionComponent={Transition}
                fullScreen={fullScreen} open={alertOpen} onClose={handleCloseExistsUser} maxWidth={'sm'}
                aria-labelledby="responsive-dialog-title" PaperProps={{ style: { borderRadius: 10 } }} >
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleCloseExistsUser}>
                    <Typography variant="h5" gutterBottom className={classes.titleHeader}>
                        CREATE USER
                    </Typography>
                </DialogTitleHeader>
                <DialogContent>
                    <Typography variant="h6" className={classes.titleConfirm} gutterBottom>
                        User : {localState?.emailAddress} already exists.
                    </Typography>
                </DialogContent>
                <DialogActions >
                    <Button id="DU_ok_btn" size="small" variant="contained" onClick={(e) => { setAlertOpen(false); setOpen(true); }} className={classes.existButton}>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar className="snackBarStyle" open={createUser} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="success" className="alertStyle">
                    User {localState.firstName} {localState.lastName} is Created Successfully!
                </Alert>
            </Snackbar>

            <Snackbar className="snackBarStyle" open={isValid} anchorOrigin={{
                vertical: 'top', horizontal: 'center'
            }} autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="error" className="alertStyle">
                    Please select atleast one client code.
                </Alert>
            </Snackbar>

        </React.Fragment >
    )
}

export default CreateAgentUser
