import {
  Backdrop, Box, Button, Card, Checkbox, Chip, CircularProgress, ClickAwayListener, Dialog, DialogActions, DialogContent, FormControl,
  FormControlLabel, FormLabel, IconButton, InputAdornment, Snackbar, TextField, Typography, useMediaQuery, useTheme
} from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Autocomplete, { AutocompleteCloseReason } from '@material-ui/lab/Autocomplete';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import PuffLoader from 'react-spinners/PuffLoader';
// Icons
import CancelIcon from '@material-ui/icons/Cancel';
import SearchIcon from "@material-ui/icons/Search";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@mui/icons-material/Info';
import BorderColorIcon from '@mui/icons-material/BorderColor';
// Componant
import { ARMLogo, firstNameregex, lastNameregex } from '../../constants/Constant';
import { Transition } from '../GlobalStyles/DialogBoxTransition';
import useStyles from './AgentUserCss';
import ManageActivity from './ManageActivity';
// Models
import _ from 'lodash';
import { CONT, FIRSTSTP, PREC } from '../../constants/Constant';
import { IAgentClient, IAgentClientCode, IAgentClientDetails, IFTEHistoryDetail, IPhaseSelection } from '../../models/AgentUser/IAgentClientDetails';
import { IAgentUserDetails } from '../../models/AgentUser/IAgentUserDetails';
import { IClientPhaseDetails } from '../../models/AgentUser/IClientPhaseDetails';
import { useGlobalState } from '../../store/GlobalStore';
import { usePost } from '../../utils/apiHelper';
import { AppBar, Toolbar, Grid, FormGroup, Paper } from '@mui/material';
import { IAgentUserEditDetails } from '../../models/AgentUser/IAgentUserEditDetails';

const EditAgentUser: React.FC<{ row: IAgentUserEditDetails, userId: number, onSave: () => void }> = (props) => {
  const theme = useTheme();
  const classes = useStyles();
  const { state } = useGlobalState();

  /* API EndPoint */
  const GetAgentClientList = "AgentUser/GetAgentClientList";
  const ModifyAgentUser = "AgentUser/ModifyAgentUser";
  const GetAgentUserById = "AgentUser/GetAgentUserById";
  const AgentFteInsert = "AgentUser/InsertFTEHistory";
  const AgentFTEHistory = "/AgentUser/InsertAgentFTEHistory";

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [updateUser, setUpdateUser] = useState(false);
  const [ProgressBar, setShowProgressBar] = useState(false);
  const [ClientCodeOpen, setClientCodeOpen] = useState(false);
  const [deselectClient, setDeselectClient] = useState(false);

  const [searchClientCode, setSearchClientCode] = useState("");
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [phase, setPhase] = useState<IPhaseSelection[]>([]);
  const [confirmedClients, setConfirmedClients] = useState([]);
  const [selectedClients, setSelectedClients] = useState<any>([]);

  const [firstnameerror, setFirstNameError] = useState("");
  const [Lastnameerror, setLastNameError] = useState("");
  const [isDisable, setIsDisable] = useState(false);
  const [accessTemplate, setAccessTemplate] = useState(false);
  const [fteOrginalValue, setFteOrginalValue] = useState<boolean>(null);
  const [isClientCodeModified, setIsClientCodeModified] = useState(false);

  /* Notification access state */
  const [notificationConfirmedClients, setNotificationConfirmedClients] = useState<IAgentClient[]>([]);
  const [notificationSearchClientCode, setNotificationSearchClientCode] = useState("");
  const [notificationDeselectAll, setNotificationDeselectAll] = useState(false);
  const [notificationCheckAll, setNotificationCheckAll] = useState(false);
  const [notificationClientCodeOpen, setNotificationClientCodeOpen] = useState(false);
  const [notificationSelectedClients, setNotificationSelectedClients] = useState<any>([]);
  const [notificationClientLoading, setNotificationClientLoading] = useState(false);
  const [notificationClientClear, setNotificationClientClear] = useState("false");


  let userDetails: IAgentUserDetails = {
    emailAddress: "",
    firstName: "",
    lastName: "",
    createdDate: moment(props?.row?.createdDate)?.format("YYYY-MM-DD"),
    modifiedDate: moment(new Date())?.format("YYYY-MM-DD"),
    paymentUpdates: false,
    closeAccounts: false,
    rollAccounts: false,
    clientInformation: false,
    readOnlyAccess: false,
    isActive: true,
    portalUserExist: false,
    remainingAccountCount: false,
    dailyActivityCount: false,
    dailyTargetedCount: false,
    accountPlacementCount: false,
    accountUpdateHistory: false,
    accountActivityHistory: false,
    allFirstPartySelected: false,
    allPrecSelected: false,
    allContSelected: false,
    agentUsersReport: false,
    ftePermission: false,
    disputeValidation: props?.row?.disputeValidation,
    paymentVerification: props?.row?.paymentVerification,
    backupDocument: props?.row?.backupDocument,
    caseCreatedReport: false,
    activityCountReport: false
  }

  let fteHistoryDetail: IFTEHistoryDetail = {
    agentUserId: props?.userId,
    clientCode: null,
    phaseCode: null,
    previousActivityTemplateId: null,
    newActivityTemplateId: null
  }

  let clientPhaseDetails: IClientPhaseDetails = {
    isFirstParty: true,
    isFlatFee: false,
    isContingency: false
  }

  const [localState, setLocalState] = useState(userDetails);
  const [selectedPhase, setSelectedPhase] = useState(clientPhaseDetails);

  const handleFetchUser = () => {
    (async () => {
      let request = {
        "agentUserId": props?.userId
      };
      await usePost<{ agentClientDetails: IAgentClientCode[], agentUserDetails: IAgentUserDetails[], clientPhaseDetails: IClientPhaseDetails[], notificationClientDetails: IAgentClientCode[] }>(GetAgentUserById, request).then((r) => {
        setLocalState(r?.data?.agentUserDetails[0]);
        setFteOrginalValue(r?.data?.agentUserDetails[0]?.ftePermission);
        setSelectedClients(r?.data?.agentClientDetails);
        setSelectedPhase(r?.data?.clientPhaseDetails[0]);
        setNotificationSelectedClients(r?.data?.notificationClientDetails);
        let PhaseList = _.cloneDeep(phase);
        r?.data?.clientPhaseDetails?.forEach((p) => {
          if (p?.isFirstParty) {
            let phaseCode: IPhaseSelection = {
              phase: FIRSTSTP
            };
            PhaseList.push(phaseCode);
          }
          if (p?.isFlatFee) {
            let phaseCode: IPhaseSelection = {
              phase: PREC
            };
            PhaseList.push(phaseCode);
          }
          if (p?.isContingency) {
            let phaseCode: IPhaseSelection = {
              phase: CONT
            };
            PhaseList.push(phaseCode);
          }
        })
        setPhase(PhaseList);
        handleClientOpen(PhaseList);
        handleNotificationClientOpen();
      }).finally(() => {
        setShowProgressBar(false);
      });
    })()
  }

  const handleRefresherUser = () => {
    (async () => {
      let request = {
        "agentUserId": props?.userId
      };
      await usePost<{ agentClientDetails: IAgentClientCode[], agentUserDetails: IAgentUserDetails[], clientPhaseDetails: IClientPhaseDetails[], notificationClientDetails: IAgentClientCode[] }>(GetAgentUserById, request).then((r) => {
        setLocalState(r?.data?.agentUserDetails[0]);
        setSelectedClients(r?.data?.agentClientDetails);
        setSelectedPhase(r?.data?.clientPhaseDetails[0]);
        setNotificationSelectedClients(r?.data?.notificationClientDetails);
      }).finally(() => {
        setShowProgressBar(false);
      });
    })()
  }

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setUpdateUser(false);
  };

  const handleChange = (e) => {
    setAccessTemplate(true);
    let name = e.target.name;
    setLocalState({
      ...localState,
      [name]: e.target.value
    });
  }

  const handleChecked = (e) => {
    setAccessTemplate(true);
    let name = e.target.name;
    setLocalState({
      ...localState,
      [name]: e.target.checked
    });
  }

  const handleUpdateUser = () => {
    setShowProgressBar(true);
    (async () => {
      let newClient: IAgentClientCode[] = [];

      selectedClients?.map((c) => {
        phase?.map((p) => {
          let ClientDetail: IAgentClientCode = { clientCode: c?.clientCode, phaseCode: p.phase }
          newClient.push(ClientDetail);
        })
      });

      let newNotificationClient: IAgentClientCode[] = [];
      notificationSelectedClients?.map((r) => {
        let NotificationClientDetails: IAgentClientCode = { clientCode: r?.clientCode, phaseCode: CONT }
        newNotificationClient.push(NotificationClientDetails);
      })

      const batchSize = 500;
      let newUser: IAgentUserDetails[] = [];
      newUser.push(localState);

      if (newClient.length < batchSize) {
        handleBatchUpdate(newUser, newClient, 4, newNotificationClient);
      } else {
        let batchClientCodeList: IAgentClientCode[] = [];
        let batchCount = 0;
        handleBatchUpdate(newUser, newClient, 1, newNotificationClient);
        for (let i = 1; i <= newClient.length; i++) {
          batchClientCodeList.push(newClient[i - 1]);

          if (i % batchSize == 0) {
            handleBatchUpdate(newUser, batchClientCodeList, 2, newNotificationClient);
            batchClientCodeList = [];
            batchCount++;

            sleep(2000);
          }
        }

        if (batchClientCodeList?.length > 0 && batchCount == Math.floor(newClient?.length / batchSize)) {
          handleBatchUpdate(newUser, batchClientCodeList, 2, newNotificationClient);
          batchClientCodeList = [];
        }
        handleBatchUpdate(newUser, batchClientCodeList, 3, newNotificationClient);
      }
    })().finally(() => {
      setShowProgressBar(false);
      setUpdateUser(true);
      setAccessTemplate(false);
      if (isClientCodeModified) {
        let newClientCodeList: IAgentClientDetails[] = [];
        selectedClients?.forEach((result) => {
          let AgentClientCodeList: IAgentClientDetails = { clientCode: result?.clientCode }
          newClientCodeList.push(AgentClientCodeList);
        })
        handleInsertFTEHistory(newClientCodeList);
      }
    });
  }

  const handleUpdateHistory = () => {
    if (fteOrginalValue != localState?.ftePermission && !isClientCodeModified) {
      insertAgentFTEHistory();
    }
  }

  const handleInsertFTEHistory = (clientCodeList: IAgentClientDetails[]) => {
    let request = {
      "agentClientCodes": clientCodeList,
      "agentUserId": props?.userId,
      "fteActionTakenBy": state?.userAccessContext?.id
    }
    usePost<any>(AgentFTEHistory, request).then((r) => {

    }).finally(() => {
      setIsClientCodeModified(false);
      setShowProgressBar(false);
      handleRefresherUser();
      props.onSave();
    });
  }

  const insertAgentFTEHistory = () => {
    let newFteHistoryDetails: IFTEHistoryDetail[] = [];
    newFteHistoryDetails.push(fteHistoryDetail);
    let request = {
      "agentFteHistory": newFteHistoryDetails,
      "fteAction": localState?.ftePermission ? 1 : 2,
      "fteActionTakenBy": state?.userAccessContext?.id
    }
    usePost<any>(AgentFteInsert, request).then((r) => {

    }).finally(() => {
      setIsClientCodeModified(false);
      props.onSave();
      handleRefresherUser();
      setShowProgressBar(false);
    });
  }

  const sleep = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  const handleBatchUpdate = ((userInfo: IAgentUserDetails[], batchClientCodeList: IAgentClientCode[], clearFlag: number, batchNotificationclientCodeList: IAgentClientCode[]) => {
    let request = {
      "agentUserId": props?.userId,
      "modifiedBy": state?.userAccessContext?.id,
      "clearHistoryFlag": clearFlag,
      "agentClientDetails": batchClientCodeList,
      "agentUserDetails": userInfo,
      "notificationClientDetails": batchNotificationclientCodeList
    }
    usePost(ModifyAgentUser, request).finally(() => {
    });
  });

  const HandleFirstNameVal = (e) => {
    const FirstNameVal = e.target.value;

    if (!FirstNameVal.match(firstNameregex)) {
      setFirstNameError("");
      setIsDisable(false);
    } else if (FirstNameVal === "") {
      setFirstNameError("First Name is Required");
      setIsDisable(true);
    } else {
      setFirstNameError("Please enter characters only");
      setIsDisable(true);
    }
  };

  const HandleLastNameVal = (e) => {
    const LastNameVal = e.target.value;

    if (!LastNameVal.match(lastNameregex)) {
      setLastNameError("");
      setIsDisable(false);
    } else if (LastNameVal === "") {
      setLastNameError("Last Name is Required");
      setIsDisable(true);
    }
    else {
      setLastNameError("Please enter characters only");
      setIsDisable(true);
    }
  };

  const checkAllChange = (event) => {
    setAccessTemplate(true);
    setCheckAll(event.target.checked);
    if (event.target.checked) {
      var NewClientList = confirmedClients.filter((c) => {
        return !selectedClients.find((r) => {
          return c.clientCode == r.clientCode
        })
      });
      setSelectedClients([...new Set(selectedClients?.concat(NewClientList?.map((r) => r)))]);
      if (selectedPhase?.isFirstParty && selectedPhase?.isFlatFee && selectedPhase?.isContingency) {
        setLocalState({ ...localState, allFirstPartySelected: true, allPrecSelected: true, allContSelected: true });
      } else if (selectedPhase?.isFirstParty && selectedPhase?.isFlatFee) {
        setLocalState({ ...localState, allFirstPartySelected: true, allPrecSelected: true });
      } else if (selectedPhase?.isFirstParty && selectedPhase?.isContingency) {
        setLocalState({ ...localState, allFirstPartySelected: true, allContSelected: true });
      } else if (selectedPhase?.isFlatFee && selectedPhase?.isContingency) {
        setLocalState({ ...localState, allPrecSelected: true, allContSelected: true });
      } else if (selectedPhase?.isFirstParty) {
        setLocalState({ ...localState, allFirstPartySelected: true });
      } else if (selectedPhase?.isFlatFee) {
        setLocalState({ ...localState, allPrecSelected: true });
      } else if (selectedPhase?.isContingency) {
        setLocalState({ ...localState, allContSelected: true });
      }
      setClientCodeOpen(false);
    }
    setIsClientCodeModified(true);
    setCheckAll(false);
    handleClear();
    setSearchClientCode("");
  };

  const notificationCheckAllChange = (event) => {
    setAccessTemplate(true);
    setNotificationCheckAll(event.target.checked);
    if (event.target.checked) {
      var NewClientList = notificationConfirmedClients.filter((c) => {
        return !notificationSelectedClients.find((r) => {
          return c.clientCode == r.clientCode
        })
      });
      setNotificationSelectedClients([...new Set(notificationSelectedClients?.concat(NewClientList?.map((r) => r)))]);
      setNotificationClientCodeOpen(false);
    }
    setNotificationCheckAll(false);
    handleClientCodeClear();
    setNotificationSearchClientCode("");
  };

  const DeselectAllChange = (event) => {
    setAccessTemplate(true);
    setDeselectClient(event.target.checked);
    if (event.target.checked) {
      setSelectedClients([]);
      setClientCodeOpen(false);
    }
    setLocalState({ ...localState, allFirstPartySelected: false, allPrecSelected: false, allContSelected: false });
    setDeselectClient(false);
    handleClear();
    setIsClientCodeModified(false);
    setSearchClientCode("");
  };

  const notificationDeselectAllChange = (event) => {
    setAccessTemplate(true);
    setNotificationDeselectAll(event.target.checked);
    if (event.target.checked) {
      setNotificationSelectedClients([]);
      setNotificationClientCodeOpen(false);
    }
    setLocalState({ ...localState, allFirstPartySelected: false, allPrecSelected: false, allContSelected: false });
    setNotificationDeselectAll(false);
    handleClientCodeClear();
    setNotificationSearchClientCode("");
  };

  const handleClickAway = (e, isSearchClear: boolean) => {
    setClientCodeOpen(false);
    if (isSearchClear) {
      handleClear();
    }
  };

  const handleClickAwayClient = (e, isSearchClear: boolean) => {
    setNotificationClientCodeOpen(false);
    if (isSearchClear) {
      handleClientCodeClear();
    }
  };

  const onDelete = (clientcode: string) => () => {
    setAccessTemplate(true);
    setIsClientCodeModified(true);
    setSelectedClients((value) => value.filter((v) => (v.clientCode !== clientcode)));
  };

  const onNotificationDelete = (clientcode: string) => () => {
    setAccessTemplate(true);
    setNotificationSelectedClients((value) => value.filter((v) => (v.clientCode !== clientcode)));
  };

  const handleClientSelect = (event, selected) => {
    setAccessTemplate(true);
    setSelectedClients(selected);
    setIsClientCodeModified(true);
    if (selected?.length <= 0) {
      setCheckAll(false);
    }
  }

  const handleNotificationClientSelect = (event, selected) => {
    setAccessTemplate(true);
    setNotificationSelectedClients(selected);
    if (selected?.length <= 0) {
      setNotificationCheckAll(false);
    }
  }

  const handleSearchClient = (e) => {
    setSearchClientCode(e.target.value);
  }

  const handleNotificationSearchClient = (e) => {
    setNotificationSearchClientCode(e.target.value);
    setNotificationClientClear("");
  }


  useEffect(() => {
    if (!selectedPhase?.isFirstParty && !selectedPhase?.isFlatFee && !selectedPhase?.isContingency) {
      setSelectedPhase({
        ...selectedPhase,
        isFirstParty: true
      });
      let PhaseList = _.cloneDeep(phase);
      let phaseCode: IPhaseSelection = {
        phase: FIRSTSTP
      };
      if (!PhaseList?.includes(phaseCode)) {
        PhaseList.push(phaseCode);
      };
      setPhase(PhaseList);
      handleClientGet(PhaseList?.map((r) => r?.phase).join(","));
    }
  }, [selectedPhase])

  const handleClientGet = (Phase: string) => {
    (async () => {
      setLoading(true);
      let request = {
        "searchText": searchClientCode?.trim().toLocaleUpperCase(),
        "phaseDetails": Phase
      };
      await usePost<IAgentClient[]>(GetAgentClientList, request).then((r) => {
        setConfirmedClients(r?.data);
        setLoading(false);
      }).finally(() => {
        setCheckAll(false);
        setNotificationCheckAll(false);
      });
    })()
  };

  const handlePhaseChange = (e) => {
    setAccessTemplate(true);
    const { name, checked } = e.target;
    setSelectedPhase({
      ...selectedPhase,
      [name]: checked
    });

    let PhaseList = _.cloneDeep(phase);
    if ((phase.findIndex(x => x.phase == e.target.value) <= -1)) {
      let phaseCode = { phase: e?.target.value };
      if (!PhaseList?.includes(phaseCode)) {
        PhaseList.push(phaseCode);
      };
      setPhase(PhaseList);
    }
    else {
      if ((phase.findIndex(x => x.phase == e.target.value) > -1)) {
        PhaseList = phase.filter(x => x.phase !== e.target.value)
        setPhase(phase.filter(x => x.phase !== e.target.value));
      };
    }
    handleClientOpen(PhaseList);
  }

  useEffect(() => {
    if (!selectedPhase?.isFirstParty) {
      setLocalState((prevState) => ({
        ...prevState,
        ftePermission: false,
      }));
    }
    else {
      setLocalState((prevState) => ({
        ...prevState,
        ftePermission: true,
      }));
    }
  }, [selectedPhase?.isFirstParty]);

  const handleClientOpen = (Phase: IPhaseSelection[]) => {
    (async () => {
      setLoading(true);
      setOpen(true);
      let request = {
        "searchText": searchClientCode?.trim().toLocaleUpperCase(),
        "phaseDetails": Phase?.map((r) => r?.phase)?.join(",")
      };
      await usePost<IAgentClient[]>(GetAgentClientList, request).then((r) => {
        setConfirmedClients(r?.data);
        setLoading(false);
      }).finally(() => {
        setCheckAll(false);
      });
    })()
  };

  const handleNotificationClientOpen = () => {
    (async () => {
      setNotificationConfirmedClients([]);
      setNotificationClientLoading(true);
      setOpen(true);
      let request = {
        "searchText": notificationSearchClientCode?.trim().toLocaleUpperCase(),
        "phaseDetails": CONT
      }
      await usePost<IAgentClient[]>(GetAgentClientList, request).then((r) => {
        setNotificationConfirmedClients(r?.data);
        setNotificationClientLoading(false);
      }).finally(() => {
        setNotificationCheckAll(false);
      });
    })()
  };

  const handleClear = () => {
    (async () => {
      setOpen(true);
      let request = {
        "searchText": null,
        "phaseDetails": phase?.map((r) => r?.phase)?.join(",")
      };
      await usePost<IAgentClient[]>(GetAgentClientList, request).then((r) => {
        setConfirmedClients(r?.data);
      }).finally(() => {
        setSearchClientCode("");
      });
    })()
  };

  const handleClientCodeClear = () => {
    (async () => {
      setOpen(true);
      setNotificationClientLoading(true);
      let request = {
        "searchText": null,
        "phaseDetails": CONT
      }
      await usePost<IAgentClient[]>(GetAgentClientList, request).then((r) => {
        setNotificationConfirmedClients(r?.data);
      }).finally(() => {
        setNotificationSearchClientCode("")
        setNotificationClientLoading(false);
      });
    })()
  };

  const handleClose = () => {
    setOpen(false);
    setLocalState(userDetails);
    setConfirmedClients([]);
    setSelectedClients([]);
    setNotificationSelectedClients([]);
    setPhase([]);
    setSelectedPhase(clientPhaseDetails);
    setFirstNameError(null);
    setLastNameError(null);
    setAccessTemplate(false);
  };

  return (
    <React.Fragment>
      <Button size="small" id="EU_Edit" startIcon={<BorderColorIcon />} variant="contained" color="primary" className={classes.editButton}
        onClick={(_) => { setOpen(true); setShowProgressBar(true); handleFetchUser(); }}>
        EDIT
      </Button>

      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition} className={classes.dialog}>
        <AppBar position="fixed" className={classes.appBar} sx={{ position: 'relative' }}>
          <Toolbar className={classes.toolbarStyle}>
            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" >
              <img alt="ARM_Solutions" className={classes.menuButton1} src={ARMLogo} />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              <b>EDIT AGENT USER</b>
            </Typography>
            <IconButton edge="end" color="inherit" onClick={() => handleClose()} aria-label="close" style={{ padding: '5px' }}>
              <CloseIcon style={{ color: '#000', fontSize: 22 }} />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ padding: "10px" }}>
          <Backdrop className={classes.backdrop} open={ProgressBar}>
            <PuffLoader size={80} color={"white"} speedMultiplier={1} />
          </Backdrop>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Box className={classes.userboxviewStyle}>
                <Typography variant="h6" gutterBottom className={`${classes.root} ${classes.userStyle}`}>USER PROFILE</Typography>
                <TextField
                  id="U_CU_FirstName"
                  size="small"
                  required label="First Name"
                  variant="outlined"
                  name="firstName"
                  value={localState?.firstName}
                  onChange={(e) => { handleChange(e); HandleFirstNameVal(e); }}
                  helperText={firstnameerror}
                  error={!!firstnameerror}
                  inputProps={{ maxlength: 100 }}
                  className={`${classes.firstTextfield} ${classes.firstnameInputSpacing}`}
                  autoComplete='off'
                  InputProps={{
                    classes: {
                      root: classes.blueBorderBottom,
                    },
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.inputFontSize,
                    },
                  }}
                />
                <TextField
                  id="U_CU_FirstName"
                  size="small" required
                  label="Last Name"
                  variant="outlined"
                  name="lastName"
                  value={localState?.lastName}
                  onChange={(e) => { handleChange(e); HandleLastNameVal(e); }}
                  helperText={Lastnameerror} error={!!Lastnameerror}
                  inputProps={{ maxlength: 100 }}
                  className={`${classes.lastTextfield} ${classes.firstnameInputSpacing}`}
                  autoComplete='off'
                  InputProps={{
                    classes: {
                      root: classes.blueBorderBottom,
                    },
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.inputFontSize,
                    },
                  }}
                />
                <TextField id="U_CU_Email"
                  size="small" required
                  label="Email"
                  variant="outlined"
                  name="emailAddress"
                  value={localState?.emailAddress}
                  onChange={(e) => { handleChange(e); }}
                  inputProps={{ maxlength: 50 }}
                  disabled={true}
                  className={`${classes.textFieldBorderEdit}`}
                  autoComplete='off'
                  InputProps={{
                    classes: {
                      root: classes.blueBorderBottom,
                    },
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.inputFontSize,
                    },
                  }} />
                <Box className={classes.LabelBoxStyle}>
                  <FormLabel className={classes.formLabel}> Selected Client Code : {selectedClients.length}</FormLabel>
                </Box>
                {selectedClients?.length > 0 ?
                  <Paper className={classes.boxBorder}>
                    <Scrollbars autoHide
                      autoHideTimeout={1000}
                      autoHideDuration={10}
                      autoHeight
                      className={`${classes.textBoxStyle2} ${classes.scrollMargin}`}
                      autoHeightMin="auto"
                      autoHeightMax={80}
                      style={{ width: '98%' }}>
                      <div className={`${classes.valueContainer}`} >
                        {selectedClients.map((v, index) => (
                          <Chip key={index} size="small" color="secondary" label={v.clientCode} onDelete={onDelete(v.clientCode)} />
                        ))}
                      </div>
                    </Scrollbars>
                  </Paper>
                  :
                  null
                }
                <ClickAwayListener onClickAway={(e) => { handleClickAway(e, searchClientCode.length > 0) }}>
                  <div style={{ display: 'flex' }}>
                    <Autocomplete
                      multiple size="small"
                      loading={loading}
                      filterOptions={(options) => options}
                      className={classes.clientCodeBox}
                      autoComplete={false}
                      id="checkboxes-tags-demo"
                      options={confirmedClients}
                      classes={{ paper: classes.paper2 }}
                      disableClearable
                      value={selectedClients}
                      open={ClientCodeOpen}
                      renderTags={() => null}
                      onChange={(e, selected) => { handleClientSelect(e, selected); }}
                      onOpen={() => {
                        setClientCodeOpen(true);
                      }}
                      onClose={(e: any, reason: AutocompleteCloseReason) => {
                        if (reason === "toggleInput") {
                          setClientCodeOpen(false);
                        }
                      }}
                      getOptionDisabled={(option) => option.clientCode}
                      getOptionLabel={(option) => option.clientCode}
                      getOptionSelected={(option, value) => (option.clientCode == value.clientCode)}
                      renderOption={(option, { selected }) => (
                        <span className={`${selected ? classes.GreenColor : classes.clientCodeStyle}`} >
                          {`${option.clientCode} -- ${option.clientName}`}
                        </span>
                      )}
                      ListboxProps={{ style: { maxHeight: '160px' } }}
                      renderInput={(params) => (
                        <TextField {...params} fullWidth size="small"
                          value={searchClientCode} variant="outlined"
                          type='text' placeholder="Search Clients"
                          onChange={(e) => { setSearchClientCode(e.target.value); }}
                          onKeyDown={event => { if (event.key === 'Enter') { handleSearchClient(event); handleClientOpen(phase); setClientCodeOpen(true); } }}
                          InputProps={{
                            ...params.InputProps,
                            classes: {
                              root: classes.inputFontSize,
                            },
                            endAdornment: (
                              <React.Fragment>
                                {loading ? <CircularProgress color="primary" size={15} /> : null}
                                {params.InputProps.endAdornment}
                                <InputAdornment position="end">
                                  <IconButton onClick={(_) => { handleClientOpen(phase); setClientCodeOpen(true); }}>
                                    <SearchIcon className={classes.IconSizeStyle} />
                                  </IconButton>
                                </InputAdornment>
                                <Box display="flex" width="100%" className={classes.CheckBoxBorder}>
                                  <Box className={classes.ClientcheckBoxStyle} width="64%">
                                    <Checkbox size="small" disabled={loading || !confirmedClients?.length}
                                      checked={checkAll}
                                      onChange={checkAllChange}
                                      id="check-all"
                                    />
                                    <span style={{ fontSize: 14, marginLeft: '3px' }}><b>SELECT ALL</b></span>
                                  </Box>
                                  <Box className={classes.ClientcheckBoxStyle} width="36%">
                                    <Checkbox size="small" disabled={loading || !confirmedClients?.length}
                                      checked={deselectClient}
                                      onChange={(e) => { DeselectAllChange(e); }}
                                      id="check-all"
                                    />
                                    <span style={{ fontSize: 14 }}><b>DESELECT ALL</b></span>
                                  </Box >
                                </Box >
                                <Card className={classes.checkBoxClientPhase} style={{ padding: '7px' }}>
                                  <FormControlLabel
                                    id="U_CU_FileAccess"
                                    control={<Checkbox size="small" name="isFirstParty" value={FIRSTSTP} className={classes.checkboxSeletct} checked={selectedPhase?.isFirstParty} onChange={(e) => { handlePhaseChange(e); }} />}
                                    label={<span className={classes.CheckBoxPhaseStyle}><b>1ST PARTY</b></span>}
                                  />
                                  <FormControlLabel
                                    id="U_CU_FileAccess"
                                    control={<Checkbox size="small" name="isFlatFee" value={PREC} className={classes.checkboxSeletct1} checked={selectedPhase?.isFlatFee} onChange={(e) => { handlePhaseChange(e); }} />}
                                    label={<span className={classes.CheckBoxPhaseStyle}><b>FLAT FEE</b></span>}
                                  />
                                  <FormControlLabel
                                    id="U_CU_FileAccess"
                                    control={<Checkbox size="small" name="isContingency" value={CONT} className={classes.checkboxSeletct1} checked={selectedPhase?.isContingency} onChange={(e) => { handlePhaseChange(e); }} />}
                                    label={<span className={classes.CheckBoxPhaseStyle}><b>CONTINGENCY</b></span>}
                                  />
                                </Card  >
                              </React.Fragment >
                            ),
                          }}
                          className={classes.textBoxStyle}
                        />

                      )}
                    />
                  </div>
                </ClickAwayListener >
              </Box>
              <Box style={{ padding: '8px 17px 8px 8px' }}>
                <ManageActivity agentUserID={props?.userId} isAccess={accessTemplate} phaseList={selectedPhase} isReadOnly={localState?.readOnlyAccess} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12}>
                  <Box className={classes.userboxviewStyle}>
                    <Typography variant="h6" gutterBottom className={`${classes.root} ${classes.userStyle1}`}>{"PERMISSIONS"}</Typography>
                    <FormControl component="fieldset">
                      <FormGroup row>
                        <FormControlLabel
                          id="U_CU_paymentUpdates"
                          className={classes.permission}
                          control={<Checkbox size="small" name="paymentUpdates" />} checked={localState?.paymentUpdates} onChange={(e) => { handleChecked(e); }}
                          label={<span className={classes.inputFontSize}>{"Payment Updates"}</span>}
                        />
                        <FormControlLabel
                          id="U_CU_closeAccounts"
                          className={classes.permission}
                          control={<Checkbox size="small" name="closeAccounts" />} checked={localState?.closeAccounts} onChange={(e) => { handleChecked(e); }}
                          label={<span className={classes.inputFontSize}>{"Close Accounts"}</span>}
                        />
                        <FormControlLabel
                          id="U_CU_rollAccounts"
                          className={classes.permission}
                          control={<Checkbox size="small" name="rollAccounts" />} checked={localState?.rollAccounts} onChange={(e) => { handleChecked(e); }}
                          label={<span className={classes.inputFontSize}>{"Roll Accounts"}</span>}
                        />
                        <FormControlLabel
                          id="U_CU_clientInformation"
                          className={classes.permission}
                          control={<Checkbox size="small" name="clientInformation" />} checked={localState?.clientInformation} onChange={(e) => { handleChecked(e); }}
                          label={<span className={classes.inputFontSize}>{"Client information"}</span>}
                        />
                        <FormControlLabel
                          id="U_CU_readOnlyAccess"
                          className={classes.permission}
                          control={<Checkbox size="small" name="readOnlyAccess" />} checked={localState?.readOnlyAccess} onChange={(e) => { handleChecked(e); }}
                          label={<span className={classes.inputFontSize}>{"Read Only Access"}</span>}
                        />
                        <FormControlLabel
                          id="U_CU_agentUsersReport"
                          className={classes.permission}
                          control={<Checkbox size="small" name="agentUsersReport" />} checked={localState?.agentUsersReport} onChange={(e) => { handleChecked(e); }}
                          label={<span className={classes.inputFontSize}>{"Agent User Report"}</span>}
                        />
                        <FormControlLabel
                          id="U_CU_fTEPermission"
                          className={classes.permission}
                          disabled={!selectedPhase?.isFirstParty ? true : false}
                          control={<Checkbox size="small" name="ftePermission" />} checked={localState?.ftePermission && selectedPhase?.isFirstParty === true && selectedPhase?.isFirstParty ? localState?.ftePermission : false} onChange={(e) => { handleChecked(e); }}
                          label={<span className={classes.inputFontSize}>{"FTE Permission"}</span>}
                        />
                        <FormControlLabel
                          id="U_CU_CaseCreatedReport"
                          className={classes.permission}
                          // disabled={!selectedPhase?.isFirstParty ? true : false}
                          control={<Checkbox size="small" name="caseCreatedReport" />} checked={localState?.caseCreatedReport} onChange={(e) => { handleChecked(e); }}
                          label={<span className={classes.inputFontSize}>{"Case Created Report"}</span>}
                        />
                        <FormControlLabel
                          id="U_CU_ActivityCountReport"
                          className={classes.permission}
                          control={<Checkbox size="small" name="activityCountReport" />} checked={localState?.activityCountReport} onChange={(e) => { handleChecked(e); }}
                          label={<span className={classes.inputFontSize}>{"Activity Count Report"}</span>}
                        />
                      </FormGroup>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Box className={classes.userboxviewStyle}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={12}>
                        <Typography variant="h6" gutterBottom className={`${classes.root} ${classes.userStyle1}`}>{"CONTINGENCY NOTIFICATION ACCESS"}</Typography>
                        <FormControl component="fieldset">
                          <FormGroup row>
                            <FormControlLabel
                              id="U_CU_paymentUpdates"
                              className={classes.permission}
                              control={<Checkbox size="small" name="disputeValidation" />} checked={localState?.disputeValidation} onChange={(e) => { handleChecked(e); }}
                              label={<span className={classes.inputFontSize}>{"Dispute Validation Notification"}</span>}
                            />
                            <FormControlLabel
                              id="U_CU_closeAccounts"
                              className={classes.permission}
                              control={<Checkbox size="small" name="paymentVerification" />} checked={localState?.paymentVerification} onChange={(e) => { handleChecked(e); }}
                              label={<span className={classes.inputFontSize}>{"Payment Verification Notification"}</span>}
                            />
                            <FormControlLabel
                              id="U_CU_rollAccounts"
                              className={classes.permission}
                              control={<Checkbox size="small" name="backupDocument" />} checked={localState?.backupDocument} onChange={(e) => { handleChecked(e); }}
                              label={<span className={classes.inputFontSize}>{"Backup Document Request Notification"}</span>}
                            />
                          </FormGroup>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} >
                        <Grid container spacing={1}>
                          <Grid item xs={12} sm={6} >
                            <Typography variant="h6" gutterBottom className={`${classes.root} ${classes.userStyle1}`}>{"ASSIGN CLIENT CODE"}</Typography>
                            <ClickAwayListener onClickAway={(e) => { handleClickAwayClient(e, notificationSearchClientCode?.length > 0) }}>
                              <div style={{ display: 'flex' }}>
                                <Autocomplete
                                  multiple size="small"
                                  loading={notificationClientLoading}
                                  filterOptions={(options) => options}
                                  className={classes.clientCodeBox}
                                  autoComplete={false}
                                  id="checkboxes-tags-demo"
                                  options={notificationConfirmedClients}
                                  classes={{ paper: classes.paper2 }}
                                  disableClearable
                                  value={notificationSelectedClients}
                                  open={notificationClientCodeOpen}
                                  renderTags={() => null}
                                  onChange={(e, selected) => { handleNotificationClientSelect(e, selected); }}
                                  onOpen={() => {
                                    setNotificationClientCodeOpen(true);
                                  }}
                                  onClose={(e: any, reason: AutocompleteCloseReason) => {
                                    if (reason === "toggleInput") {
                                      setNotificationClientCodeOpen(false);
                                    }
                                  }}
                                  getOptionDisabled={(option) => option.clientCode}
                                  getOptionLabel={(option) => option.clientCode}
                                  getOptionSelected={(option, value) => (option.clientCode == value.clientCode)}
                                  renderOption={(option, { selected }) => (
                                    <span className={`${selected ? classes.GreenColor : classes.clientCodeStyle}`} >
                                      {`${option.clientCode} -- ${option.clientName}`}
                                    </span>
                                  )}
                                  ListboxProps={
                                    {
                                      style: {
                                        maxHeight: '160px'
                                      }
                                    }
                                  }
                                  renderInput={(params) => (
                                    <TextField {...params} fullWidth size="small"
                                      value={notificationSearchClientCode} variant="outlined"
                                      type='text' placeholder="Search Clients"
                                      onChange={(e) => { setNotificationSearchClientCode(e.target.value); }}
                                      onKeyDown={event => {
                                        if (event.key === 'Enter') { handleNotificationSearchClient(event); handleNotificationClientOpen(); setNotificationClientCodeOpen(true); }
                                      }}
                                      InputProps={{
                                        ...params.InputProps,
                                        inputProps: {
                                          ...params.inputProps,
                                          maxLength: 256
                                        },
                                        classes: {
                                          root: classes.inputFontSize,
                                        },
                                        endAdornment: (
                                          <React.Fragment>
                                            {notificationClientLoading ? <CircularProgress color="primary" size={15} /> : null}
                                            {params.InputProps.endAdornment}
                                            <InputAdornment position="end">
                                              <IconButton onClick={(_) => { handleNotificationClientOpen(); setNotificationClientCodeOpen(true); }}>
                                                <SearchIcon className={classes.IconSizeStyle} />
                                              </IconButton>
                                            </InputAdornment>
                                            <Box display="flex" width="100%" className={classes.CheckBoxBorder}>
                                              <Box className={classes.ClientcheckBoxStyle} width="60%">
                                                <Checkbox size="small" disabled={notificationClientLoading || !confirmedClients?.length}
                                                  checked={notificationCheckAll}
                                                  onChange={notificationCheckAllChange}
                                                  id="check-all"
                                                />
                                                <span style={{ fontSize: 14, marginLeft: '2px' }}><b>SELECT ALL</b></span>
                                              </Box>
                                              <Box className={classes.ClientcheckBoxStyle} width="40%">
                                                <Checkbox size="small" disabled={notificationClientLoading || !confirmedClients?.length}
                                                  checked={notificationDeselectAll}
                                                  onChange={(e) => { notificationDeselectAllChange(e); }}
                                                  id="check-all"
                                                />
                                                <span style={{ fontSize: 14 }}><b>DESELECT ALL</b></span>
                                              </Box >
                                            </Box >
                                          </React.Fragment >
                                        ),
                                      }
                                      }
                                      className={classes.textBoxStyle}
                                    />
                                  )}
                                />
                              </div>
                            </ClickAwayListener >
                          </Grid>
                          <Grid item xs={12} sm={6} >
                            <Box className={classes.LabelBoxStyle}>
                              <FormLabel className={classes.formLabel}> Selected Client Code : {notificationSelectedClients.length}</FormLabel>
                            </Box>
                            <Card className={classes.boxBorder}>
                              <Scrollbars autoHide
                                autoHideTimeout={1000}
                                autoHideDuration={10}
                                autoHeight
                                className={`${classes.textBoxStyle2} ${classes.scrollMargin}`}
                                autoHeightMin={120}
                                autoHeightMax={120}
                                style={{ width: '100%' }}>
                                <div className={`${classes.valueContainer}`} >
                                  {notificationSelectedClients?.length > 0 ?
                                    (
                                      notificationSelectedClients.map((v, index) => (
                                        <Chip key={index} size="small" color="secondary" label={v.clientCode} onDelete={onNotificationDelete(v.clientCode)} />
                                      ))
                                    )
                                    :
                                    <Typography variant='body2' align='center' className={classes.clientSelection}>
                                      <InfoIcon fontSize='large' />
                                      <br />
                                      <b> Please select client code</b>
                                    </Typography>
                                  }
                                </div>
                              </Scrollbars>
                            </Card>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ borderTop: '1px solid lightgray' }}>
          <Button id="IM_ok_btn" size="small" variant="contained" startIcon={<CheckCircleIcon />} onClick={() => { handleUpdateUser(); handleUpdateHistory() }}
            disabled={(!localState.firstName || !localState.lastName || !localState.emailAddress) || isDisable || !selectedClients.length || !accessTemplate
              || !(((!localState?.disputeValidation && !localState?.paymentVerification && !localState?.backupDocument) && !notificationSelectedClients?.length ? true
                : (localState?.disputeValidation || localState?.paymentVerification || localState?.backupDocument) && notificationSelectedClients?.length ? true
                  : false))
            }
            className={classes.yesButton} color="primary" autoFocus>
            Save
          </Button>
          <Button id="IM_Cancel_Btn" size="small" variant="contained" startIcon={<CancelIcon />} autoFocus className={classes.closeBtn} onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar className="snackBarStyle" open={updateUser} anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }} autoHideDuration={4000} onClose={closeSnackbar}>
        <Alert onClose={closeSnackbar} severity="success" className="alertStyle">
          User {localState.firstName} {localState.lastName} is Updated Successfully!
        </Alert>
      </Snackbar>
    </React.Fragment >
  )
}

export default EditAgentUser