import {
  Backdrop, Box, Button, createStyles, Grid, IconButton, makeStyles, Paper, Table, TableBody, Container,
  TableCell, TableContainer, TableHead, TableRow, TextField, Theme, withStyles, Dialog, DialogActions, DialogContent, FormLabel
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import QueuePlayNextIcon from '@mui/icons-material/QueuePlayNext';
import * as _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { useForm } from 'react-hook-form';
import { Link, useHistory, useLocation, withRouter } from 'react-router-dom';
import PuffLoader from "react-spinners/PuffLoader";
import Footer from '../../../components/Footer/Footer';
import { IValidationError } from '../../../models/common/IValidationError';
import { IPlacementAccount } from '../../../models/Placements/IPlacementAccount';
import { ITemplateField } from '../../../models/Placements/ITemplateField';
import { GlobalStateAction, useGlobalState } from '../../../store/GlobalStore';
import { usePost, useFetch } from '../../../utils/apiHelper';
import { DialogTitleDelete } from '../../../components/GlobalStyles/DialogStyle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { IUwfDetails } from '../../../models/Placements/IUwfDetails';
import { Transition } from '../../../components/GlobalStyles/DialogBoxTransition';
import * as constant from '../../../constants/Constant';
import Papa from 'papaparse';
import {
  IAccountNumberList, IClientCodeDetail, IClientCodeExempted, IItemizationDateAndState, IItemizationResults, IPhonenumber, ISpecialNameList,
  IStateAndZipCodeList, IStateResult, IZipCodeResult
} from '../../../models/Placements/IPlacementRejectRules';

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: "100vw",
    maxHeight: 'calc( 100vh - 130px)',
    minHeight: 'calc( 100vh - 130px)',
    alignItems: "center",
    border: '2px solid gray',
    borderRadius: 10,
    backgroundColor: 'white',
    boxShadow: '0px 1px 4px 1px rgba(103, 128, 159, 1)',
    padding: '5px'
  },
  buttons: {
    borderRadius: 20,
    fontSize: 12,
    fontWeight: 'bold',
    background: "#007FFF",
    color: "white",
    float: 'right'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 180,
    textAlign: 'center',
    maxHeight: '50px',
    border: '1px solid #364F6B',
    borderRadius: '5px',
    backgroundColor: 'white',
    paddingLeft: '7px',
    color: 'black',
    '& .MuiInputBase-input': {
      fontSize: 13
    },
    '& .MuiInputBase-inputMarginDense': {
      padding: '6px',
    }
  },
  moneyField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 280,
    fontSize: 13,
    transition: '0.3s',
    borderRadius: '4px',
    textAlign: 'right',
    paddingRight: '10px',
    height: '30px',
    border: '1px solid #364F6B'
  },
  textField1: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    fontSize: 12,
    textIndent: 5,
    width: 200,
    textAlign: 'center',
    maxHeight: '50px',
    border: '1px solid #364F6B',
    borderRadius: '5px',
    backgroundColor: 'white',
    paddingLeft: '7px',
    outline: 'none',
    whiteSpace: "nowrap",
    '& .MuiInputBase-input': {
      fontSize: 13,
    },
    '& .MuiInputBase-inputMarginDense': {
      paddingTop: '6px',
    }
  },
  error: {
    color: 'red',
  },
  inputField: {
    height: '30px',
    fontSize: 14,
    borderRadius: '4px',
    transition: '0.3s',
    marginRight: "20px",
    width: "70px",
  },
  div: {
    flexGrow: 1,
    margin: "0px 10px",
    paddingTop: '4px',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  errorColor: {
    color: 'red',
    fontSize: '18px'
  },
  title: {
    alignContent: "center",
    textAlign: 'center',
    justifyItems: 'center',
    marginTop: '0.2cm',
    float: 'right',
    fontSize: 18,
  },
  buttonbox: {
    float: 'right',
    marginRight: 20,
    padding: 0,
    backgroundColor: 'transparent'
  },
  tableHead: {
    alignContent: "left",
    textAlign: 'left',
    height: '2vh',
    background: "#364F6B",
    boxShadow: '2px 1px 8px 1px rgba(103, 128, 159, 1)',
    padding: '0px 0px 0px 0px',
  },
  tableHead1: {
    height: '4vh',
    padding: '3px',
  },
  tableRow: {
    border: '1px solid #364F6B',
    alignContent: "center",
    justifyItems: 'center',
    padding: '0px',
  },
  tableRow1: {
    color: "white",
    textAlign: 'left',
    padding: '0px',
    textIndent: '10px',
    fontSize: 13
  },
  tablebody: {
    maxHeight: 'calc( 100vh - 260px)',
    display: 'flex',
    marginTop: '5px',
    backgroundColor: 'whitesmoke',
    width: '99%',
    marginLeft: '10px',
    marginBottom: 5
  },
  textWrap: {
    display: "inline-block",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "100%",
    fontSize: 13,
    overflow: 'hidden',
    marginTop: '8px',
    '&:hover': {
      overflow: 'visible',
      transition: '0.10s',
    }
  },
  deleteIconStyle: {
    marginLeft: "10px",
  },
  titleheader: {
    fontWeight: 'bold',
    marginTop: '5px',
    color: 'white',
    fontSize: 18
  },
  yesButton: {
    background: "red",
    color: 'white',
    borderRadius: 20,
    right: '15px',
    fontSize: 12,
    '&:hover': {
      background: "red",
    }
  },
  MessageStyle: {
    color: "black",
    fontSize: 17
  },
  error_message: {
    color: "#02580e",
    fontSize: 13
  },
  error_message1: {
    color: "red",
    fontSize: 13
  },
  uwfStyle: {
    color: 'green',
    fontSize: 14,
    padding: '5px',
    fontWeight: 'bold'
  },
  InfoStyle: {
    fontSize: 14
  },
  uwfBoxStyle: {
    padding: '0px',
    marginTop: '2px',
    marginLeft: '5px',
    width: 610,
    borderRadius: '10px',
    position: 'absolute',
    border: '2px solid gray',
    backgroundColor: 'transparent',
  },
  messagegrid: {
    minHeight: '65px',
    maxHeight: '65px',
    alignItems: "center",
    justifyContent: "center",
    marginTop: '5px'
  },
  LabelStyle: {
    float: 'left',
    marginTop: '6px'
  }
}))

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: '#364F6B',
      color: theme.palette.common.white,
      padding: '0px'
    },
    body: {
      fontSize: 12,
      width: 600
    },
  }),
)(TableCell);

function Fieldnames(props) {
  let history = useHistory();
  const classes = useStyles();
  let location = useLocation();
  const { state, dispatch } = useGlobalState();
  const { handleSubmit } = useForm();

  const [placementAccountList, setPlacementAccountList] = useState<IPlacementAccount[]>(props.location?.state?.placementAccountList ? props.location?.state?.placementAccountList : []);
  const [selectedHeaderList, setSelectedHeaderList] = useState<string[]>(props.location?.state?.selectedHeaderList ? props.location?.state?.selectedHeaderList : []);
  const [tablerows, setTableRows] = useState<number[]>(props.location?.state?.tablerows ? props.location?.state?.tablerows : [1]);
  const [getStates, setGetStates] = useState(props.location?.state?.stateCodes ? props.location?.state?.stateCodes : []);
  const [mappedRequireFields, setMappedRequireFields] = useState<ITemplateField[]>([]);
  const [validationErrors, setValidationErrors] = useState<IValidationError[]>([]);
  const [errorMessage, setErrorMessage] = useState<IValidationError[]>([]);
  const [requireFields, setRequireFields] = useState<ITemplateField[]>([]);
  const [templateList, setTemplateList] = useState<ITemplateField[]>([]);
  const [GetUwfCltData, setGetUwfCltData] = useState<IUwfDetails[]>();

  const theme = useTheme();
  const [alertOpen, setAlertOpen] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  let phaseNumber: number = props.location.state.phaseNumber;
  let clientCode: string = props.location.state.clientCode;
  let templateId: number = props.location.state.templateId;
  let multiCode: boolean = props.location.state.multiCode;

  const [numberOfRows, setNumberOfRows] = useState<any>(1);
  const [savingEnable, setSavingEnable] = useState(false);
  const [errorStyle, setErrorStyle] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  let zipVal = /^(?:$|(\d{5}[-\s]?(?:\d{4})?$))/;

  const [csvUrl, setCSVUrl] = useState('');
  let zipCodeResult: IZipCodeResult[] = props.location?.state?.zipCodeResult ? props.location?.state?.zipCodeResult : [];
  let stateCodeResult: IStateResult[] = props.location?.state?.stateCodeResult ? props.location?.state?.stateCodeResult : [];
  let phoneNumberResult: IPhonenumber[] = props.location?.state?.phoneNumberResult ? props.location?.state?.phoneNumberResult : [];
  let accountNumberList: IAccountNumberList[] = props.location?.state?.accountNumberList ? props.location?.state?.accountNumberList : [];
  let isClientCodeExempt: IClientCodeExempted = props.location?.state?.isClientCodeExempt ? props.location?.state?.isClientCodeExempt : [];
  let accountNumberResult: IAccountNumberList[] = props.location?.state?.accountNumberResult ? props.location?.state?.accountNumberResult : [];
  let rejectNvNmStatusCode: IClientCodeDetail[] = props.location?.state?.rejectNvNmStatusCode ? props.location?.state?.rejectNvNmStatusCode : [];
  let specialNameListResult: ISpecialNameList[] = props.location?.state?.specialNameListResult ? props.location?.state?.specialNameListResult : [];
  let itemizationDateList: IItemizationDateAndState[] = props.location?.state?.itemizationDateList ? props.location?.state?.itemizationDateList : [];
  let delinquencyDateList: IItemizationDateAndState[] = props.location?.state?.delinquencyDateList ? props.location?.state?.delinquencyDateList : [];
  let stateAndZipCodeResult: IStateAndZipCodeList[] = props.location?.state?.stateAndZipCodeResult ? props.location?.state?.stateAndZipCodeResult : [];
  let rejectDupicateAcResult: IAccountNumberList[] = props.location?.state?.rejectDupicateAcResult ? props.location?.state?.rejectDupicateAcResult : [];
  let existingAccountNumberList: IAccountNumberList[] = props.location?.state?.existingAccountNumberList ? props.location?.state?.existingAccountNumberList : [];
  let itemizationDateStatueDate: IItemizationResults[] = props.location?.state?.itemizationDateStatueDate ? props.location?.state?.itemizationDateStatueDate : [];
  let delinquencyDateStatueDate: IItemizationResults[] = props.location?.state?.delinquencyDateStatueDate ? props.location?.state?.delinquencyDateStatueDate : [];

  let PlacementRejectedAccountsList = [];
  let placementDuplicateAccountsList = [];

  const [progressBar, setShowProgressBar] = useState(false);

  useEffect(() => {
    if (!requireFields.length) {
      setSavingEnable(true);
    } else {
      setSavingEnable(false);
    }
    let errorlist = _.cloneDeep(validationErrors);
    errorlist = errorlist.filter((x) => x.errorMessage !== null);
    setErrorMessage(errorlist);
  }, [requireFields, validationErrors, isSubmit]);

  const getPhaseString = (phaseNum: number) => {
    switch (+phaseNum) {
      case 1:
        return "1STP";
      case 2:
        return "PREC";
      case 3:
        return "CONT";
      default:
        return "";
    }
  }

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  useEffect(() => {
    if (placementAccountList.length == 0) {
      let list: IPlacementAccount[] = [];
      let placementAccount: IPlacementAccount = getEmptyPlacementAccount();
      list.push(placementAccount);
      setPlacementAccountList(list);
      setIsSubmit(true);
    }
  }, [placementAccountList])

  useEffect(() => {
    (async () => {
      if (templateList.length <= 0) {
        try {
          dispatch({ type: GlobalStateAction.Busy })
          await useFetch<ITemplateField[]>(`Placements/TemplateExclude?clientCode=${clientCode === "-2" ? null : clientCode}`).then((template) => {
            setTemplateList(template.data);
            setRequireFields(template.data.filter(x => x.is_nullable == false));
            setMappedRequireFields(template.data.filter(x => x.is_nullable == false));
            setIsSubmit(true);
          });
        }
        catch (ex) {
          dispatch({ type: GlobalStateAction.Idle });
          dispatch({ type: GlobalStateAction.Error, error: ex });
        }
        finally {
          dispatch({ type: GlobalStateAction.Idle });
        }
      }
    })()
  }, [templateList])

  useEffect(() => {
    let mappedHeaderList = _.cloneDeep(selectedHeaderList);
    let requiredHeaderList = _.cloneDeep(mappedRequireFields);
    mappedHeaderList.map(header => {
      requiredHeaderList = requiredHeaderList.filter(x => x.field_name !== header);
    });
    if (selectedHeaderList.includes("first_name") && selectedHeaderList.includes("last_name")) {
      requiredHeaderList = requiredHeaderList.filter(x => x.field_name !== "full_name");
    }
    if (selectedHeaderList.includes("full_name")) {
      requiredHeaderList = requiredHeaderList.filter(x => x.field_name !== "first_name" && x.field_name !== "last_name");
    }
    if (selectedHeaderList.includes("Payments_applied_since_itemization_date") && selectedHeaderList.includes("Credits_applied_since_itemization_date")) {
      requiredHeaderList = requiredHeaderList.filter(x => x.field_name !== "Payments_Credits_applied_since_itemization_date");
    }
    if (selectedHeaderList.includes("Payments_Credits_applied_since_itemization_date")) {
      requiredHeaderList = requiredHeaderList.filter(x => x.field_name !== "Payments_applied_since_itemization_date" && x.field_name !== "Credits_applied_since_itemization_date");
    }
    setRequireFields(requiredHeaderList);
  }, [isSubmit, selectedHeaderList]);

  useEffect(() => {
    (async () => {
      if (selectedHeaderList?.includes("first_name") || selectedHeaderList?.includes("last_name")) {
        let currentValidationerrorList = _.cloneDeep(validationErrors);

        if (selectedHeaderList?.includes("first_name") && !selectedHeaderList?.includes("last_name") && !selectedHeaderList?.includes("full_name")) {
          let FirstNameValidation: IValidationError = {
            errorNumber: 1,
            field: "first_name",
            errorMessage: "First Name is mapped but Last Name or Business Name is missing (*)"
          };
          if (currentValidationerrorList.findIndex(x => x.errorNumber == 1) <= -1) {
            currentValidationerrorList.push(FirstNameValidation);
            setValidationErrors(currentValidationerrorList);
          }
        } else {
          if (currentValidationerrorList.findIndex(x => x.errorNumber == 1) > -1) {
            let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 1);
            setValidationErrors(errorList);
          }
        }

        if (selectedHeaderList?.includes("last_name") && !selectedHeaderList?.includes("first_name") && !selectedHeaderList?.includes("full_name")) {
          let LastNameValidation: IValidationError = {
            errorNumber: 2,
            field: "last_name",
            errorMessage: "Last Name is mapped but First Name or Business Name is missing (*)"
          };
          if (currentValidationerrorList.findIndex(x => x.errorNumber == 2) <= -1) {
            currentValidationerrorList.push(LastNameValidation);
            setValidationErrors(currentValidationerrorList);
          }
        } else {
          if (currentValidationerrorList.findIndex(x => x.errorNumber == 2) > -1) {
            let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 2);
            setValidationErrors(errorList);
          }
        }

        if (!selectedHeaderList?.includes("first_name") && !selectedHeaderList?.includes("last_name") && !selectedHeaderList?.includes("full_name")) {
          let FullNameValidation: IValidationError = {
            errorNumber: 3,
            field: "full_name",
            errorMessage: "Business Name, First Name, or Last Name is required (*)"
          };
          if (currentValidationerrorList.findIndex(x => x.errorNumber == 3) <= -1) {
            currentValidationerrorList.push(FullNameValidation);
            setValidationErrors(currentValidationerrorList);
          }
        } else {
          if (currentValidationerrorList.findIndex(x => x.errorNumber == 3) > -1) {
            let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 3);
            setValidationErrors(errorList);
          }
        }
      }

      if (selectedHeaderList.includes("account_no")) {
        let currentValidationerrorList = _.cloneDeep(validationErrors);
        let validationList = _.cloneDeep(placementAccountList);
        validationList.map((x, index) => {
          if ((!constant.accountNumberRegex.exec(x?.account_no)) || (mappedRequireFields.find(x => x.field_name === "account_no") && x?.account_no === "" || x?.account_no === null)) {
            let accountValidation: IValidationError = {
              errorNumber: 1, errorMessage: (currentValidationerrorList.findIndex(x => x.errorNumber == 1) <= -1 ? "Account number does not  support percent sign, underscore, and apostrophe and maximum characters should be 50" : null),
              field: "account_no", index: index
            };
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 1 && x.index == index) <= -1) {
              currentValidationerrorList.push(accountValidation);
              setValidationErrors(currentValidationerrorList);
            }
          } else {
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 1 && x.index == index) > -1) {
              let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 1 && x.index !== index);
              setValidationErrors(errorList);
            };
          };
        });
      }

      if (selectedHeaderList.includes("city")) {
        let cityVal = new RegExp(/^[a-zA-Z ]*$/);
        let currentValidationerrorList = _.cloneDeep(validationErrors);
        let validationList = _.cloneDeep(placementAccountList);
        validationList.map((x, index) => {
          if ((!cityVal.exec(x?.city)) || (mappedRequireFields.find(x => x.field_name === "city") && x?.city === "" || x?.city === null)) {
            let cityValidation: IValidationError = {
              errorNumber: 5, errorMessage: (currentValidationerrorList.findIndex(x => x.errorNumber == 5) <= -1 ? "City must be Characters only" : null),
              field: "city", index: index
            };
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 5 && x.index == index) <= -1) {
              currentValidationerrorList.push(cityValidation);
              setValidationErrors(currentValidationerrorList);
            };
          } else {
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 5 && x.index == index) > -1) {
              let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 5 && x.index !== index);
              setValidationErrors(errorList);
            };
          };
        });
      }

      if (selectedHeaderList.includes("Codebtor_City")) {
        let cityVal = new RegExp(/^[a-zA-Z ]*$/);
        let currentValidationerrorList = _.cloneDeep(validationErrors);
        let validationList = _.cloneDeep(placementAccountList);
        validationList.map((x, index) => {
          if ((!cityVal.exec(x?.Codebtor_City)) || (mappedRequireFields.find(x => x.field_name === "Codebtor_City") && x?.Codebtor_City === "" || x?.Codebtor_City === null)) {
            let cityValidation: IValidationError = {
              errorNumber: 31, errorMessage: (currentValidationerrorList.findIndex(x => x.errorNumber == 31) <= -1 ? "Codebtor City must be Characters only" : null),
              field: "Codebtor_City", index: index
            };
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 31 && x.index == index) <= -1) {
              currentValidationerrorList.push(cityValidation);
              setValidationErrors(currentValidationerrorList);
            };
          } else {
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 31 && x.index == index) > -1) {
              let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 31 && x.index !== index);
              setValidationErrors(errorList);
            };
          };
        });
      }

      if (selectedHeaderList.includes("fees")) {
        let currentValidationerrorList = _.cloneDeep(validationErrors);
        let validationList = _.cloneDeep(placementAccountList);
        validationList.map((x, index) => {
          if ((mappedRequireFields.find(x => x.field_name === "fees") && Number(x?.fees) <= 0 || x?.fees === null)) {
            let balanceValidation: IValidationError = {
              errorNumber: 6, errorMessage: (currentValidationerrorList.findIndex(x => x.errorNumber == 6) <= -1 ? "Fee balance is required & must be greater than zero" : null),
              field: "fees", index: index
            };
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 6 && x.index == index) <= -1) {
              currentValidationerrorList.push(balanceValidation);
              setValidationErrors(currentValidationerrorList);
            }
          } else {
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 6 && x.index == index) > -1) {
              let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 6 && x.index !== index);
              setValidationErrors(errorList);
            };
          };
        });
      }

      if (selectedHeaderList.includes("state")) {
        let currentValidationerrorList = _.cloneDeep(validationErrors);
        let validationList = _.cloneDeep(placementAccountList);
        validationList.map((x, index) => {
          if (!getStates?.includes(x?.state?.toUpperCase())) {
            let stateValidation: IValidationError = {
              errorNumber: 7, errorMessage: (currentValidationerrorList.findIndex(x => x.errorNumber == 7) <= -1 ? "State code must be valid and 2 digit only" : null),
              field: "state", index: index
            };
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 7 && x.index == index) <= -1) {
              currentValidationerrorList.push(stateValidation);
              setValidationErrors(currentValidationerrorList);
            };
          } else {
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 7 && x.index == index) > -1) {
              let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 7 && x.index !== index);
              setValidationErrors(errorList);
            };
          };
        });
      }

      if (selectedHeaderList.includes("secondary_state")) {
        let currentValidationerrorList = _.cloneDeep(validationErrors);
        let validationList = _.cloneDeep(placementAccountList);
        validationList.map((x, index) => {
          if (!getStates.includes(x?.secondary_state?.toUpperCase()) && (mappedRequireFields.find(x => x.field_name === "secondary_state"))) {
            let secondaryStateValidation: IValidationError = {
              errorNumber: 8, errorMessage: (currentValidationerrorList.findIndex(x => x.errorNumber == 8) <= -1 ? "Secondary State code must be valid and 2 digit only" : null),
              field: "secondary_state", index: index
            };
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 8 && x.index == index) <= -1) {
              currentValidationerrorList.push(secondaryStateValidation);
              setValidationErrors(currentValidationerrorList);
            };
          } else {
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 8 && x.index == index) > -1) {
              let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 8 && x.index !== index);
              setValidationErrors(errorList);
            };
          };
        });
      }

      if (selectedHeaderList.includes("Codebtor_State")) {
        let currentValidationerrorList = _.cloneDeep(validationErrors);
        let validationList = _.cloneDeep(placementAccountList);
        validationList.map((x, index) => {
          if (!getStates.includes(x?.Codebtor_State?.toUpperCase()) && (mappedRequireFields.find(x => x.field_name === "Codebtor_State"))) {
            let coDebtorStateValidation: IValidationError = {
              errorNumber: 9, errorMessage: (currentValidationerrorList.findIndex(x => x.errorNumber == 9) <= -1 ? "CoDebtor State code must be valid and 2 digit only" : null),
              field: "Codebtor_State", index: index
            };
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 9 && x.index == index) <= -1) {
              currentValidationerrorList.push(coDebtorStateValidation);
              setValidationErrors(currentValidationerrorList);
            };
          } else {
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 9 && x.index == index) > -1) {
              let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 9 && x.index !== index);
              setValidationErrors(errorList);
            };
          };
        });
      }

      if (selectedHeaderList.includes("zip")) {
        let currentValidationerrorList = _.cloneDeep(validationErrors);
        let validationList = _.cloneDeep(placementAccountList);
        validationList.map((x, index) => {
          if ((!zipVal.exec(x?.zip)) || (mappedRequireFields.find(x => x.field_name === "zip") && (x?.zip === null || x?.zip === ""))) {
            let zipValidation: IValidationError = {
              errorNumber: 10, errorMessage: (currentValidationerrorList.findIndex(x => x.errorNumber == 10) <= -1 ? "Zip code must be either 5 or 9 digits only" : null),
              field: "zip", index: index
            };
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 10 && x.index == index) <= -1) {
              currentValidationerrorList.push(zipValidation);
              setValidationErrors(currentValidationerrorList);
            };
          } else {
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 10 && x.index == index) > -1) {
              let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 10 && x.index !== index);
              setValidationErrors(errorList);
            };
          };
        });
      }

      if (selectedHeaderList.includes("secondary_zip")) {
        let currentValidationerrorList = _.cloneDeep(validationErrors);
        let validationList = _.cloneDeep(placementAccountList);
        validationList.map((x, index) => {
          if ((!zipVal.exec(x?.secondary_zip)) || (mappedRequireFields.find(x => x.field_name === "secondary_zip") && (x?.secondary_zip === null || x?.secondary_zip === ""))) {
            let secondaryZipValidation: IValidationError = {
              errorNumber: 11, errorMessage: (currentValidationerrorList.findIndex(x => x.errorNumber == 11) <= -1 ? "Secondary Zip code must be either 5 or 9 digits only" : null),
              field: "secondary_zip", index: index
            };
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 11 && x.index == index) <= -1) {
              currentValidationerrorList.push(secondaryZipValidation);
              setValidationErrors(currentValidationerrorList);
            };
          } else {
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 11 && x.index == index) > -1) {
              let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 11 && x.index !== index);
              setValidationErrors(errorList);
            }
          }
        });
      }

      if (selectedHeaderList.includes("Codebtor_Zip")) {
        let currentValidationerrorList = _.cloneDeep(validationErrors);
        let validationList = _.cloneDeep(placementAccountList);
        validationList.map((x, index) => {
          if ((!zipVal.exec(x?.Codebtor_Zip)) || (mappedRequireFields.find(x => x.field_name === "codebtor_Zip") && (x?.Codebtor_Zip === null || x?.Codebtor_Zip === ""))) {
            let secondaryZipValidation: IValidationError = {
              errorNumber: 12, errorMessage: (currentValidationerrorList.findIndex(x => x.errorNumber == 12) <= -1 ? "CoDebtor Zip code must be either 5 or 9 digits only" : null),
              field: "Codebtor_Zip", index: index
            };
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 12 && x.index == index) <= -1) {
              currentValidationerrorList.push(secondaryZipValidation);
              setValidationErrors(currentValidationerrorList);
            };
          } else {
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 12 && x.index == index) > -1) {
              let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 12 && x.index !== index);
              setValidationErrors(errorList);
            }
          }
        });
      }

      if (selectedHeaderList.includes("phone_no")) {
        let phoneVal = new RegExp(/(^\d{10,10}$)|(^[(]?\d{3,3}[-|\s|)]\d{3,3}[-|\s]\d{4,4}$|^$)/);
        let currentValidationerrorList = _.cloneDeep(validationErrors);
        let validationList = _.cloneDeep(placementAccountList);
        validationList.map((x, index) => {
          if ((!phoneVal.exec(x?.phone_no)) || (mappedRequireFields.find(x => x.field_name === "phone_no") && x?.phone_no === "")) {
            let phoneValidation: IValidationError = {
              errorNumber: 13, errorMessage: (currentValidationerrorList.findIndex(x => x.errorNumber == 13) <= -1 ? "Phone number must be number and 10 digits only" : null),
              field: "phone_no", index: index
            };
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 13 && x.index == index) <= -1) {
              currentValidationerrorList.push(phoneValidation);
              setValidationErrors(currentValidationerrorList);
            };
          } else {
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 13 && x.index == index) > -1) {
              let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 13 && x.index !== index);
              setValidationErrors(errorList);
            }
          }
        });
      }

      if (selectedHeaderList.includes("secondary_phone_no")) {
        let phoneVal = new RegExp(/(^\d{10,10}$)|(^[(]?\d{3,3}[-|\s|)]\d{3,3}[-|\s]\d{4,4}$|^$)/);
        let currentValidationerrorList = _.cloneDeep(validationErrors);
        let validationList = _.cloneDeep(placementAccountList);
        validationList.map((x, index) => {
          if ((!phoneVal.exec(x?.secondary_phone_no)) || (mappedRequireFields.find(x => x.field_name === "secondary_phone_no") && (x?.secondary_phone_no === null || x?.secondary_phone_no === ""))) {
            let secondaryPhoneValidation: IValidationError = {
              errorNumber: 14, errorMessage: (currentValidationerrorList.findIndex(x => x.errorNumber == 14) <= -1 ? "Secondary Phone number must be number and 10 digits only" : null),
              field: "secondary_phone_no", index: index
            };
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 14 && x.index == index) <= -1) {
              currentValidationerrorList.push(secondaryPhoneValidation);
              setValidationErrors(currentValidationerrorList);
            };
          } else {
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 14 && x.index == index) > -1) {
              let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 14 && x.index !== index);
              setValidationErrors(errorList);
            }
          }
        });
      }

      if (selectedHeaderList.includes("CoDebtor_Phone1")) {
        let phoneVal = new RegExp(/(^\d{10,10}$)|(^[(]?\d{3,3}[-|\s|)]\d{3,3}[-|\s]\d{4,4}$|^$)/);
        let currentValidationerrorList = _.cloneDeep(validationErrors);
        let validationList = _.cloneDeep(placementAccountList);
        validationList.map((x, index) => {
          if ((!phoneVal.exec(x?.CoDebtor_Phone1)) || (mappedRequireFields.find(x => x.field_name === "CoDebtor_Phone1") && x?.CoDebtor_Phone1 === "")) {
            let phoneValidation: IValidationError = {
              errorNumber: 15, errorMessage: (currentValidationerrorList.findIndex(x => x.errorNumber == 15) <= -1 ? "Phone number must be number and 10 digits only" : null),
              field: "CoDebtor_Phone1", index: index
            };
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 15 && x.index == index) <= -1) {
              currentValidationerrorList.push(phoneValidation);
              setValidationErrors(currentValidationerrorList);
            };
          } else {
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 15 && x.index == index) > -1) {
              let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 15 && x.index !== index);
              setValidationErrors(errorList);
            }
          }
        });
      }

      if (selectedHeaderList.includes("CoDebtor_Phone2")) {
        let phoneVal = new RegExp(/(^\d{10,10}$)|(^[(]?\d{3,3}[-|\s|)]\d{3,3}[-|\s]\d{4,4}$|^$)/);
        let currentValidationerrorList = _.cloneDeep(validationErrors);
        let validationList = _.cloneDeep(placementAccountList);
        validationList.map((x, index) => {
          if ((!phoneVal.exec(x?.CoDebtor_Phone2)) || (mappedRequireFields.find(x => x.field_name === "CoDebtor_Phone2") && x?.CoDebtor_Phone2 === "")) {
            let coDebtorPhoneValidation: IValidationError = {
              errorNumber: 16, errorMessage: (currentValidationerrorList.findIndex(x => x.errorNumber == 16) <= -1 ? "CoDebtor Phone number2 must be number and 10 digits only" : null),
              field: "CoDebtor_Phone2", index: index
            };
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 16 && x.index == index) <= -1) {
              currentValidationerrorList.push(coDebtorPhoneValidation);
              setValidationErrors(currentValidationerrorList);
            };
          } else {
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 16 && x.index == index) > -1) {
              let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 16 && x.index !== index);
              setValidationErrors(errorList);
            }
          }
        });
      }

      if (selectedHeaderList.includes("total_balance")) {
        let currentValidationerrorList = _.cloneDeep(validationErrors);
        let validationList = _.cloneDeep(placementAccountList);
        validationList.map((x, index) => {
          if ((mappedRequireFields.find(x => x.field_name === "total_balance") && x?.total_balance === null)) {
            let balanceValidation: IValidationError = {
              errorNumber: 17, errorMessage: (currentValidationerrorList.findIndex(x => x.errorNumber == 17) <= -1 ? "Total balance is required & must be greater than zero" : null),
              field: "total_balance", index: index
            };
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 17 && x.index == index) <= -1) {
              currentValidationerrorList.push(balanceValidation);
              setValidationErrors(currentValidationerrorList);
            };
          } else {
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 17 && x.index == index) > -1) {
              let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 17 && x.index !== index);
              setValidationErrors(errorList);
            };
          };
        });
      }

      if (selectedHeaderList.includes("Balance_owed_on_itemization_date")) {
        let currentValidationerrorList = _.cloneDeep(validationErrors);
        let validationList = _.cloneDeep(placementAccountList);
        validationList.map((x, index) => {
          if ((mappedRequireFields.find(x => x.field_name === "Balance_owed_on_itemization_date") && x?.Balance_owed_on_itemization_date === null)) {
            let balanceDateValidation: IValidationError = {
              errorNumber: 20, errorMessage: (currentValidationerrorList.findIndex(x => x.errorNumber == 20) <= -1 ? 'Balance owed on itemization date is required & can not be empty' : null),
              field: "Balance_owed_on_itemization_date", index: index
            };
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 20 && x.index == index) <= -1) {
              currentValidationerrorList.push(balanceDateValidation);
              setValidationErrors(currentValidationerrorList);
            };
          }
          else {
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 20 && x.index == index) > -1) {
              let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 20 && x.index !== index);
              setValidationErrors(errorList);
            };
          };
        });
      }

      if (selectedHeaderList.includes("Fees_added_since_itemization_date")) {
        let currentValidationerrorList = _.cloneDeep(validationErrors);
        let validationList = _.cloneDeep(placementAccountList);
        validationList.map((x, index) => {
          if ((mappedRequireFields.find(x => x.field_name === "Fees_added_since_itemization_date") && x?.Fees_added_since_itemization_date === null)) {
            let feesDateValidation: IValidationError = {
              errorNumber: 21, errorMessage: (currentValidationerrorList.findIndex(x => x.errorNumber == 21) <= -1 ? 'Fees added since itemization is required & can not be empty' : null),
              field: "Fees_added_since_itemization_date", index: index
            };
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 21 && x.index == index) <= -1) {
              currentValidationerrorList.push(feesDateValidation);
              setValidationErrors(currentValidationerrorList);
            };
          }
          else {
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 21 && x.index == index) > -1) {
              let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 21 && x.index !== index);
              setValidationErrors(errorList);
            };
          };
        });
      }

      if (selectedHeaderList.includes("Interest_added_since_itemization_date")) {
        let currentValidationerrorList = _.cloneDeep(validationErrors);
        let validationList = _.cloneDeep(placementAccountList);
        validationList.map((x, index) => {
          if ((mappedRequireFields.find(x => x.field_name === "Interest_added_since_itemization_date") && x?.Interest_added_since_itemization_date === null)) {
            let interestDateValidation: IValidationError = {
              errorNumber: 22, errorMessage: (currentValidationerrorList.findIndex(x => x.errorNumber == 22) <= -1 ? 'Interest added since Itemization Date is required & can not be empty' : null),
              field: "Interest_added_since_itemization_date", index: index
            };
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 22 && x.index == index) <= -1) {
              currentValidationerrorList.push(interestDateValidation);
              setValidationErrors(currentValidationerrorList);
            };
          }
          else {
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 22 && x.index == index) > -1) {
              let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 22 && x.index !== index);
              setValidationErrors(errorList);
            };
          };
        });
      }
      if (selectedHeaderList.includes("Payments_applied_since_itemization_date") || selectedHeaderList.includes("Credits_applied_since_itemization_date")) {
        let currentValidationerrorList = _.cloneDeep(validationErrors);
        let validationList = _.cloneDeep(placementAccountList);
        validationList.map((x, index) => {
          if ((x?.Payments_Credits_applied_since_itemization_date === null || x?.Payments_Credits_applied_since_itemization_date === '0.00')
            && (x?.Payments_applied_since_itemization_date === null && Number(x?.Credits_applied_since_itemization_date) > 0)
            || (Number(x?.Payments_applied_since_itemization_date) > 0 && Number(x?.Credits_applied_since_itemization_date) === 0 && Number(x?.Payments_Credits_applied_since_itemization_date) > 0)
          ) {
            let paymentDateValidation: IValidationError = {
              errorNumber: 18, errorMessage: (currentValidationerrorList.findIndex(x => x.errorNumber == 19) <= -1 && currentValidationerrorList.findIndex(x => x.errorNumber == 23) <= -1 ? 'Payments Applied Since Itemization Date, Credits Applied Since Itemization Date (OR) Payments Credits Applied Since Itemization Date is required and must be Number' : null),
              field: "Payments_applied_since_itemization_date", index: index
            };
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 18 && x.index == index) <= -1) {
              currentValidationerrorList.push(paymentDateValidation);
              setValidationErrors(currentValidationerrorList);
            };
          }
          else {
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 18 && x.index == index) > -1) {
              let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 18 && x.index !== index);
              setValidationErrors(errorList);
            };
          };
        });
      }

      if (selectedHeaderList.includes("Credits_applied_since_itemization_date") || (selectedHeaderList.includes("Payments_applied_since_itemization_date"))) {
        let currentValidationerrorList = _.cloneDeep(validationErrors);
        let validationList = _.cloneDeep(placementAccountList);
        validationList.map((x, index) => {
          if ((x?.Payments_Credits_applied_since_itemization_date === null || x?.Payments_Credits_applied_since_itemization_date === '0.00')
            && (x?.Credits_applied_since_itemization_date === null && Number(x?.Payments_applied_since_itemization_date) > 0)
            || (Number(x?.Credits_applied_since_itemization_date) > 0 && Number(x?.Payments_applied_since_itemization_date) === 0 && Number(x?.Payments_Credits_applied_since_itemization_date) > 0)
          ) {
            let creditsDateValidation: IValidationError = {
              errorNumber: 19, errorMessage: (currentValidationerrorList.findIndex(x => x.errorNumber == 18) <= -1 && currentValidationerrorList.findIndex(x => x.errorNumber == 23) <= -1 ? 'Payments Applied Since Itemization Date, Credits Applied Since Itemization Date (OR) Payments Credits Applied Since Itemization Date is required and must be Number' : null),
              field: "Credits_applied_since_itemization_date", index: index
            };
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 19 && x.index == index) <= -1) {
              currentValidationerrorList.push(creditsDateValidation);
              setValidationErrors(currentValidationerrorList);
            };
          }
          else {
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 19 && x.index == index) > -1) {
              let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 19 && x.index !== index);
              setValidationErrors(errorList);
            };
          };
        });
      }

      if (selectedHeaderList.includes("Payments_Credits_applied_since_itemization_date") || selectedHeaderList.includes("Credits_applied_since_itemization_date") || selectedHeaderList.includes("Payments_applied_since_itemization_date")) {
        let currentValidationerrorList = _.cloneDeep(validationErrors);
        let validationList = _.cloneDeep(placementAccountList);
        validationList.map((x, index) => {
          if (
            (x?.Payments_Credits_applied_since_itemization_date == null && x?.Payments_applied_since_itemization_date == null && x?.Payments_Credits_applied_since_itemization_date === null)) {
            let interestDateValidation: IValidationError = {
              errorNumber: 23, errorMessage: (currentValidationerrorList.findIndex(x => x.errorNumber == 18) <= -1
                && currentValidationerrorList.findIndex(x => x.errorNumber == 19) <= -1 && currentValidationerrorList.findIndex(x => x.errorNumber == 23) <= -1 ? 'Payments Applied Since Itemization Date, Credits Applied Since Itemization Date (OR) Payments Credits Applied Since Itemization Date is required and must be Number' : null),
              field: "Payments_Credits_applied_since_itemization_date", index: index
            };
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 23 && x.index == index) <= -1) {
              currentValidationerrorList.push(interestDateValidation);
              setValidationErrors(currentValidationerrorList);
            };
          }
          else {
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 23 && x.index == index) > -1) {
              let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 23 && x.index !== index);
              setValidationErrors(errorList);
            };
          };
        });
      };

      if (selectedHeaderList.includes("delinquency_date")) {
        let currentValidationerrorList = _.cloneDeep(validationErrors);
        let validationList = _.cloneDeep(placementAccountList);
        validationList.map((x, index) => {
          if ((moment(x?.delinquency_date)?.isAfter(new Date())) || moment(x?.delinquency_date)?.format("YYYY") < moment(new Date())?.subtract(100, 'year')?.format('YYYY') || (mappedRequireFields.find(x => x.field_name === "delinquency_date") && (x?.delinquency_date === null || x?.delinquency_date === ""))) {
            let interestDateValidation: IValidationError = {
              errorNumber: 24, errorMessage: "Delinquency Date is required and can not be empty or Future date/Invalid date",
              field: "delinquency_date", index: index
            };
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 24 && x.index == index) <= -1) {
              currentValidationerrorList.push(interestDateValidation);
              setValidationErrors(currentValidationerrorList);
            };
          }
          else {
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 24 && x.index == index) > -1) {
              let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 24 && x.index !== index);
              setValidationErrors(errorList);
            };
          };
        });
      };

      if (selectedHeaderList.includes("Itemization_Date")) {
        let currentValidationerrorList = _.cloneDeep(validationErrors);
        let validationList = _.cloneDeep(placementAccountList);
        validationList.map((x, index) => {
          if ((moment(x?.Itemization_Date)?.isAfter(new Date()) || moment(x?.Itemization_Date)?.format("YYYY") < moment(new Date())?.subtract(100, 'year')?.format('YYYY') || (mappedRequireFields.find(x => x.field_name === "Itemization_Date") && (x?.Itemization_Date === null || x?.Itemization_Date === "")))) {
            let interestDateValidation: IValidationError = {
              errorNumber: 25, errorMessage: (currentValidationerrorList.findIndex(x => x.errorNumber == 25) <= -1 ? "Itemization Date is required and can not be empty or Future date/Invalid date" : null),
              field: "Itemization_Date", index: index
            };
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 25 && x.index == index) <= -1) {
              currentValidationerrorList.push(interestDateValidation);
              setValidationErrors(currentValidationerrorList);
            };
          }
          else {
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 25 && x.index == index) > -1) {
              let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 25 && x.index !== index);
              setValidationErrors(errorList);
            };
          };
        });
      };

      if (selectedHeaderList.includes("birth_date")) {
        let currentValidationerrorList = _.cloneDeep(validationErrors);
        let validationList = _.cloneDeep(placementAccountList);
        validationList.map((x, index) => {
          if ((moment(x?.birth_date)?.isAfter(new Date())) || (mappedRequireFields.find(x => x.field_name === "birth_date") && (x?.birth_date === null || x?.birth_date === ""))) {
            let interestDateValidation: IValidationError = {
              errorNumber: 26, errorMessage: (currentValidationerrorList.findIndex(x => x.errorNumber == 26) <= -1 ? "Birth Date is required and can not be empty or Future date/Invalid date" : null),
              field: "birth_date", index: index
            };
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 26 && x.index == index) <= -1) {
              currentValidationerrorList.push(interestDateValidation);
              setValidationErrors(currentValidationerrorList);
            };
          }
          else {
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 26 && x.index == index) > -1) {
              let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 26 && x.index !== index);
              setValidationErrors(errorList);
            };
          };
        });
      };

      if (selectedHeaderList.includes("Codebtor_BirthDate")) {
        let currentValidationerrorList = _.cloneDeep(validationErrors);
        let validationList = _.cloneDeep(placementAccountList);
        validationList.map((x, index) => {
          if ((moment(x?.Codebtor_BirthDate)?.isAfter(new Date())) || (mappedRequireFields.find(x => x.field_name === "Codebtor_BirthDate") && (x?.Codebtor_BirthDate === null || x?.Codebtor_BirthDate === ""))) {
            let interestDateValidation: IValidationError = {
              errorNumber: 27, errorMessage: (currentValidationerrorList.findIndex(x => x.errorNumber == 27) <= -1 ? "Codebtor Birth Date is required and can not be empty or Future date/Invalid date" : null),
              field: "Codebtor_BirthDate", index: index
            };
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 27 && x.index == index) <= -1) {
              currentValidationerrorList.push(interestDateValidation);
              setValidationErrors(currentValidationerrorList);
            };
          }
          else {
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 27 && x.index == index) > -1) {
              let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 27 && x.index !== index);
              setValidationErrors(errorList);
            };
          };
        });
      };

      if (selectedHeaderList.includes("service_date")) {
        let currentValidationerrorList = _.cloneDeep(validationErrors);
        let validationList = _.cloneDeep(placementAccountList);
        validationList.map((x, index) => {
          if ((mappedRequireFields.find(x => x.field_name === "service_date") && (x?.service_date === null || x?.service_date === "" || moment(x?.service_date)?.isAfter(new Date())) || moment(x?.service_date)?.format("YYYY") < moment(new Date())?.subtract(100, 'year')?.format('YYYY'))) {
            let interestDateValidation: IValidationError = {
              errorNumber: 28, errorMessage: (currentValidationerrorList.findIndex(x => x.errorNumber == 28) <= -1 ? "Service Date is required and can not be empty or Future date/Invalid date" : null),
              field: "service_date", index: index
            };
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 28 && x.index == index) <= -1) {
              currentValidationerrorList.push(interestDateValidation);
              setValidationErrors(currentValidationerrorList);
            };
          }
          else {
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 28 && x.index == index) > -1) {
              let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 28 && x.index !== index);
              setValidationErrors(errorList);
            };
          };
        });
      };

      if (selectedHeaderList.includes("last_payment_date")) {
        let currentValidationerrorList = _.cloneDeep(validationErrors);
        let validationList = _.cloneDeep(placementAccountList);
        validationList.map((x, index) => {
          if ((moment(x?.last_payment_date).isAfter(new Date()) || moment(x?.last_payment_date)?.format("YYYY") < moment(new Date())?.subtract(100, 'year')?.format('YYYY') || (mappedRequireFields.find(x => x.field_name === "last_payment_date") && (x?.last_payment_date === null || x?.last_payment_date === "")))) {
            let interestDateValidation: IValidationError = {
              errorNumber: 29, errorMessage: (currentValidationerrorList.findIndex(x => x.errorNumber == 29) <= -1 ? "Last Payment Date is required and can not be empty or Future date/Invalid date" : null),
              field: "last_payment_date", index: index
            };
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 29 && x.index == index) <= -1) {
              currentValidationerrorList.push(interestDateValidation);
              setValidationErrors(currentValidationerrorList);
            };
          }
          else {
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 29 && x.index == index) > -1) {
              let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 29 && x.index !== index);
              setValidationErrors(errorList);
            };
          };
        });
      };

      if (selectedHeaderList.includes("address1")) {
        let currentValidationerrorList = _.cloneDeep(validationErrors);
        let validationList = _.cloneDeep(placementAccountList);
        validationList.map((x, index) => {
          if (((mappedRequireFields.find(x => x.field_name === "address1") && x?.address1 === "" || x?.address1 === null))) {
            let cityValidation: IValidationError = {
              errorNumber: 30, errorMessage: (currentValidationerrorList.findIndex(x => x.errorNumber == 30) <= -1 ? "Street Address1 is required & cannot be empty" : null),
              field: "address1", index: index
            };
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 30 && x.index == index) <= -1) {
              currentValidationerrorList.push(cityValidation);
              setValidationErrors(currentValidationerrorList);
            };
          } else {
            if (currentValidationerrorList.findIndex(x => x.errorNumber == 30 && x.index == index) > -1) {
              let errorList = currentValidationerrorList.filter(x => x.errorNumber !== 30 && x.index !== index);
              setValidationErrors(errorList);
            };
          };
        });
      }

    })()
  }, [placementAccountList, selectedHeaderList, validationErrors, isSubmit])


  const getEmptyPlacementAccount = (): IPlacementAccount => {
    let placementAccount: IPlacementAccount = {
      phaseNumber: null,
      phase: null,
      client_code: null,
      account_no: null,
      first_name: null,
      last_name: null,
      full_name: null,
      address1: null,
      address2: null,
      city: null,
      state: null,
      zip: null,
      phone_no: null,
      total_balance: null,
      delinquency_date: null,
      secondary_address1: null,
      secondary_address2: null,
      secondary_city: null,
      secondary_state: null,
      secondary_zip: null,
      secondary_phone_no: null,
      email: null,
      drivers_license: null,
      tax_id: null,
      birth_date: null,
      past_due_balance: null,
      current_balance: null,
      equipment_charges: null,
      contract_charges: null,
      fees: null,
      last_payment_date: null,
      guarantor_first_name: null,
      guarantor_last_name: null,
      guarantor_full_name: null,
      comment: null,
      account_type: null,
      other: null,
      user_id: state.userAccessContext?.id,
      modifiedby: state?.userAccessContext?.userName,
      file_name: null,
      contact_name: null,
      service_date: null,
      location: null,
      Codebtor_Full_Name: null,
      Codebtor_Street_Address1: null,
      Codebtor_Street_Address2: null,
      Codebtor_City: null,
      Codebtor_State: null,
      Codebtor_Zip: null,
      Codebtor_BirthDate: null,
      Codebtor_Tax_ID: null,
      CoDebtor_First_Name: null,
      CoDebtor_Last_Name: null,
      CoDebtor_Phone1: null,
      CoDebtor_Phone2: null,
      Payments_applied_since_itemization_date: null,
      Credits_applied_since_itemization_date: null,
      Itemization_Date: null,
      Balance_owed_on_itemization_date: null,
      Fees_added_since_itemization_date: null,
      Interest_added_since_itemization_date: null,
      Payments_Credits_applied_since_itemization_date: null
    }
    return placementAccount;
  }

  const getTextFieldType = (datatype: string) => {
    switch (datatype) {
      case 'varchar': return 'text'
      case 'money': return 'text'
      case 'date': return 'date'
    }
  }

  const addMultipleRows = () => {
    let rows = _.cloneDeep(tablerows);
    let paList = _.cloneDeep(placementAccountList);
    for (let i = 0; i < numberOfRows; i++) {
      rows.push((rows.length + 1));
      let placementAccount: IPlacementAccount = getEmptyPlacementAccount();
      paList.push(placementAccount);
    }
    setPlacementAccountList(paList);
    setTableRows(rows);
  }

  const handleDeleteFilter = (r: any) => {
    if (tablerows.length == 1)
      return;
    let paList = _.cloneDeep(placementAccountList);
    let modifiedPAList = paList.filter((ele, index) => index != r);
    setPlacementAccountList(modifiedPAList);
    let validationerrorList = _.cloneDeep(validationErrors);
    validationerrorList = validationerrorList.filter((v) => v.index != r);
    setValidationErrors(validationerrorList);
    let tr = _.cloneDeep(tablerows);
    let trModified = tr.filter((ele, index) => index != r);
    trModified.forEach((f, indexnum) => { trModified[indexnum] = indexnum + 1 });
    setTableRows(trModified);
  }

  const handleAddRows = (value: string): void => {
    setNumberOfRows(value === '' ? '' : Number(value) || '');
  };

  const preventMinus = (e) => {
    if (e.code === 'Minus' || e.code === 'NumpadSubtract') {
      e.preventDefault();
    }
  };

  async function handleChange(field_Name, index, event, field_Type) {
    let headerList = _.cloneDeep(selectedHeaderList);
    let targetValue = (field_Type == "money" ? (event === undefined ? "0" : event) : event.target.value);
    if (!headerList.includes(field_Name)) {
      headerList.push(field_Name);
      setSelectedHeaderList(headerList);
    } if (field_Name !== "Delinquency_Itemization_Date") {
      let accountList: IPlacementAccount[] = _.cloneDeep(placementAccountList);
      eval('accountList[' + index + '].' + field_Name + ' = targetValue');
      setPlacementAccountList(accountList);
    } else if (field_Name == "Delinquency_Itemization_Date") {
      let accountList: IPlacementAccount[] = _.cloneDeep(placementAccountList);
      accountList[index].delinquency_date = targetValue;
      accountList[index].Itemization_Date = targetValue;
      setPlacementAccountList(accountList);
    };
  }

  useEffect(() => {
    (async () => {
      try {
        dispatch({ type: GlobalStateAction.Busy });
        await useFetch<{ uwfclT_FLD1: IUwfDetails[] }>(`Placements/GetClientItemizationDate?client_Code=${clientCode === "-2" ? null : clientCode}`).then((GetUWFDetails) => {
          setGetUwfCltData(GetUWFDetails.data[0].uwfclT_FLD1);
        });
      }
      catch (ex) {
        dispatch({ type: GlobalStateAction.Error, error: ex });
        dispatch({ type: GlobalStateAction.Idle });
      }
      finally {
        dispatch({ type: GlobalStateAction.Idle });
      }
    })()
  }, [GetUwfCltData]);

  // Genarate the Rejected accounts based on the placement reject rule
  function convertListToCSV(list: any[], columnsToRemove: string[]): { csvUrl: string; csvBlob: any } {
    // Extract column names from the first object
    const columnNames = Object?.keys(list[0]);

    let mergedList = [...list];
    if (placementDuplicateAccountsList?.length) {
      mergedList = [...list, ...placementDuplicateAccountsList];
    }
    // Filter out column names with null values and specified columns
    const nonNullColumns = columnNames?.filter(columnName => mergedList?.some(item => item[columnName] !== null) && !columnsToRemove.includes(columnName));

    // Filter the list objects to include only non-null columns
    const filteredList = mergedList?.map(item =>
      nonNullColumns.reduce((obj, columnName) => {
        obj[columnName] = item[columnName];
        return obj;
      }, {})
    );

    // This is to download the rejected accounts with account number and reject reason 
    const filteredData = filteredList.map(row => ({
      accountNumber: row['account_no'],
      rejectReason: row['Rejected Reason']
    }));

    // Generate CSV from the filtered list of non-null columns
    const csvData = Papa.unparse(filteredList);
    const csvBlob = new Blob([csvData], { type: 'text/csv' });

    // Convert filteredData back to CSV and generate a Blob URL
    const mergedCSV1 = Papa.unparse(filteredData);
    const csvBlobFile = new Blob([mergedCSV1], { type: 'text/csv' });
    const csvUrl = URL.createObjectURL(csvBlobFile);

    // Return both csvUrl and csvBlob
    return { csvUrl, csvBlob };
  }

  const splitConditions = /[-/\s]+/;
  // Get the date values based on the user identified date format
  function findValidDateFormat(dateString) {
    let month;
    let day;
    let year;
    let finalYear = 2000;
    const dateParts = dateString?.trim()?.split(splitConditions);
    // Extract day, month, and year from the input string
    day = dateParts[1];
    month = dateParts[0];
    year = dateParts[2];
    if (year?.length === 2) {
      year = finalYear + Number(dateParts[2]);
    }
    return `${year}/${month}/${day}`;
  }

  // Rule - 01 - Function to validate CFPB Balance is matching or not
  function calculateCFPBBalance(total_balance: number, currentAccountData: any, selectedHeaderList: string[]) {
    let result;
    let cfpbFees;
    let cfpbCredit;
    let cfpbPayment;
    let cfpbInterest;
    let cfpbBalanceAsOf;
    let cfpbPaymentCredit;
    let totalBalance = total_balance;
    if (totalBalance?.toString()?.includes("$")) {
      totalBalance = Number(totalBalance?.toString()?.replace(/\$|,/g, ""));
    } else {
      totalBalance = Number(totalBalance?.toString()?.replace(/\$|,/g, ""));
    }
    // Check if the Balance_owed_on_itemization_date field has $
    if (currentAccountData?.Balance_owed_on_itemization_date?.toString()?.includes("$")) {
      cfpbBalanceAsOf = Number(currentAccountData?.Balance_owed_on_itemization_date?.toString()?.replace(/\$|,/g, ""));
    } else {
      cfpbBalanceAsOf = Number(currentAccountData?.Balance_owed_on_itemization_date?.toString()?.replace(/\$|,/g, ""));
    }
    // Check if the Interest_added_since_itemization_date field has $
    if (currentAccountData?.Interest_added_since_itemization_date?.toString()?.includes("$")) {
      cfpbInterest = Number(currentAccountData?.Interest_added_since_itemization_date?.toString()?.replace(/\$|,/g, ""));
    } else {
      cfpbInterest = Number(currentAccountData?.Interest_added_since_itemization_date?.toString()?.replace(/\$|,/g, ""));
    }
    // Check if the Fees_added_since_itemization_date field has $
    if (currentAccountData?.Fees_added_since_itemization_date?.toString()?.includes("$")) {
      cfpbFees = Number(currentAccountData?.Fees_added_since_itemization_date?.toString()?.replace(/\$|,/g, ""));
    } else {
      cfpbFees = Number(currentAccountData?.Fees_added_since_itemization_date?.toString()?.replace(/\$|,/g, ""));
    }

    // check if the "Credits applied since itemization date" and "Payments applied since itemization date" columns are selected
    if (selectedHeaderList?.findIndex(x => x === "Payments_Credits_applied_since_itemization_date") != -1) {
      // Check if the Payments_Credits_applied_since_itemization_date field has $
      if (currentAccountData?.Payments_Credits_applied_since_itemization_date?.toString()?.includes("$")) {
        cfpbPaymentCredit = Number(currentAccountData?.Payments_Credits_applied_since_itemization_date?.toString()?.replace(/\$|,/g, ""));
      } else {
        cfpbPaymentCredit = Number(currentAccountData?.Payments_Credits_applied_since_itemization_date?.toString()?.replace(/\$|,/g, ""));
      }
    } else if ((selectedHeaderList?.findIndex(x => x == "Payments_applied_since_itemization_date") != -1) && (selectedHeaderList?.findIndex(x => x == "Credits_applied_since_itemization_date") != -1)) {
      // Check if the Payments_applied_since_itemization_date field has $
      if (currentAccountData?.Payments_applied_since_itemization_date?.toString()?.includes("$")) {
        cfpbPayment = Number(currentAccountData?.Payments_applied_since_itemization_date?.toString()?.replace(/\$|,/g, ""));
      } else {
        cfpbPayment = Number(currentAccountData?.Payments_applied_since_itemization_date?.toString()?.replace(/\$|,/g, ""));
      }
      // Check if the Credits_applied_since_itemization_date field has $
      if (currentAccountData?.Credits_applied_since_itemization_date?.toString()?.includes("$")) {
        cfpbCredit = Number(currentAccountData?.Credits_applied_since_itemization_date?.toString()?.replace(/\$|,/g, ""));
      } else {
        cfpbCredit = Number(currentAccountData?.Credits_applied_since_itemization_date?.toString()?.replace(/\$|,/g, ""));
      }
    }
    // Calculate the CFPB Balance and validate the Total Balance and the Calculation is matching or not 
    if (selectedHeaderList?.findIndex(x => x == "Payments_Credits_applied_since_itemization_date") != -1) {
      result = (cfpbBalanceAsOf + cfpbInterest + cfpbFees) - (cfpbPaymentCredit);
    } else {
      result = (cfpbBalanceAsOf + cfpbInterest + cfpbFees) - (cfpbPayment + cfpbCredit);
    }
    // Store the Rejected accounts list into to another state with rejected reason
    let newResult = (result === totalBalance ? true : false);
    if (!newResult) {
      const exist = PlacementRejectedAccountsList?.some((a) => currentAccountData?.account_no === a?.account_no);
      const newData = { ...currentAccountData };
      newData['Rejected Reason'] = 'CFPB balance not match'; // Add new column for rejected Accounts and adding rejected Reason in the List
      if (!exist) {
        PlacementRejectedAccountsList?.push(newData);
      }
    }
    return newResult;
  }

  // Rule - 02 - Function to validate "Existing account in Dispute, BK or Active"
  function OmitAccountNumberBasedOnRuleTwo(account: any, currentAccountData: any, accountNumberResult: any) {
    // Store the Rejected accounts list into to another state with rejected reason
    let newResult = accountNumberResult?.some(r => r?.accountNumber === account);
    if (newResult) {
      const exist = PlacementRejectedAccountsList?.some((a) => currentAccountData?.account_no === a?.account_no);
      const newData = { ...currentAccountData };
      newData['Rejected Reason'] = 'Existing account in Dispute, BK or Active'; // Add new column for rejected Accounts and adding rejected Reason in the List
      if (!exist) {
        PlacementRejectedAccountsList?.push(newData);
      }
    }
    // Validate the current account number is valid for the account placement
    return !accountNumberResult?.some(r => r?.accountNumber === account);
  }

  // Rule - 4 - Ignore the accounts which is "CFPB itemization date invalid" --> Based on Placement Reject Rules
  function OmitAccountNumberBasedOnRuleFour(dateValue: any, currentAccountData: any, itemizationDateStatueDate: any) {
    // Store the Rejected accounts list into to another state with rejected reason
    let newResult = itemizationDateStatueDate?.some(result => moment(result.itemizationDate).format("YYYY/MM/DD") === dateValue && result?.state === currentAccountData?.state);
    if (newResult) {
      const exist = PlacementRejectedAccountsList.some((a) => currentAccountData?.account_no === a?.account_no);
      const newData = { ...currentAccountData };
      newData['Rejected Reason'] = 'CFPB itemization date is past state statue compliance'; // Add new column for rejected Accounts and adding rejected Reason in the List
      if (!exist) {
        PlacementRejectedAccountsList?.push(newData);
      }
    }
    return !itemizationDateStatueDate?.some(result => moment(result.itemizationDate).format("YYYY/MM/DD") === dateValue && result?.state === currentAccountData?.state);
  }

  // Rule - 5 - Removed duplicate account no with same client code and created date
  function RemoveDuplicateAccountNumber(accountNumber: any, currentAccountData: any, rejectDupicateAcResult: any) {
    // Store the Rejected accounts list into to another state with rejected reason
    let newResult = rejectDupicateAcResult?.some(result => result?.accountNumber === accountNumber);
    // If the Account number exsit in the rejectDupicateAcResult Remove the accounts from the submition 
    if (newResult) {
      const exist = PlacementRejectedAccountsList.some((a) => currentAccountData?.account_no === a?.account_no);
      const newData = { ...currentAccountData };
      newData['Rejected Reason'] = 'Dups-all fields matching'; // Add new column for rejected Accounts and adding rejected Reason in the List
      if (!exist) {
        PlacementRejectedAccountsList?.push(newData);
      }
    }
    // Take the each account number from PlacementAccountList and validate that account numbers with accounts numbers from DB 
    return !rejectDupicateAcResult?.some(result => result?.accountNumber === accountNumber);
  }

  // Rule - 6 - Remove the Accounts with Special name reject due to compliances
  function RemoveAccountsWithSpecialName(currentAccountData: any, filteredHeaderList: string[]) {
    let finalFullName = "";
    // Construct the full name
    const fullName = `${currentAccountData?.first_name} ${currentAccountData?.last_name}`.trim();

    // Determine which name to check based on the filtered header list
    if (filteredHeaderList.includes("full_name") && currentAccountData?.full_name?.length > 1) {
      finalFullName = currentAccountData?.full_name;
    } else if (filteredHeaderList.includes("first_name") && filteredHeaderList.includes("last_name") && fullName?.length > 1) {
      finalFullName = fullName;
    } else if (filteredHeaderList.includes("first_name") && currentAccountData?.first_name?.length > 1) {
      finalFullName = currentAccountData?.first_name;
    } else if (filteredHeaderList.includes("last_name") && currentAccountData?.last_name?.length > 1) {
      finalFullName = currentAccountData?.last_name;
    }

    // Convert the finalFullName to lowercase for case-insensitive comparison
    finalFullName = finalFullName.toLowerCase();

    // Check if any of the special names are contained within the finalFullName
    const isSpecialName = specialNameListResult?.some(result => finalFullName.includes(result.specialName.toLowerCase()));

    if (isSpecialName) {
      // If the account number exists in the PlacementRejectedAccountsList, do not add it again
      const exists = PlacementRejectedAccountsList?.some(a => a?.account_no === currentAccountData?.account_no);

      if (!exists) {
        const newData = { ...currentAccountData, 'Rejected Reason': 'Special name reject due to compliances' };
        PlacementRejectedAccountsList?.push(newData);
      }

      // Reject the account
      return false;
    }
    // Accept the account if it doesn't match a special name
    return true;
  }

  // Rule - 07 - DOB is a minor --> Funtion is used to find the DOB is minor or not 
  function OmitAccountsBasedOnRuleSeven(dateString: any, currentAccountData: any) {

    let dateValue = dateString?.trim();

    if (!dateValue) {
      return true;
    }
    const today = new Date();
    if (dateValue) {
      const birthDate = new Date(dateValue);
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      // Store the Rejected accounts list into to another state with rejected reason
      let newResult = (age >= 18 ? true : false);
      if (!newResult) {
        const exist = PlacementRejectedAccountsList?.some((a) => currentAccountData?.account_no === a?.account_no);
        const newData = { ...currentAccountData };
        newData['Rejected Reason'] = 'DOB is a minor'; // Add new column for rejected Accounts and adding rejected Reason in the List
        if (!exist) {
          PlacementRejectedAccountsList?.push(newData);
        }
      }
      return age >= 18 ? true : false;
    }
    return true;
  }

  // Rule - 8 - Invalid State or Zip Code --> To filter State based on the status code
  function OmitStateBasedOnRuleEight(result: any, currentAccountData: any, stateCodeResult: any) {
    // Store the Rejected accounts list into to another state with rejected reason
    let newResult = stateCodeResult?.some(r => r?.stateCode === result);
    if (newResult) {
      const exist = PlacementRejectedAccountsList?.some((a) => currentAccountData?.account_no === a?.account_no);
      const newData = { ...currentAccountData };
      newData['Rejected Reason'] = 'Invalid State or Zip Code'; // Add new column for rejected Accounts and adding rejected Reason in the List
      if (!exist) {
        PlacementRejectedAccountsList?.push(newData);
      }
    }
    // Validate the current account number is valid for the account placement 
    return !stateCodeResult?.some(r => r?.stateCode === result);
  }

  // Rule - 8 - Invalid State or Zip Code --> To filter Zip Code based on the status code
  function OmitZipCodeBasedOnRuleEight(result: any, currentAccountData: any, zipCodeResult: any) {
    // Store the Rejected accounts list into to another state with rejected reason
    let truncatedZip = result?.match(/^\d{5}-\d{4}$/) ? result.slice(0, 5) : result;
    let newResult = zipCodeResult?.some(r => r.zipCode === truncatedZip);
    if (newResult) {
      const exist = PlacementRejectedAccountsList?.some((a) => currentAccountData?.account_no === a?.account_no);
      const newData = { ...currentAccountData };
      newData['Rejected Reason'] = 'Invalid State or Zip Code'; // Add new column for rejected Accounts and adding rejected Reason in the List
      if (!exist) {
        PlacementRejectedAccountsList?.push(newData);
      }
    }
    // Validate the current account number is valid for the account placement 
    return !zipCodeResult?.some(r => r.zipCode === truncatedZip);
  }

  // Rule - 09 - Ignore the accounts which is "Invalid or missing Last Charge Date" --> Based on Placement Reject Rules
  function OmitAccountNumberBasedOnRuleNine(deliquencyDate: any, fieldName: any, currentAccountData: any) {
    // Store the Rejected accounts list into to another state with rejected reason
    let newResult = (deliquencyDate === null || deliquencyDate === '1899/12/30' ? false : true);
    if (!newResult) {
      const exist = PlacementRejectedAccountsList?.some((a) => currentAccountData?.account_no === a?.account_no);
      const newData = { ...currentAccountData };
      newData['Rejected Reason'] = 'Invalid or missing Last Charge Date'; // Add new column for rejected Accounts and adding rejected Reason in the List
      if (!exist) {
        PlacementRejectedAccountsList?.push(newData);
      }
    }
    return (deliquencyDate === null || deliquencyDate === '1899/12/30' ? false : true);
  }

  // Rule - 10 - Function to validate "Existing Account Closed per Agency"
  function RejectAccountNumberBasedOnRuleTen(account: any, currentAccountData: any, accountNumberResult: any) {
    // Store the Rejected accounts list into to another state with rejected reason
    let newResult = accountNumberResult?.some(r => r?.accountNumber === account);
    if (newResult) {
      const exist = PlacementRejectedAccountsList?.some((a) => currentAccountData?.account_no === a?.account_no);
      const newData = { ...currentAccountData };
      newData['Rejected Reason'] = 'Existing Account Closed per Agency'; // Add new column for rejected Accounts and adding rejected Reason in the List
      if (!exist) {
        PlacementRejectedAccountsList?.push(newData);
      }
    }
    // Validate the current account number is valid for the account placement
    return !accountNumberResult?.some(r => r?.accountNumber === account);
  }

  // Rule Number 11 - Phone number validation 
  function RemovePhoneNumberBasedOnRuleEleven(rowPhoneNumber: any, currentAccountData: any) {
    const phoneValue = (rowPhoneNumber || "")?.replace(constant?.phoneRegexVal, "");
    // Store the Rejected accounts list into to another state with rejected reason
    const isInvalid = phoneNumberResult?.some(item => phoneValue.includes(item.phoneNo));

    if (isInvalid) {
      const existsInRejectedList = PlacementRejectedAccountsList?.some((a) => currentAccountData?.account_no === a?.account_no);
      if (!existsInRejectedList) {
        const newData = { ...currentAccountData };
        newData['Rejected Reason'] = 'Exception: Kinder DNC PhoneNo Removed';
        PlacementRejectedAccountsList?.push(newData);
      }
      return false;
    }
    return true;
  }

  // Rule - 12 - Exception:Age of the account is older than 5 years. --> Reject the account which is older then 5
  function OmitAccountsBasedOnRuleTwelve(dateString: any, currentAccountData: any, delinquencyDateStatueDate: any) {
    let newResult = delinquencyDateStatueDate?.some(result => moment(result.itemizationDate).format("YYYY/MM/DD") === currentAccountData?.delinquency_date && result?.state === currentAccountData?.state);
    if (newResult) {
      const exist = PlacementRejectedAccountsList?.some((a) => currentAccountData?.account_no === a?.account_no);
      const newData = { ...currentAccountData };
      newData['Rejected Reason'] = 'Exception: Age of the account is older than 5 years or date is invalid';
      if (!exist) {
        PlacementRejectedAccountsList?.push(newData);
      }
    }
    return !delinquencyDateStatueDate?.some(result => moment(result.itemizationDate).format("YYYY/MM/DD") === currentAccountData?.delinquency_date && result?.state === currentAccountData?.state);
  }

  // Rule - 14 - "NB but referral amount is less than PIF threshold" - Take each total number from placmentAccountList and validate the total due less than 5
  function RemovefilteredTotalBalance(balance: any, currentAccountData: any) {
    balance = Number(balance?.toString()?.replace(/\$|,/g, ""));
    // Store the Rejected accounts list into to another state with rejected reason
    let newResult = (balance >= 5 ? true : false);
    if (!newResult) {
      const exist = PlacementRejectedAccountsList?.some((a) => currentAccountData?.account_no === a?.account_no);
      const newData = { ...currentAccountData };
      newData['Rejected Reason'] = 'New Business but referral amount is less than PIF threshold'; // Add new column for rejected Accounts and adding rejected Reason in the List
      if (!exist) {
        PlacementRejectedAccountsList?.push(newData);
      }
    }
    return (balance >= 5 ? true : false);
  }

  // Rule - 15 - "CFPB Balance As Of Itemization Date <= 0"
  function OmitAccountNumberBasedOnRuleFifteen(balance_owed_on_itemization: any, currentAccountData: any) {
    // Store the Rejected accounts list into to another state with rejected reason
    let newResult = balance_owed_on_itemization <= 0 ? false : true;
    if (!newResult) {
      const exist = PlacementRejectedAccountsList?.some((a) => currentAccountData?.account_no === a?.account_no);
      const newData = { ...currentAccountData };
      newData['Rejected Reason'] = 'CFPB Balance As Of Itemization Date <= 0'; // Add new column for rejected Accounts and adding rejected Reason in the List
      if (!exist) {
        PlacementRejectedAccountsList?.push(newData);
      }
    }
    return balance_owed_on_itemization <= 0 ? false : true;
  }

  // Rule - 16 -  "Medical Accts in NV and NM" - Take each state from placmentAccountList and validate whether state code conatine "NV" and "NM"
  function RemoveNvNmStateCode(rowIndex: any, currentAccountData: any) {
    let state = currentAccountData?.state?.toUpperCase();
    let isNvOrNmState = (state === constant?.StateCodeNM || state === constant?.StateCodeNV);
    let isClientCodeExist = rejectNvNmStatusCode?.some(result => currentAccountData?.client_code === result?.dbT_CLIENT || clientCode === result?.dbT_CLIENT);

    if (isNvOrNmState) {
      if (isClientCodeExist) {
        const exist = PlacementRejectedAccountsList?.some((a) => currentAccountData?.account_no === a?.account_no);
        if (!exist) {
          // Add the account to the rejected list with a reason
          const newData = { ...currentAccountData };
          newData['Rejected Reason'] = 'Medical Accts in NV and NM';
          PlacementRejectedAccountsList?.push(newData);
        }
        return !rejectNvNmStatusCode?.some(result => placementAccountList[rowIndex]?.client_code != result?.dbT_CLIENT || clientCode != result?.dbT_CLIENT);
      }
    }
    return true;
  }

  /** Start */
  // Function to get the Valid account numbers from the Reports Database
  const checkValidAccountNumbers = async (accountNumberList: IAccountNumberList[]): Promise<IAccountNumberList[]> => {
    try {
      const request = {
        "accountDetailContracts": accountNumberList,
        "clientCode": clientCode
      };
      const result = await usePost<{ accountResultContracts: IAccountNumberList[] }>('Placements/GetExistingAccountNumber', request);
      return result?.data["accountResultContracts"];
    } catch (error) {
      console.error("Error fetching valid account numbers", error);
      return [];
    }
  };

  // Function to get the Valid account numbers from the Report Database
  const checkExistingAccountNumbers = async (accountNumberList: IAccountNumberList[]): Promise<IAccountNumberList[]> => {
    try {
      const request = {
        "accountDetailContracts": accountNumberList,
        "clientCode": clientCode
      };
      const result = await usePost<{ accountResultContracts: IAccountNumberList[] }>('Placements/GetExistingClosedPerAgencyAccountNumber', request);
      return result?.data["accountResultContracts"];
    } catch (error) {
      console.error("Error fetching valid account numbers", error);
      return [];
    }
  };

  // Function to get valid State and Zip code
  const checkValidStateAndZipCode = async (stateAndZipCodeResult: IStateAndZipCodeList[]): Promise<{ stateCodeResult: IStateResult[], zipCodeResult: IZipCodeResult[] }> => {
    let result;
    try {
      const request = {
        "stateAndZipCodeLists": stateAndZipCodeResult
      };
      result = await usePost<{ stateCodeResult: IStateResult[], zipCodeResult: IZipCodeResult[] }>('Placements/GetStateandZipValidation', request);
      return result?.data;
    } catch (error) {
      console.error('Error fetching state and zip validation', error);
      return { stateCodeResult: [], zipCodeResult: [] }; // Return an empty result in case of an error
    }
  };

  // This function to Get the Valid accounts number based on the Placement Reject Rule Five
  const handleGetValidAccountsForRuleFive = async (accountNumberList: IAccountNumberList[]): Promise<IAccountNumberList[]> => {
    const clientCodeList: IClientCodeDetail[] = [{ dbT_CLIENT: clientCode }];
    try {
      const request = {
        "clientCodeDetails": clientCodeList,
        "rejectAccountNumber": accountNumberList
      };
      const result = await usePost<{ accountResultContracts: IAccountNumberList[] }>("Placements/RejectDupicateAccountNumber", request);
      return result?.data?.accountResultContracts;
    } catch (error) {
      console.error("Error handling rule five validation", error);
      return [];
    }
  };

  // Function to validate Itemization Date and State
  const ItemizationDateAndStateValidation = async (data: IItemizationDateAndState[]): Promise<IItemizationResults[]> => {
    try {
      const request = {
        "rejectItemization": data
      };
      const result = await usePost<{ itemizationResults: IItemizationResults[] }>('Placements/RejectInvalidItemizationDate', request);
      return result?.data["itemizationResults"];
    } catch (error) {
      console.error("Error in ItemizationDate validation", error);
      return [];
    }
  };

  // Function to validate Delinquency Date and State
  const DelinquencyDateAndStateValidation = async (data: IItemizationDateAndState[]): Promise<IItemizationResults[]> => {
    try {
      const request = {
        "rejectItemization": data
      };
      const result = await usePost<{ itemizationResults: IItemizationResults[] }>('Placements/RejectInvalidItemizationDate', request);
      return result?.data["itemizationResults"];
    } catch (error) {
      console.error("Error in DelinquencyDate validation", error);
      return [];
    }
  };
  /** End */

  const handleFormSubmit = async (e) => {
    setShowProgressBar(true);
    (async () => {
      let request = {
        "clientCode": clientCode,
        "phaseCode": getPhaseString(phaseNumber)
      }
      await usePost<IClientCodeExempted>("Placements/ValidateClientCodeIsExempted", request).then((result) => {
        isClientCodeExempt = result?.data;
      });
    })()

    if (!props?.location?.state?.rejectNvNmStatusCode?.length) {
      await useFetch<IClientCodeDetail[]>("Placements/RejectNvNmStateCode").then((result) => {
        rejectNvNmStatusCode = result.data;
      });
    }

    if (!props?.location?.state?.phoneNumberResult?.length) {
      await useFetch<IPhonenumber[]>("Placements/GetPhoneNumberValidation").then((result) => {
        phoneNumberResult = result?.data;
      })
    }

    if (!props?.location?.state?.specialNameListResult?.length) {
      await useFetch<ISpecialNameList[]>("Placements/GetSpecialNameCompliancesList").then((result) => {
        specialNameListResult = result?.data;
      })
    }

    setIsSubmit(true);
    let finalPlacementList = _.cloneDeep(placementAccountList);

    // Read all the State and Zip code numbers based on the user selection  
    let newStateAndZipList = [];
    placementAccountList?.forEach((result) => {
      if (result?.state?.length === 2 && result?.zip?.length <= 10) {
        let truncatedZip = result?.zip.match(/^\d{5}-\d{4}$/) ? result?.zip.slice(0, 5) : result?.zip;
        let currentStateAndZipCode: IStateAndZipCodeList = {
          state: result?.state,
          zipCode: truncatedZip
        };
        newStateAndZipList?.push(currentStateAndZipCode);
      }
    });
    stateAndZipCodeResult = newStateAndZipList;
    // Get the validation results and wait for them
    const validationResults = await checkValidStateAndZipCode(newStateAndZipList);
    const { stateCodeResult, zipCodeResult } = validationResults;

    let newAccountNumberList: IAccountNumberList[] = [];
    let newItemizationDate: IItemizationDateAndState[] = [];
    let newDelinquencyDate: IItemizationDateAndState[] = [];

    // Populate account numbers
    placementAccountList?.forEach((result) => {
      newAccountNumberList.push({ accountNumber: result?.account_no });
    });

    // Populate itemization date list
    placementAccountList?.forEach((result) => {
      newItemizationDate.push({ state: result?.state, itemizationDate: result?.Itemization_Date });
    });

    // Populate delinquency date list
    placementAccountList?.forEach((result) => {
      newDelinquencyDate.push({ state: result?.state, itemizationDate: result?.Itemization_Date });
    });

    // Validate account numbers, rule five, itemization, and delinquency dates
    const [validAccountNumbers, ruleFiveValidAccounts, validItemizationDates, validDelinquencyDates, existingAccountNumber] = await Promise.all([
      checkValidAccountNumbers(newAccountNumberList),
      handleGetValidAccountsForRuleFive(newAccountNumberList),
      ItemizationDateAndStateValidation(newItemizationDate),
      DelinquencyDateAndStateValidation(newDelinquencyDate),
      checkExistingAccountNumbers(newAccountNumberList)
    ]);

    accountNumberResult = validAccountNumbers;
    itemizationDateStatueDate = validItemizationDates;
    delinquencyDateStatueDate = validDelinquencyDates;
    rejectDupicateAcResult = ruleFiveValidAccounts;
    existingAccountNumberList = existingAccountNumber;

    const accountNoCountMap = new Map();

    finalPlacementList.forEach(account => {
      const accountNo = account.account_no;
      accountNoCountMap.set(accountNo, (accountNoCountMap.get(accountNo) || 0) + 1);
    });


    finalPlacementList = finalPlacementList.filter(account => {
      const accountNo = account.account_no;
      if (accountNoCountMap.get(accountNo) > 1) {
        placementDuplicateAccountsList.push({
          ...account,
          'Rejected Reason': 'The account number is either empty or a duplicate in the file'
        });
        return false;
      } else {
        return true;
      }
    });


    if (!validationErrors.length && !requireFields.length) {
      //Rule - 1 - Ignore the accounts which is " CFPB balance not match " --> Based on Placement Reject Rules
      if (((isClientCodeExempt?.isClientCodeIsExempted == 0)) && getPhaseString(phaseNumber) != constant.FIRSTSTP) {
        if ((isClientCodeExempt?.isAuditLetter == 0) || (isClientCodeExempt?.isAuditLetter >= 1 && getPhaseString(phaseNumber) != constant.PREC)) {
          // Validating the placement rule - CFPB balance not match - Remove the invalid accounts
          if (selectedHeaderList.findIndex(x => x == "total_balance") > -1) {
            finalPlacementList = finalPlacementList?.filter((item, rowIndex) => calculateCFPBBalance(finalPlacementList[rowIndex]?.total_balance, finalPlacementList[rowIndex], selectedHeaderList));
          }
        }
      }

      // Rule - 2 - Ignore the accounts which is "Existing in Dispute, BK or Active" --> Based on Placement Reject Rules
      if (selectedHeaderList.findIndex(x => x == "account_no") > -1) {
        finalPlacementList = finalPlacementList.filter((item, rowIndex) => OmitAccountNumberBasedOnRuleTwo(finalPlacementList[rowIndex]?.account_no, finalPlacementList[rowIndex], accountNumberResult));
      }

      // Rule - 4 - Ignore the accounts which is "CFPB itemization date invalid" --> Based on Placement Reject Rules
      if (isClientCodeExempt?.isClientCodeIsExempted === 0) {
        if ((isClientCodeExempt?.isAuditLetter == 0) || (isClientCodeExempt?.isAuditLetter >= 1 && getPhaseString(phaseNumber) != constant.PREC)) {
          if (selectedHeaderList.findIndex(x => x == "Itemization_Date") > -1) {
            finalPlacementList = finalPlacementList.filter((item, rowIndex) => OmitAccountNumberBasedOnRuleFour(finalPlacementList[rowIndex]?.Itemization_Date, finalPlacementList[rowIndex], itemizationDateStatueDate));
          }
        }
      }

      // Rule - 5 - Removed duplicate account no with same client code and created date
      if (selectedHeaderList?.findIndex(x => x = "account_no") > -1) {
        finalPlacementList = finalPlacementList?.filter((item, rowIndex) => RemoveDuplicateAccountNumber(finalPlacementList[rowIndex]?.account_no, finalPlacementList[rowIndex], rejectDupicateAcResult));
      }

      // Rule - 6 -  "Special name reject due to compliances"
      if ((selectedHeaderList?.findIndex(x => x = "full_name") > -1) || (selectedHeaderList.findIndex(x => x == "first_name") > -1 && selectedHeaderList.findIndex(x => x == "last_name") > -1)) {
        finalPlacementList = finalPlacementList?.filter((item, rowIndex) => RemoveAccountsWithSpecialName(finalPlacementList[rowIndex], selectedHeaderList));
      }

      // Rule - 7 -  "DOB is a minor" --> Reject the Account which is Minor
      if (selectedHeaderList.findIndex(x => x == "birth_date") > -1) {
        finalPlacementList = finalPlacementList.filter((item, rowIndex) => OmitAccountsBasedOnRuleSeven(finalPlacementList[rowIndex]?.birth_date, finalPlacementList[rowIndex]));
      }

      // Rule - 8 - Ignore the accounts which is "Invalid State or Zip Code" --> Based on Placement Reject Rules 
      if (selectedHeaderList.findIndex(x => x == "state") > -1) {
        finalPlacementList = finalPlacementList.filter((item, rowIndex) => OmitStateBasedOnRuleEight(finalPlacementList[rowIndex]?.state, finalPlacementList[rowIndex], stateCodeResult));
      }
      // Rule - 8 - Ignore the accounts which is "Invalid State or Zip Code" --> Based on Placement Reject Rules
      if (selectedHeaderList.findIndex(x => x == "zip") > -1) {
        finalPlacementList = finalPlacementList.filter((item, rowIndex) => OmitZipCodeBasedOnRuleEight(finalPlacementList[rowIndex]?.zip, finalPlacementList[rowIndex], zipCodeResult));
      }

      // Rule - 09 - Ignore the accounts which is "Invalid or missing Last Charge Date" --> Based on Placement Reject Rules
      if (selectedHeaderList.findIndex(x => x == "delinquency_date") > -1) {
        finalPlacementList = finalPlacementList.filter((item, rowIndex) => OmitAccountNumberBasedOnRuleNine(finalPlacementList[rowIndex]?.delinquency_date, constant.delinquency_date, finalPlacementList[rowIndex]));
      }

      // Rule - 10 -  "Check client account no by numeric value. Same AccountNo active or BK"
      if (selectedHeaderList.findIndex(x => x == "account_no") > -1) {
        finalPlacementList = finalPlacementList.filter((item, rowIndex) => RejectAccountNumberBasedOnRuleTen(finalPlacementList[rowIndex]?.account_no, finalPlacementList[rowIndex], existingAccountNumberList));
      }

      // Rule - 11 -  "Exception:Kinder DNC PhoneNo Removed"
      // if (selectedHeaderList?.findIndex(x => x == "phone_no") > -1) {
      //   finalPlacementList = finalPlacementList?.filter((item, rowIndex) => RemovePhoneNumberBasedOnRuleEleven(placementAccountList[rowIndex]?.phone_no, placementAccountList[rowIndex]));
      //   placementAccountList = placementAccountList.filter((item, rowIndex) => RemovePhoneNumberBasedOnRuleEleven(placementAccountList[rowIndex]?.phone_no, placementAccountList[rowIndex]));
      // }

      // Rule - 12 - "Exception : Age of the account is older than 5 years"
      if (selectedHeaderList.findIndex(x => x == "delinquency_date") > -1) {
        finalPlacementList = finalPlacementList.filter((item, rowIndex) => OmitAccountsBasedOnRuleTwelve(finalPlacementList[rowIndex]?.delinquency_date, finalPlacementList[rowIndex], delinquencyDateStatueDate));
      }

      // Rule - 14 - "NB but referral amount is less than PIF threshold" - Remove duplicate account when total due less than late fee or total due greater than late fee.
      if (selectedHeaderList?.findIndex(x => x = "total_balance") > -1) {
        finalPlacementList = finalPlacementList?.filter((item, rowIndex) => RemovefilteredTotalBalance(finalPlacementList[rowIndex]?.total_balance, finalPlacementList[rowIndex]));
      }

      // Rule - 15 - "CFPB Balance As Of Itemization Date <= 0"
      if (isClientCodeExempt?.isClientCodeIsExempted === 0) {
        if ((isClientCodeExempt?.isAuditLetter == 0) || (isClientCodeExempt?.isAuditLetter >= 1 && getPhaseString(phaseNumber) != constant.PREC)) {
          if (selectedHeaderList.findIndex(x => x == "Balance_owed_on_itemization_date") > -1) {
            finalPlacementList = finalPlacementList.filter((item, rowIndex) => OmitAccountNumberBasedOnRuleFifteen(finalPlacementList[rowIndex]?.Balance_owed_on_itemization_date, finalPlacementList[rowIndex]));
          }
        }
      }

      // Rule - 16 -  "Medical Accts in NV and NM" - Reject if the address State = NV or NM and the CLT_CLASS = '02' (medical)
      if (selectedHeaderList?.findIndex(x => x = "state") > -1) {
        finalPlacementList = finalPlacementList?.filter((item, rowIndex) => RemoveNvNmStateCode(rowIndex, finalPlacementList[rowIndex]));
      }

      const columnsToRemove = ['user_id', 'modifiedby'];

      let csvUrl, csvBlob;
      if (PlacementRejectedAccountsList?.length !== 0) {
        ({ csvUrl, csvBlob } = convertListToCSV(PlacementRejectedAccountsList, columnsToRemove));
      }
      setCSVUrl(csvUrl);

      history.push('\previewdata', {
        clientCode: clientCode, tablerows: tablerows, phaseNumber: phaseNumber, multiCode: multiCode, filteredPlacementAccountList: finalPlacementList, templateList: templateList, templateId: templateId,
        selectedHeaderList: selectedHeaderList, stateCodes: getStates, placementAccountList: placementAccountList, csvUrl: csvUrl, PlacementRejectedAccountsList: PlacementRejectedAccountsList,
        csvBlob: csvBlob, zipCodeResult: zipCodeResult, stateCodeResult: stateCodeResult, phoneNumberResult: phoneNumberResult, accountNumberList: accountNumberList, accountNumberResult: accountNumberResult,
        rejectNvNmStatusCode: rejectNvNmStatusCode, specialNameListResult: specialNameListResult, itemizationDateList: itemizationDateList, delinquencyDateList: delinquencyDateList,
        stateAndZipCodeResult: stateAndZipCodeResult, rejectDupicateAcResult: rejectDupicateAcResult, itemizationDateStatueDate: itemizationDateStatueDate,
        delinquencyDateStatueDate: delinquencyDateStatueDate, placementDuplicateAccountsList: placementDuplicateAccountsList, isClientCodeExempt: isClientCodeExempt, existingAccountNumberList: existingAccountNumberList
      });
      setShowProgressBar(false);
    } else {
      setAlertOpen(true);
      setShowProgressBar(false);
    }
  };

  return (
    <React.Fragment>
      <Backdrop className={classes.backdrop} open={progressBar}>
        <PuffLoader size={80} color={"white"} speedMultiplier={1} />
      </Backdrop>
      <Dialog TransitionComponent={Transition}
        fullScreen={fullScreen} open={alertOpen} onClose={handleAlertClose} maxWidth={'xs'}
        aria-labelledby="responsive-dialog-title" PaperProps={{ style: { borderRadius: 10 } }} >
        <DialogTitleDelete id="responsive-dialog-title" onClose={handleAlertClose}>
          <Typography variant="h6" gutterBottom className={classes.titleheader}>
            MANUAL ENTRY
          </Typography>
        </DialogTitleDelete>
        <DialogContent>
          <Typography variant="h6" className={classes.MessageStyle} gutterBottom>
            Please provide all the necessary data.
          </Typography>
        </DialogContent>
        <DialogActions >
          <Button id="DU_ok_btn" variant="contained" size="small" onClick={(e) => { setAlertOpen(false); setErrorStyle(true) }} className={classes.yesButton}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <div className={classes.div}>
        <Backdrop className={classes.backdrop} open={state.busy}>
          <PuffLoader size={80} color={"white"} speedMultiplier={1} />
        </Backdrop>
        <Container fixed className={classes.container} component="div" >
          <Grid container style={{ justifyContent: 'space-between' }}>
            <Grid item xs={6}>
              {(GetUwfCltData !== undefined) ?
                <Box width='45%' display="flex" p={1} bgcolor="white" className={classes.uwfBoxStyle}>
                  <span className={classes.InfoStyle}>
                    Your account itemization date is: <span className={classes.uwfStyle}>{GetUwfCltData}</span> . If you need to change this, please contact clientservices@armsolutions.com.
                  </span>
                </Box>
                : null}
              <Typography variant="h5" gutterBottom className={`${classes.title} ${"headertitle"}`}>
                <b>2.INPUT DATA </b>
              </Typography>
            </Grid>
            <Grid item xs={5} >
              <Box className={classes.LabelStyle}>
                <FormLabel style={{ fontWeight: 'bold', color: 'green', fontSize: 15 }}> Total Added Row : {tablerows?.length}</FormLabel>
              </Box>
              <Box p={0.5} flexShrink={0} bgcolor="white" className={classes.buttonbox}>
                <Button id="FN_Next_btn" size="small" endIcon={<ArrowForwardIosIcon />} className={classes.buttons}
                  variant="contained" type='submit' color="primary" onClick={handleSubmit(handleFormSubmit)}>
                  Next
                </Button>
              </Box>
              <Box p={0} flexShrink={0} bgcolor="white" className={classes.buttonbox}>
                <Button id-="FN_Back_btn" size="small" startIcon={<ArrowBackIosIcon />}
                  className={classes.buttons} variant="contained" color="primary"
                  component={Link} to="/placement">
                  Back
                </Button>
              </Box>
              <Box p={0} flexShrink={0} bgcolor="white" className={classes.buttonbox}>
                <CurrencyInput
                  maxLength={2}
                  value={numberOfRows}
                  className={classes.inputField}
                  allowDecimals={false}
                  allowNegativeValue={false}
                  onValueChange={handleAddRows}
                  style={{ textAlign: 'center' }}
                />
                <Button id="FN_AddRows" size="small" startIcon={<QueuePlayNextIcon />} className={classes.buttons} disabled={validationErrors.length > 0 ? true : false}
                  variant="contained" color="primary" onClick={addMultipleRows}>
                  Add Rows
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} className={classes.messagegrid}>
              <Box p={1} width="100%" bgcolor="white">
                {isSubmit === true && savingEnable == false && !validationErrors.length ?
                  <Typography variant="body1" gutterBottom className={`${errorStyle === false ? classes.error_message : classes.error_message1}`}>
                    <b style={{ textDecoration: 'underline' }}>Note: </b>
                    {!state.busy ? 'The following fields are required:' : null}
                    {requireFields.map((field) => (<> &nbsp;{`${field.display_name}`},  </>))}
                  </Typography> : null}
              </Box>
              <Box bgcolor="white" display="flex" alignItems="center" justifyContent="center">
                <Typography variant="body1" gutterBottom style={{ color: "red", fontSize: 12 }}>
                  {errorMessage.map((field) => (<> &nbsp;{`${field.errorMessage !== null ? field.errorMessage : null}`}{field.errorMessage !== null ? ',' : ''}  </>))}
                </Typography>
              </Box>
            </Grid>
          </Grid >
          <TableContainer component={Paper} className={classes.tablebody}>
            {templateList.length > 0 ?
              <Table stickyHeader aria-label="collapsible table">
                <TableHead className={classes.tableHead} >
                  <TableRow key='table-header' className={classes.tableHead1}>
                    {templateList.map((row) =>
                    (<StyledTableCell className={`${classes.tableRow1}`}
                      key={row.display_name + 'header'} >
                      <span className={classes.textWrap}>
                        {row.display_name.trim()}
                      </span>
                    </StyledTableCell>
                    ))}
                    <StyledTableCell className={classes.tableRow1}>
                      Delete
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {placementAccountList.map((r, index) => (
                    <TableRow key={index} className={classes.tableRow}>
                      {templateList.map((row) =>
                      (<StyledTableCell key={row.display_name + index} className={classes.tableRow}>
                        {row.data_type == 'date' ?
                          <TextField id={row.field_name + index} placeholder={row.display_name.replace('(*)', '')}
                            size="small"
                            name={row.field_name + index} type={getTextFieldType(row.data_type)}
                            className={classes.textField1} InputLabelProps={{ shrink: true }}
                            value={eval('placementAccountList[' + index + ']?.' + row.field_name)}
                            onChange={(e) => handleChange(row?.field_name, index, e, row?.data_type)}
                            key={row.display_name + index}
                            inputProps={{ maxLength: row?.field_length, max: (moment(new Date()).format('YYYY-MM-DD')) }}
                            InputProps={{
                              disableUnderline: true,
                            }}
                            style={validationErrors.find(x => x.field === row.field_name) && validationErrors.find(x => x.index === index) ? { border: '2px solid red' } : { backgroundColor: "white" }}
                          />
                          : row.data_type == 'money' ?
                            <CurrencyInput
                              className={classes.moneyField}
                              intlConfig={{ locale: 'en-US', currency: 'USD' }}
                              id={row.field_name + index}
                              key={row.display_name + index}
                              value={eval('placementAccountList[' + index + ']?.' + row.field_name)}
                              allowNegativeValue={false}
                              placeholder={row.display_name.replace('(*)', '')}
                              maxLength={row?.field_length}
                              prefix='$'
                              disableAbbreviations={true}
                              decimalsLimit={2}
                              decimalScale={2}
                              onValueChange={(value, name) => {
                                handleChange(row?.field_name, index, value, row?.data_type)
                              }}
                              onKeyPress={preventMinus}
                              autoComplete='off'
                              style={validationErrors.find(x => x.field === row.field_name) && validationErrors.find(x => x.index === index) ? { border: '2px solid red' } : { backgroundColor: "white" }}
                            />
                            :
                            <TextField id={row.field_name + index} placeholder={row.display_name.replace('(*)', '')}
                              size="small"
                              name={row.field_name + index} type={getTextFieldType(row.data_type)}
                              className={classes.textField} InputLabelProps={{ shrink: true }}
                              key={row.display_name + index}
                              value={eval('placementAccountList[' + index + ']?.' + row.field_name)}
                              onChange={(e) => handleChange(row.field_name, index, e, row?.data_type)}
                              inputProps={{ maxLength: row?.field_length, max: row?.field_length }}
                              InputProps={{ disableUnderline: true }}
                              style={validationErrors.find(x => x.field === row.field_name) && validationErrors.find(x => x.index === index) ? { border: '2px solid red' } : { backgroundColor: "white", fontSize: 12 }}
                            />
                        }
                      </StyledTableCell>
                      ))}
                      <StyledTableCell className={classes.tableRow}>
                        <IconButton id="FR_DeleteIcon" aria-label="delete" disableRipple={true} disableFocusRipple={true}
                          onClick={() => handleDeleteFilter(index)} className={classes.deleteIconStyle} style={{ padding: '8px' }}>
                          <HighlightOffIcon style={{ color: "red", fontSize: 25 }} />
                        </IconButton>
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table> :
              <Backdrop className={classes.backdrop} open={true}>
                <PuffLoader size={80} color={"white"} speedMultiplier={1} />
              </Backdrop>
            }
          </TableContainer>
        </Container >
        <Footer />
      </div >
    </React.Fragment >
  )
}

export default withRouter(Fieldnames)