import {
    Backdrop, Badge, Button, Checkbox, Collapse, Dialog, DialogActions, DialogContent,
    FormControl, FormControlLabel, InputLabel, Paper, Select, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TextField
} from '@material-ui/core';
import _ from 'lodash';
import List from '@mui/material/List';
import React, { useState } from 'react';
import { PuffLoader } from 'react-spinners';
import { useStyles } from './AgentActivityCss';
import ListIcon from '@mui/icons-material/List';
import { usePost } from '../../../utils/apiHelper';
import CancelIcon from '@mui/icons-material/Cancel';
import { Scrollbars } from 'react-custom-scrollbars';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import LightTooltip from '../../GlobalStyles/LightTooltip';
import { useGlobalState } from '../../../store/GlobalStore';
import { Typography, IconButton, Grid } from '@mui/material';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import StyledTableRow from '../../GlobalStyles/StyledTableRow';
import { DialogTitleHeader } from '../../GlobalStyles/DialogStyle';
import { Transition } from '../../GlobalStyles/DialogBoxTransition';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { StyledTableCell } from '../../GlobalStyles/TableStyledCell';
import { IAgentDebtor } from '../../../models/AgentInquiry/IAgentDebtor';
import { IValidationError } from '../../../models/common/IValidationError';
import { IActivityContract } from '../../../models/AgentTemplate/IActivityContract';
import activtyHistory from '../../../images/agentUser/agentInquiry/activtyHistory.svg';
import { IInsertTransactionLog } from '../../../models/UpdateHistory/GetTransactionOptions';
import { IActivityControlResult } from '../../../models/AgentInquiry/IAgentActivityPermission';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import { Category, IAgentActivityHistory, IAgentActivityListCount } from '../../../models/AgentInquiry/IAgentActivityListCount';

interface AgentActivitiesGroupingProps {
    debtor: IAgentDebtor;
    activityCount: IAgentActivityListCount[];
    data: Activity[];
    ActivityControlList: IActivityControlResult[];
    handleSaveSelectedActivities: (event: any) => void;
    handleChangeTextValues: (event, fieldId: number, activityControlResult: IActivityControlResult[]) => void;
    handleDropDownChanged: (event: any, fieldId: number) => void;
    handleValidateControl: (event: any, activityControlResult: IActivityControlResult[]) => void;
    isCommunicationMethodChecked: boolean;
    level: number
}

interface Activity {
    ActivityMapId: number;
    ActivityName: string;
    ActivityDescription: string;
    Activities?: Activity[];
}

const AgentTrackInteraction: React.FC<AgentActivitiesGroupingProps> = (props) => {
    const { data } = props;
    const { debtor } = props;
    const classes = useStyles();
    const { activityCount } = props;
    const { state } = useGlobalState();
    const { ActivityControlList, level } = props;
    const [ProgressBar, setshowProgressBar] = useState(false);
    const [activityName, setActivityName] = useState<string>("");
    const [activityHistory, setActivityHistory] = useState(false);
    const [allActivityHistory, setAllActivityHistory] = useState(false);
    const [insertLog, setInsertLog] = useState<IInsertTransactionLog[]>([]);
    const [saveActivity, setSaveActivity] = useState<IActivityContract[]>([]);
    const [activityPermisison, setActivityPermisssion] = useState<boolean>(false);
    const [validationErrors, setValidationErrors] = useState<IValidationError[]>([]);
    const [ActivityHistoryList, setActivityHistoryList] = useState<IAgentActivityHistory[]>([]);

    const [openNodes, setOpenNodes] = useState<Record<number, string | null>>({});

    let activityId = 0;
    let feedOperation = [];

    const handleChecked = (e) => {
        let activityList = _.cloneDeep(saveActivity);
        let activityLog = _.cloneDeep(insertLog);
        if (saveActivity.findIndex(x => x.activityFieldId == e?.target?.value) <= -1) {
            let activityDetails = { activityFieldId: e?.target?.value };
            let logDetails = { operationName: e?.target?.name, operationText: e?.target?.name }
            activityLog.push(logDetails);
            activityList.push(activityDetails);
            setSaveActivity(activityList);
            setInsertLog(activityLog);
        }
        else {
            if (saveActivity.findIndex(x => x.activityFieldId == e?.target?.value) > -1) {
                let activityDetails = saveActivity.filter(x => x.activityFieldId !== e?.target?.value);
                let logDetails = insertLog.filter(x => x.operationName !== e?.target?.name);
                setSaveActivity(activityDetails);
                setInsertLog(logDetails);
            };
        }
        // call back function to get the selected activities 
        props?.handleSaveSelectedActivities(e)
    };

    const getActivityHistoryList = (activityFieldId: number) => {
        setActivityHistory(true);
        setshowProgressBar(true);
        (async () => {
            let request = {
                "dbtNo": debtor?.dbT_NO,
                "fieldId": activityFieldId
            }
            await usePost<IAgentActivityHistory[]>("AgentUser/GetActivitiesHistoryDetails", request).then((r) => {
                setActivityHistoryList(r?.data);
                setActivityName(r?.data[0]?.activityName);
            }).finally(() => {
                setshowProgressBar(false);
            })
        })()
    }

    const getAllActivityHistoryList = () => {
        setAllActivityHistory(true);
        setshowProgressBar(true);
        (async () => {
            let request = {
                "dbtNo": debtor?.dbT_NO
            }
            await usePost<IAgentActivityHistory[]>("AgentUser/GetActivitiesHistoryByDebtorIdDetails", request).then((r) => {
                setActivityHistoryList(r?.data);
            }).finally(() => {
                setshowProgressBar(false);
            })
        })()
    }

    const GetActivityHistory = (activity: number) => {
        let filteredActivity = activityCount?.filter((x) => x.activityMapId === activity);
        if (filteredActivity?.length) {
            return filteredActivity[0]?.totalCount;
        }
    }

    function EnhancedTableHead(props) {
        const { classes, order, orderBy, } = props;

        const headCells = [
            { id: "first_name", disablePadding: false, label: "FIRST NAME", sortable: true },
            { id: "last_name", disablePadding: false, label: "LAST NAME", sortable: true },
            { id: "email_address", disablePadding: false, label: "EMAIL ADDRESS", sortable: true },
            { id: "elapsed_time", disablePadding: false, label: "ELAPSED TIME", sortable: true },
            { id: "dbT_No", disablePadding: false, label: "CREATED DATE", sortable: true },
            { id: "activity_name", disablePadding: false, label: "ACTIVITY NAME", sortable: true }
        ]

        return (
            <TableHead>
                <StyledTableRow >
                    {headCells.map(headCell => (
                        <TableCell size="small" key={headCell.id} sortDirection={orderBy === headCell.id ? order : false} className={classes.tableCellColor} >
                            {headCell.label}
                        </TableCell>
                    ))}
                </StyledTableRow>
            </TableHead >
        );
    }

    const handleActivityClose = () => {
        setActivityHistory(false);
        setSaveActivity([]);
    }

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setActivityPermisssion(false);
    };

    const handleValidateControls = (event, activityControlResult: IActivityControlResult[]) => {
        let controlValue = event.target.value;
        let currentValidationerrorList = _.cloneDeep(validationErrors);
        for (var i = 0; i < activityControlResult?.length; i++) {
            if (activityControlResult[i]?.controlId === Number(event.target.id) && activityControlResult[i]?.regex !== null) {
                var regexValue = new RegExp(activityControlResult[i]?.regex);
                if (regexValue?.test(controlValue)) {
                    if (currentValidationerrorList.findIndex(x => x.errorNumber == event.target.id) > -1) {
                        let errorList = currentValidationerrorList.filter(x => x.errorNumber !== event.target.id);
                        setValidationErrors(errorList);
                    };
                } else if (controlValue === "") {
                    if (activityControlResult[i]?.label?.includes(event.target.name) && activityControlResult[i]?.isRequired) {
                        activityControlResult.map((x, index) => {
                            if (!regexValue?.test(controlValue)) {
                                let regexValidation: IValidationError = {
                                    errorNumber: event.target.id, field: event.target.name, errorMessage: " " + event.target.name + " is Required"
                                }; if (currentValidationerrorList.findIndex(x => x.errorNumber == event.target.id) <= -1) {
                                    currentValidationerrorList.push(regexValidation);
                                    setValidationErrors(currentValidationerrorList);
                                };
                            }
                        });
                    }
                } else {
                    if (activityControlResult[i]?.label?.includes(event.target.name)) {
                        activityControlResult.map((x, index) => {
                            if (!regexValue?.test(controlValue)) {
                                let regexValidation: IValidationError = {
                                    errorNumber: event.target.id, field: event.target.name, errorMessage: "Please Enter valid " + event.target.name + " "
                                }; if (currentValidationerrorList.findIndex(x => x.errorNumber == event.target.id) <= -1) {
                                    currentValidationerrorList.push(regexValidation);
                                    setValidationErrors(currentValidationerrorList);
                                };
                            }
                        });
                    }
                }
            }
        }

        // call back function to validate each activity controls 
        props?.handleValidateControl(event, activityControlResult);
    }

    const handleTextChange = (event, fieldId: number, activityControlResult: IActivityControlResult[]) => {
        // call back function to read the text control values
        props?.handleChangeTextValues(event, fieldId, activityControlResult);
    };

    const handleDropDownChange = (event, fieldId: number) => {
        // call back function to read the dropdown control values
        props?.handleDropDownChanged(event, fieldId);
    };

    const renderSubControl = (activityControl: IActivityControlResult[], activityId: number) => {
        const allComponents = [];
        for (var i = 0; i < activityControl?.length; i++) {
            switch (activityControl[i]?.controlName) {
                case "TextBox":
                    {
                        allComponents.push(
                            <TextField onBlur={(e) => { handleTextChange(e, activityId, activityControl); }}
                                fullWidth
                                onChange={(e) => { handleValidateControls(e, activityControl); }}
                                className={`${classes.activityTextStyle}`}
                                label={`${activityControl[i]?.label}`}
                                size="small" name={`${activityControl[i]?.label}`}
                                id={`${activityControl[i]?.controlId}`}
                                helperText={validationErrors?.map((field) => (activityControl[i]?.controlId === Number(field?.errorNumber) ? field?.errorMessage : null))}
                                error={!validationErrors?.findIndex(x => Number(x?.errorNumber) === activityControl[i]?.controlId) ? true : false}
                                inputProps={{ maxlength: activityControl[i]?.maxLength }}
                                required={activityControl[i]?.isRequired ? true : false}
                                variant="outlined" margin="dense" />
                        )
                        break;
                    }
                case "DropDownList":
                    {
                        let dropDownValue;
                        let optionItems;
                        allComponents.push(<FormControl size="small" variant="outlined" fullWidth className={classes.activityDropDownStyle} style={{ marginTop: "15px" }}>
                            <InputLabel htmlFor="outlined-age-native-simple">{activityControl[i]?.label}</InputLabel>
                            <Select native
                                onBlur={(e) => { handleDropDownChange(e, activityId); }}
                                label={`${activityControl[i]?.label}`}
                                name={`${activityControl[i]?.label}`}
                                id={`${activityControl[i]?.controlId}`} >
                                {dropDownValue = (activityControl[i]?.controlValues).split("|")}
                                {optionItems = dropDownValue.map((r) => {
                                    return (
                                        <option value={r}>{r}</option>
                                    )
                                })
                                }
                            </Select>
                        </FormControl>)
                        break;
                    }
                case "CheckBox":
                    {
                        allComponents.push(<Checkbox size="small" name={`${activityName}`} />)
                        break;
                    }
            }
        }
        return allComponents;
    }

    const handleClick = (activityValue: string, level: number) => {
        setOpenNodes((prevOpenNodes) => {
            const isNodeOpen = prevOpenNodes[level] === activityValue;
            const newOpenNodes = { ...prevOpenNodes, [level]: isNodeOpen ? null : activityValue };
            // Close nodes below the clicked level
            for (let i = level + 1; i < Object.keys(newOpenNodes).length; i++) {
                newOpenNodes[i] = null;
            }
            return newOpenNodes;
        });
    };

    const renderActivityTree = (activities: Category[], level: number) => {
        return activities?.map(activity => (
            <React.Fragment key={activity?.ActivityName}>
                <ListItemButton onClick={(_) => { handleClick(activity?.ActivityName, level); }} sx={{ pt: "5px", pb: 0 }} className={classes.listItemStyle}>
                    <ListItemIcon sx={{ minWidth: 30 }}>
                        {activity?.Activities ? (
                            openNodes[level] === activity?.ActivityName ? (
                                <IndeterminateCheckBoxOutlinedIcon fontSize="small" />
                            ) : (
                                <AddBoxOutlinedIcon fontSize="small" />
                            )
                        ) : (
                            <Grid container spacing={0}>
                                <Grid item xs={12}>
                                    <FormControl component="fieldset" fullWidth>
                                        <FormControlLabel
                                            disabled={!props?.isCommunicationMethodChecked}
                                            id="AG_emailreceived"
                                            onChange={(e) => { handleChecked(e); }}
                                            labelPlacement="end" value={activity?.ActivityMapId}
                                            control={<Checkbox size="small" name={`${activity?.ActivityDescription}`} id={`${activity?.ActivityName}`} style={{ padding: 0 }} />}
                                            className={`${!(saveActivity?.findIndex(x => x.activityFieldId == activity?.ActivityMapId) <= -1) ? classes.permittedChecked : classes.permittedFiled}`}
                                            label={
                                                <div className={classes.activityStyle}>
                                                    <span>
                                                        <span className={classes.activityText}>{activity?.ActivityName}</span>
                                                        <Badge onClick={() => { getActivityHistoryList(activity?.ActivityMapId); setAllActivityHistory(false); }} badgeContent={GetActivityHistory(activity?.ActivityMapId)} color="secondary" className={classes.badgeStyle}>
                                                            {GetActivityHistory(activity?.ActivityMapId) ?
                                                                <LightTooltip title="View Activity History" >
                                                                    <IconButton onClick={() => { getActivityHistoryList(activity?.ActivityMapId); setAllActivityHistory(false); }} style={{ padding: '2px' }}>
                                                                        <img src={activtyHistory} width="25px" height="25px" />
                                                                    </IconButton>
                                                                </LightTooltip>
                                                                : null
                                                            }
                                                        </Badge>
                                                    </span>
                                                </div>
                                            } />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        )}
                    </ListItemIcon>
                    <ListItemText>
                        {activity?.Activities ? (
                            <Typography variant="body2" style={{ fontSize: '13px' }}>
                                {activity?.ActivityName}
                            </Typography>
                        ) : null}
                    </ListItemText>
                </ListItemButton>

                {activity?.Activities && <ul>
                    <Collapse in={openNodes[level] === activity?.ActivityName} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding sx={{ borderLeft: '1px dashed lightgray', ml: "-15px" }}>
                            {renderActivityTree(activity?.Activities, level + 1)} </List>
                    </Collapse>
                </ul>}

                {activity?.ActivityMapId && (
                    <React.Fragment>
                        {ActivityControlList?.forEach((a) => {
                            if (activity?.ActivityMapId === a?.activityId) {
                                let operations: IActivityControlResult = {
                                    activityId: a?.activityId,
                                    controlName: a?.controlName,
                                    controlValues: a?.controlValues,
                                    label: a?.label,
                                    regex: a?.regex,
                                    isRequired: a?.isRequired,
                                    cssClassName: a?.cssClassName,
                                    maxLength: a?.maxLength,
                                    defaultValue: a?.defaultValue,
                                    isActive: a?.isActive,
                                    controlId: a?.controlId
                                };
                                feedOperation.push(operations);
                                activityId = a?.activityId;
                            }
                        })}
                        {activity?.ActivityMapId === activityId && !(saveActivity.findIndex(x => x.activityFieldId == activity?.ActivityMapId) <= -1) ?
                            <div className={classes.activityDiv}>
                                <Grid container spacing={0} boxShadow={5} mt={1} p={"8px 13px"} borderRadius={2} >
                                    <Grid item xs={12}>
                                        <Typography align="center" variant="body2" color={'#2164cd'} bgcolor={'#ebf2ff'} borderRadius={1} mt={1} p={1}>
                                            <b>{activity?.ActivityName} - Details</b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {renderSubControl(feedOperation.filter((v) => v?.activityId == activity?.ActivityMapId), activity?.ActivityMapId)}
                                    </Grid>
                                </Grid>
                            </div>
                            : null
                        }
                    </React.Fragment>
                )
                }
            </React.Fragment>
        ));
    };

    return (
        <React.Fragment>
            <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={10} className={classes.tableBodyTrack}>
                <List sx={{ width: '100%', padding: "0px 0llpx 0px 0px" }}>
                    {renderActivityTree(data, level)}
                </List>
            </Scrollbars>

            <Dialog
                PaperProps={{ style: { borderRadius: 10 } }}
                open={activityHistory}
                classes={{ paper: classes.dialogePaper }}
                onClose={handleActivityClose}
                TransitionComponent={Transition}
                aria-labelledby="responsive-dialog-title" >
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleActivityClose}>
                    <Typography variant="h6" gutterBottom className={classes.titleheader}>
                        {allActivityHistory ?
                            <>{"SHOW ALL ACTIVITY"}</>
                            :
                            <>{activityName?.toUpperCase()}</>
                        }
                    </Typography>
                </DialogTitleHeader>
                <DialogContent>
                    <Backdrop className={classes.backdrop} open={ProgressBar}>
                        <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                    </Backdrop>
                    <Paper>
                        <TableContainer component={Paper} className={`${classes.tableBody1} ${"scrollbox"} ${"on-scrollbar"}`}>
                            <Table aria-label="customized table" stickyHeader>
                                <EnhancedTableHead classes={classes} />
                                <TableBody>
                                    {ActivityHistoryList?.map((r) => {
                                        return (
                                            <StyledTableRow >
                                                <StyledTableCell width="12%" className={classes.tableCellColor1}>
                                                    <Typography variant="body1" gutterBottom className={classes.actvityStyle}>
                                                        {r?.firstName}
                                                    </Typography>
                                                </StyledTableCell>
                                                <StyledTableCell width="12%" className={classes.tableCellColor1}>
                                                    <Typography variant="body1" gutterBottom className={classes.actvityStyle}>
                                                        {r?.lastName}
                                                    </Typography>
                                                </StyledTableCell>
                                                <StyledTableCell width="50%" className={classes.tableCellColor1}>
                                                    <Typography variant="body1" gutterBottom className={classes.actvityStyle}>
                                                        {r?.emailAddress}
                                                    </Typography>
                                                </StyledTableCell>
                                                <StyledTableCell width="12%" className={classes.tableCellColor1}>
                                                    <Typography variant="body1" gutterBottom className={classes.actvityStyle}>
                                                        {r?.elapsedTime}
                                                    </Typography>
                                                </StyledTableCell>
                                                <StyledTableCell width="12%" className={classes.tableCellColor1}>
                                                    <Typography variant="body1" gutterBottom className={classes.actvityStyle}>
                                                        {r?.createdDate}
                                                    </Typography>
                                                </StyledTableCell>
                                                <StyledTableCell width="13%" className={classes.tableCellColor1}>
                                                    <Typography variant="body1" gutterBottom className={classes.actvityStyle}>
                                                        {r?.activityName}
                                                    </Typography>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </DialogContent>
                <DialogActions >
                    {!allActivityHistory ?
                        <Button id="CPO_Cancel_btn" size="small" color="primary" startIcon={<ListIcon />} className={classes.showButton} autoFocus variant="contained" onClick={() => { getAllActivityHistoryList(); }}>
                            Show all activity
                        </Button>
                        :
                        null
                    }
                    <Button id="CPO_Cancel_btn" size="small" color="primary" className={classes.noButton} startIcon={<CancelIcon />} autoFocus onClick={() => { handleActivityClose() }} variant="contained">
                        close
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar className="snackBarStyle" open={activityPermisison} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="success" className="alertStyle">
                    Activity Permissions Submitted Successfully!
                </Alert>
            </Snackbar>
        </React.Fragment>
    );
};
export default AgentTrackInteraction;