import { Button, Dialog, DialogActions, DialogContent, Grid, TextField, Typography, Backdrop } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
// Icons
import CancelIcon from '@mui/icons-material/Cancel';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
// Models
import _ from 'lodash';
import moment from 'moment';
import useStyles from './AgentUserCss';
import { usePost } from '../../utils/apiHelper';
import PuffLoader from 'react-spinners/PuffLoader';
import { useGlobalState } from '../../store/GlobalStore';
import { DialogTitleHeader } from '../GlobalStyles/DialogStyle';
import { Transition } from '../GlobalStyles/DialogBoxTransition';
import { IAgentUserDetails } from '../../models/AgentUser/IAgentUserDetails';
import { IClientPhaseDetails } from '../../models/AgentTemplate/IClientTemplateDetails';
import { IAgentClientCode, IFTEHistoryDetail, IPhaseSelection } from '../../models/AgentUser/IAgentClientDetails';
import { CONT, emailRegex, firstNameregex, FIRSTSTP, lastNameregex, PREC } from '../../constants/Constant';

const CloneAgentUser: React.FC<{ userId: number, onSave: () => void }> = (props) => {
    const CheckUserExists = "AgentUser/CheckUserExists";
    const CreateAgentUser = "AgentUser/CreateAgentUser";
    const GetAgentUserById = "AgentUser/GetAgentUserById";

    const classes = useStyles();
    const { state } = useGlobalState();
    const [open, setOpen] = useState(false);
    const [emailVal, setEmailVal] = useState("");
    const [lastName, setLastName] = useState("");
    const [NameVal, setNameVal] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [Emailerror, setEmailError] = useState("");
    const [isDisable, setIsDisable] = useState(false);
    const [isloading, setIsLoading] = useState(false);
    const [agentId, setAgentId] = useState<number>(0);
    const [isCaptured, setIsCaptured] = useState(false);
    const [Lastnameerror, setLastNameError] = useState("");
    const [firstnameerror, setFirstNameError] = useState("");
    const [phase, setPhase] = useState<IPhaseSelection[]>([]);
    const [isDisableEmail, setIsDisableEmail] = useState(false);
    const [emailprocess, setEmailprocess] = useState<number>(0);
    const [ProgressBar, setShowProgressBar] = useState<boolean>(false);
    const [fteHistoryDetail, setFteHistoryDetails] = useState<IFTEHistoryDetail[]>([]);

    const handleClose = () => {
        setOpen(false);
        setShowProgressBar(false);
    };

    const HandleFirstNameVal = (e) => {
        const FirstNameVal = e.target.value;
        if (FirstNameVal === "") {
            setFirstNameError("First Name is Required");
            setIsDisable(true);
        }
        else if (!FirstNameVal.match(firstNameregex)) {
            setFirstNameError("");
            setIsDisable(false);
            setFirstName(FirstNameVal); // only set when successful
        } else {
            setFirstNameError("Please enter characters only");
            setIsDisable(true);
        }
    };

    const HandleLastNameVal = (e) => {
        const LastNameVal = e.target.value;
        if (LastNameVal === "") {
            setLastNameError("Last Name is Required");
            setIsDisable(true);
        }
        else if (!LastNameVal.match(lastNameregex)) {
            setLastNameError("");
            setIsDisable(false);
            setLastName(LastNameVal); // only set when successful
        } else {
            setLastNameError("Please enter characters only");
            setIsDisable(true);
        }
    };

    const resetValues = () => {
        setFirstNameError(null);
        setLastNameError(null);
        setEmailError(null);
        setFirstName("");
        setLastName("");
        setEmailVal("");
    }

    let userDetails: IAgentUserDetails = {
        emailAddress: "",
        firstName: "",
        lastName: "",
        createdDate: moment(new Date())?.format("YYYY-MM-DD"),
        modifiedDate: moment(new Date())?.format("YYYY-MM-DD"),
        paymentUpdates: false,
        closeAccounts: false,
        rollAccounts: false,
        clientInformation: false,
        readOnlyAccess: false,
        isActive: true,
        portalUserExist: false,
        remainingAccountCount: false,
        dailyActivityCount: false,
        dailyTargetedCount: false,
        accountPlacementCount: false,
        accountUpdateHistory: false,
        accountActivityHistory: false,
        allFirstPartySelected: false,
        allPrecSelected: false,
        allContSelected: false,
        agentUsersReport: false,
        ftePermission: false,
        disputeValidation: false,
        paymentVerification: false,
        backupDocument: false,
        caseCreatedReport: false,
        activityCountReport: false,
    }

    const [localState, setLocalState] = useState(userDetails);
    const [selectedClients, setSelectedClients] = useState<any>([]);
    const [notificationSelectedClients, setNotificationSelectedClients] = useState<any>([]);

    const handleFetchUser = () => {
        (async () => {
            let request = {
                "agentUserId": props?.userId
            }
            await usePost<{ agentClientDetails: IAgentClientCode[], agentUserDetails: IAgentUserDetails[], clientPhaseDetails: IClientPhaseDetails[], notificationClientDetails: IAgentClientCode[] }>(GetAgentUserById, request).then((r) => {
                setLocalState(r?.data?.agentUserDetails[0]);
                setSelectedClients(r?.data?.agentClientDetails);
                setNotificationSelectedClients(r?.data?.notificationClientDetails);
                let PhaseList = _.cloneDeep(phase);
                r?.data?.clientPhaseDetails?.forEach((p) => {
                    if (p?.isFirstParty) {
                        let phaseCode: IPhaseSelection = {
                            phase: FIRSTSTP
                        }
                        PhaseList.push(phaseCode);
                    }
                    if (p?.isFlatFee) {
                        let phaseCode: IPhaseSelection = {
                            phase: PREC
                        }
                        PhaseList.push(phaseCode);
                    }
                    if (p?.isContingency) {
                        let phaseCode: IPhaseSelection = {
                            phase: CONT
                        }
                        PhaseList.push(phaseCode);
                    }
                })
                setPhase(PhaseList);
            }).finally(() => {
                setOpen(true);
            });
        })()
    }

    const validateEmail = (e) => {
        const email = e.target.value;
        setIsCaptured(true);
        if (emailRegex.test(email)) {
            (async () => {
                setIsLoading(false);
                let checkUser = { "emailAddress": email };
                await usePost<any>(CheckUserExists, checkUser).then(async (r) => {
                    if (r?.data[0]?.isWebViewUser || r?.data[0]?.isAgentUser) {
                        setEmailprocess(2);
                        setEmailVal(null);
                        setEmailError("User already exists, Please try alternate Email!");
                        setIsDisableEmail(true);
                    } else {
                        setEmailVal(email);
                        setEmailprocess(3);
                        setEmailError("");
                        setIsDisableEmail(false);
                    }
                }).finally(() => {
                    setIsLoading(false);
                });
            })()
        } else if (!e.target.value?.trim()?.length) {
            setEmailprocess(0);
            setEmailError("");
            setIsDisableEmail(true);
        }
        else {
            setEmailprocess(1);
            setEmailError("Please enter valid mail address");
            setIsDisableEmail(true);
        }
    };

    const handleChange = (e) => {
        let name = e.target.name;
        setLocalState({
            ...localState,
            [name]: e.target.value
        });
    }

    const handleCreateUser = async () => {
        setShowProgressBar(true);
        try {
            // Step 1: Check if the user exists
            const checkUserPayload = {
                emailAddress: localState?.emailAddress,
            };

            const checkUserResponse = await usePost<any>(CheckUserExists, checkUserPayload);

            if (checkUserResponse?.data?.[0]?.isWebViewUser || checkUserResponse?.data?.[0]?.isAgentUser) {
                return; // Exit early if a duplicate or invalid user is detected
            }

            // Step 2: Prepare new client list
            let newClient: IAgentClientCode[] = [];
            selectedClients?.forEach((c) => {
                phase?.forEach((p) => {
                    newClient.push({ clientCode: c?.clientCode, phaseCode: p.phase });
                });
            });

            // Step 3: Prepare notification client list
            let newNotificationClientCodeList: IAgentClientCode[] = [];
            notificationSelectedClients?.forEach((r) => {
                newNotificationClientCodeList.push({ clientCode: r?.clientCode, phaseCode: CONT });
            });

            // Step 4: Initialize user data for batch saving
            const newUser: IAgentUserDetails[] = [localState];
            const batchSize = 500;
            let batchClientCodeList: IAgentClientCode[] = [];

            // Step 5: Batch processing
            for (let i = 0; i < newClient.length; i++) {
                batchClientCodeList.push(newClient[i]);

                // If batch is full or we're at the last item, save the batch
                if ((i + 1) % batchSize === 0 || i === newClient.length - 1) {
                    await handleBatchSave(newUser, batchClientCodeList, newNotificationClientCodeList);
                    batchClientCodeList = []; // Reset the batch list after saving
                }
            }
        } catch (error) {
            console.error("Error in handleCreateUser:", error);
        } finally {
            // Final cleanup and state updates
            resetValues();
            setOpen(false);
            setShowProgressBar(false);
            props.onSave();
        }
    };

    const handleBatchSave = ((userInfo: IAgentUserDetails[], batchClientCodeList: IAgentClientCode[], batchNotificationclientCodeList: IAgentClientCode[]) => {
        let newHistoryList = [];
        let request = {
            "createdBy": state?.userAccessContext?.id,
            "agentUserDetails": userInfo,
            "agentClientDetails": batchClientCodeList,
            "agentNotificationClientDetails": batchNotificationclientCodeList
        }
        usePost<any>(CreateAgentUser, request).then((r) => {
            setAgentId(r?.data[0]?.agentUserId);
            let newResult: IFTEHistoryDetail = {
                agentUserId: r?.data[0]?.agentUserId,
                clientCode: null,
                phaseCode: null,
                previousActivityTemplateId: null,
                newActivityTemplateId: null
            }
            newHistoryList.push(newResult);
            setFteHistoryDetails(newHistoryList);
        });
    });

    useEffect(() => {
        if (!firstName?.trim()?.length || !lastName?.trim()?.length || Lastnameerror?.length || firstnameerror?.length) {
            setNameVal(true);
        } else {
            setNameVal(false);
        }
    }, [firstName, lastName, isDisable])

    return (
        <React.Fragment>
            <Button size="small" id="U_Clone_btn" className={classes.button} startIcon={<PeopleAltIcon />} onClick={() => { handleFetchUser(); }} variant="contained" color="primary" >
                Clone
            </Button>

            <Dialog TransitionComponent={Transition}
                PaperProps={{ style: { borderRadius: 10 } }}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                fullWidth={true}
                maxWidth={'sm'}
            >
                <Backdrop className={classes.backdrop} open={ProgressBar}>
                    <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                </Backdrop>
                <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
                    <Typography variant="h6" className={classes.titleheader}>
                        CLONE USER ( {localState?.firstName + ' ' + localState?.lastName} )
                    </Typography>
                </DialogTitleHeader>
                <DialogContent >
                    <Grid container spacing={0}>
                        <Grid item xs={12} sm={6} >
                            <TextField id="U_FirstName"
                                name="firstName"
                                variant="outlined"
                                size="small" label="First Name"
                                inputProps={{ maxlength: 100 }}
                                className={classes.userNameEmail}
                                helperText={firstnameerror} error={!!firstnameerror}
                                onChange={(e) => { handleChange(e); HandleFirstNameVal(e); }}
                                InputProps={{ classes: { root: classes.blueBorderBottom, }, }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} >
                            <TextField id="U_LastName"
                                name="lastName"
                                variant="outlined"
                                size="small" label="Last Name"
                                inputProps={{ maxlength: 100 }}
                                className={classes.userNameEmail}
                                helperText={Lastnameerror} error={!!Lastnameerror}
                                onChange={(e) => { handleChange(e); HandleLastNameVal(e); }}
                                InputProps={{ classes: { root: classes.blueBorderBottom, }, }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} >
                            <TextField id="U_CU_Email"
                                size="small"
                                autoComplete='off'
                                name="emailAddress"
                                inputProps={{ maxlength: 50 }}
                                required label="Email" variant="outlined"
                                helperText={Emailerror} error={!!Emailerror}
                                onChange={(e) => { handleChange(e); validateEmail(e) }}
                                className={`${isCaptured && emailprocess === 0 ? classes.textFieldBorder : emailprocess === 1 || emailprocess === 2 ? classes.textFieldBorderRed : classes.textFieldBorderGreen}`}
                                InputProps={{
                                    classes: {
                                        root: `${emailprocess === 0 ? classes.blueBorderBottom : emailprocess === 1 || emailprocess === 2 ? classes.redBorderBottom : classes.greenBorderBottom}`,
                                        notchedOutline: `${emailprocess === 0 ? classes.blueBorderColor : emailprocess === 1 || emailprocess === 2 ? classes.redBorderColor : classes.greenBorderColor}`,
                                    },
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.inputFontSize
                                    },
                                }} />
                            {isloading ?
                                <div className={classes.loadingDiv}>
                                    <div className={`${'loader2'} ${classes.spinnerStyle}`} />
                                </div>
                                :
                                null
                            }
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button id="U_CreateClone_btn" size="small" startIcon={<DoneAllIcon />} className={`${classes.cloneYesButton} ${classes.yesButton} `} variant="contained" color="primary" onClick={(_) => { handleCreateUser(); setOpen(false); }} disabled={(NameVal || !emailVal || isDisableEmail)}>
                        Clone User
                    </Button>
                    <Button id="U_Cancel_btn" size="small" startIcon={<CancelIcon />} className={`${classes.noButton}`} onClick={handleClose}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment >
    );
}

export default CloneAgentUser
